import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Edit from "../../../src/Assets/images/Icons/edit.svg";
import Select from 'react-select';
import View from '../../Components/Common/Buttons/View';

function EmployeeAccess({
    milestone,
    milestoneIndex,
    handleSaveSubMilestone,
    handleEditSubMilestone,
    handleDeleteSubMilestone,
    handleSubMilestoneChange,
    handleComment,
    updateStataus,
    Employee,
    isEditDisabled,
    iscreateDisabled
}) {
    return (
        <div>
            {milestone?.projectSubMilestoneDtoList?.sort((a, b) => a.subMilestoneNo - b.subMilestoneNo).map((subMilestone, subMilestoneIndex) => (
                <div key={subMilestoneIndex} className='project_submile'>
                    {/* {subMilestone.isEditing ? ( */}
                        <div className='sub_wrap col-lg-12'>
                            <div className='col-lg-3'>
                                <div className='d-flex  align-items-start'>
                                    <span>
                                        {`${milestoneIndex + 1}.${subMilestoneIndex + 1}`}
                                    </span>
                                    <p className='left' >{subMilestone.projectSubmilestoneName}</p>
                                </div>
                            </div>

                            <div className='col-lg-3'>
                                <Select
                                    value={subMilestone?.employeeList?.length > 0 ? Employee.filter((option) => subMilestone.employeeList.includes(option.id)) : ""}
                                    options={Employee}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(selectedOptions) => handleSubMilestoneChange(selectedOptions, milestoneIndex, subMilestoneIndex, "employeeSelection")}
                                />
                            </div>

                            <div className='col-lg-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=''>
                                        <input
                                            type='text'
                                            placeholder='Enter working Hours'
                                            value={subMilestone.totalWorkingHours}
                                            onChange={(e) => handleSubMilestoneChange(e, milestoneIndex, subMilestoneIndex, "duration")}
                                        />
                                    </div>
                                    {/* <div onClick={() => handleSaveSubMilestone(milestoneIndex, subMilestoneIndex)} className='mx-5'>
                                        {
                                             <span className='cursor-pointer'>&#10004;</span>
                                        }
                                    </div>
                                    <div onClick={() => handleDeleteSubMilestone(milestoneIndex, subMilestoneIndex)}>
                                        {
                                             <span className='cursor-pointer'>&#10006;</span>
                                        }
                                    </div> */}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center col-lg-2'>
                                <div onClick={() => handleComment(subMilestone, milestone?.projectMilestoneId)}>
                                    {
                                        iscreateDisabled && <View text="Comment" />
                                    }
                                </div>
                                <div onClick={() => subMilestone.status === null ? updateStataus(subMilestone) : null}>
                                    {
                                        iscreateDisabled && <View text="Status" />
                                    }
                                </div>
                            </div>
                        </div>
                    {/* ) : 
                    ( */}
                        {/* <div className='sub_wrap'>
                            <div className='col-lg-3 '>
                                <div className='d-flex  align-items-start disabled'>
                                    <span className=''>
                                        {`${milestoneIndex + 1}.${subMilestoneIndex + 1}`}
                                    </span>
                                    <p className='left'>{subMilestone.projectSubmilestoneName}</p>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <Select
                                    value={subMilestone?.employeeList?.length > 0 ? Employee.filter((option) => subMilestone.employeeList.includes(option.id)) : ""}
                                    options={Employee}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(selectedOptions) => handleSubMilestoneChange(selectedOptions, milestoneIndex, subMilestoneIndex, "employeeSelection")}
                                    isDisabled={true}
                                />
                            </div>
                            <div className='col-lg-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=''>
                                        <input
                                            type='text'
                                            disabled={true}
                                            placeholder='Enter working Hours'
                                            value={subMilestone.totalWorkingHours}
                                            onChange={(e) => handleSubMilestoneChange(e, milestoneIndex, subMilestoneIndex, "duration")}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center col-lg-2'>
                                        <div onClick={() => handleEditSubMilestone(milestoneIndex, subMilestoneIndex)} className='table_icon'>
                                            {
                                                isEditDisabled && <img src={Edit} alt='' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center col-lg-2'>
                                <div onClick={() => handleComment(subMilestone, milestone?.projectMilestoneId)}>
                                    {
                                        iscreateDisabled && <View text="Comment" />
                                    }
                                </div>
                                <div onClick={() => subMilestone.status === null ? updateStataus(subMilestone) : null}>
                                    {
                                        iscreateDisabled && <View text="Status" />
                                    }
                                </div>
                            </div>
                        </div> */}
                    {/* )
                    
                    } */}
                </div>
            ))}
        </div>
    );
}

export default EmployeeAccess;
