import React from 'react'

function SubTitle({ title, count }) {
    return (

        <div className='subtitle'>

            {
                count ? <h4>{title}<span className='count_brack'>{count}</span></h4> : <h4>{title}</h4>
            }
        </div>

    )
}

export default SubTitle
