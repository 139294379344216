import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Layouts from "./Components/Layout/index";
import Login from "../src/Pages/Auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "./Utils/auth-context";
import { gapi } from "gapi-script";


const App = () => {
  const authCtx = useContext(AuthContext);
  
  const navigate = useNavigate();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        theme="light"
      />
      {/* <Layouts /> */}

      <Routes>
        <Route exact path="/login" element={<Login />} />

        {authCtx.isLoggedIn ? (
          <Route path="/*" element={<Layouts />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route
          path="*"
          element={<Navigate to={authCtx.isLoggedIn ? "/" : "/login"} />}
        />
      </Routes>
    </div>
  );
};

export default App;
