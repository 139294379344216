import React from "react";
import "./followup.css";

const Followups = (props) => {
  const { ProjectId, questions, mid, setChatOpen } = props
  const handler = (id, questions, mid, ProjectId, setChatOpen) => props.actionProvider.handleJavascriptList(id, questions, mid, ProjectId, setChatOpen);

  const options = questions?.[props.payload]?.data.followUp;

  const optionsMarkup = options?.map((option) => (
    <button
      className="learning-option-button"
      key={option.queId}
      onClick={() => handler(option.queId, questions, mid, ProjectId, setChatOpen)}
    >
      {option.queText}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default Followups;
