import axios from "axios";
import {
  LOGIN,
  ROLECREATE,
  ALLROLEGET,
  EMPLOYEECREATE,
  ALLEMPLOYEEGET,
  EMPLOYEEDELETE,
  SERVICEADD,
  SERVICEBYCATEGORY,
  DOCUMENTCREATE,
  LinkCREATE,
  GETCUSTOMERALL,
  GETCUSTOMER,
  SERVICEALL,
  SERVICEONE,
  LinkDelete,
  GETPROJECTS,
  DOCUMENTDELETE,
  RACICREATE,
  RACIGET,
  RACIUPDATE,
  SERVICEUPDATE,
  SERVICEDelete,
  MILESTONEDELETE,
  GETONEPROJECT,
  PROJECTUPDATE,
  EMPLOYEEONE,
  UPDATEMILESTONE,
  ROLEALL,
  ROLEONE,
  PROJECTCOMMENT,
  NOTIFICATION,
  UPLOADCHAT,
  GETCHAT,
  GETCHATCONVERSATION,
  POSTCHATCONVERSATION,
  GETREVIEW,
  CREATECUSTOMER,
  UPDATECUSTOMER,
  PURCHASETASK,
  GETSCANS,
  POSTSCANS,
  GETSCAN,
  GENRATESCANREPORT,
  DOWNLOADSCANREPORT,
  DOWNLOADSCANREPORTBYID,
  CUSTOMERDELETE,
  CUSTOMERUNDO,
  SEARCHPROJECTS,
  GETALLSCANS,
  CLONEDSERVICE,
  TEARMSCREATE,
  TEARMSGET,
  TEARMSGETALL,
  DELTEMILESTONE,
  UPDATESTARTDATE,
} from "./routes";
export const BASE_URL = "https://api.fncyber.com/";

axios.defaults.baseURL = BASE_URL;

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const RoleCreate = async (data) => {
  try {
    const response = await axios.post(ROLECREATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const RoleGetAll = async (data) => {
  try {
    const response = await axios.get(ALLROLEGET, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EmployeeCreate = async (data) => {
  try {
    const response = await axios.post(EMPLOYEECREATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EmployeeGetAll = async (data) => {
  try {
    const response = await axios.get(ALLEMPLOYEEGET, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EmployeeDelete = async (id) => {
  try {
    const response = await axios.delete(`${EMPLOYEEDELETE}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DocsDelete = async (id) => {
  try {
    const response = await axios.delete(`${DOCUMENTDELETE}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ServiceAdd = async (data) => {
  try {
    const response = await axios.post(SERVICEADD, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ServiceGet = async (data) => {
  try {
    const response = await axios.get(SERVICEBYCATEGORY, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DocumentAdd = async (data) => {
  try {
    const response = await axios.post(DOCUMENTCREATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const LinkAdd = async (data) => {
  try {
    const response = await axios.post(LinkCREATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CustomerALLGet = async () => {
  try {
    const response = await axios.get(GETCUSTOMERALL, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ServiceALLGet = async () => {
  try {
    const response = await axios.get(SERVICEALL, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ServiceOneGet = async (id) => {
  try {
    const response = await axios.get(`${SERVICEONE}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CustomerGet = async (id) => {
  try {
    const response = await axios.get(`${GETCUSTOMER}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const LinkDeleteAPi = async (id) => {
  try {
    const response = await axios.delete(`${LinkDelete}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetProjects = async (type, page, size) => {
  try {
    const response = await axios.get(
      `${GETPROJECTS}?projectType=${type}&page=${page}&size=${size}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};

export const RaciCreate = async (data) => {
  try {
    const response = await axios.post(RACICREATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const RaciGet = async (data) => {
  try {
    const response = await axios.get(RACIGET, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const RaciUpdate = async (id, data) => {
  try {
    const response = await axios.put(`${RACIUPDATE}${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ServiceUpdate = async (id, data) => {
  try {
    const response = await axios.put(`${SERVICEUPDATE}${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SERVICEDeleteAPi = async (id) => {
  try {
    const response = await axios.delete(`${SERVICEDelete}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const MileStoneDelete = async (data) => {
  try {
    const response = await axios.post(MILESTONEDELETE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PROJECTONEAPi = async (id) => {
  try {
    const response = await axios.get(`${GETONEPROJECT}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PROJECTUPDATEAPi = async (data) => {
  try {
    const response = await axios.put(PROJECTUPDATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EMPLOYEEONEAPi = async (id) => {
  try {
    const response = await axios.get(`${EMPLOYEEONE}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const MileStatus = async (data) => {
  try {
    const response = await axios.post(UPDATEMILESTONE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ROLEALLAPi = async (id) => {
  try {
    const response = await axios.get(`${ROLEALL}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ROLEONEAPi = async (id) => {
  try {
    const response = await axios.get(`${ROLEONE}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PROJECTCOMMENTAPi = async (data) => {
  try {
    const response = await axios.post(PROJECTCOMMENT, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const NOTIFICATIONAPI = async (data) => {
  try {
    const response = await axios.post(NOTIFICATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPLOADCHATAPi = async (data) => {
  try {
    const response = await axios.post(UPLOADCHAT, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETCHATAPI = async (ID) => {
  try {
    const response = await axios.get(`${GETCHAT}${ID}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETCONVERSATION = async (ID) => {
  try {
    const response = await axios.get(`${GETCHATCONVERSATION}${ID}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETCHATCONVERSATIONAPi = async (data) => {
  try {
    const response = await axios.post(POSTCHATCONVERSATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETREVIEWAPi = async (id) => {
  try {
    const response = await axios.get(`${GETREVIEW}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CREATECUSTOMERAPi = async (data) => {
  try {
    const response = await axios.post(CREATECUSTOMER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATECUSTOMERAPi = async (data) => {
  try {
    const response = await axios.put(UPDATECUSTOMER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PURCHASETASKAPi = async (data) => {
  try {
    const response = await axios.post(PURCHASETASK, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETSCANSAPi = async () => {
  try {
    const response = await axios.get(`${GETSCANS}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const POSTSCANSAPi = async (data) => {
  try {
    const response = await axios.post(POSTSCANS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATESTARTDATEAPI = async (data) => {
  try {
    const response = await axios.post(UPDATESTARTDATE, data, {
      headers: {
       "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETSCANAPi = async (id) => {
  try {
    const response = await axios.get(`${GETSCAN}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const GENRATESCANREPORTAPi = async (id, data) => {
  try {
    const response = await axios.post(`${GENRATESCANREPORT}${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DOWNLOADSCANREPORTAPi = async (id) => {
  try {
    const response = await axios.get(`${DOWNLOADSCANREPORT}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const DOWNLOADSCANREPORTBYIDAPi = async (id) => {
  try {
    const response = await axios.get(`${DOWNLOADSCANREPORTBYID}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const CUSTOMERDELETEAPI = async (id) => {
  try {
    const response = await axios.delete(`${CUSTOMERDELETE}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CUSTOMERUNDOAPi = async (id) => {
  try {
    const response = await axios.post(`${CUSTOMERUNDO}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SEARCHPROJECTSOAPi = async (type, page, size, searchKeyword) => {
  try {
    const response = await axios.get(
      `${SEARCHPROJECTS}?projectType=${type}&page=${page}&size=${size}&searchKeyword=${searchKeyword.trim()}`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETALLSCANSAPi = async () => {
  try {
    const response = await axios.get(
      `${GETALLSCANS}`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CLONEDSERVICEAPi = async (data) => {
  try {
    const response = await axios.post(`${CLONEDSERVICE}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TEARMSCREATEAPi = async (data) => {
  try {
    const response = await axios.post(`${TEARMSCREATE}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TEARMSGETAPi = async (result) => {
  try {
    const response = await axios.get(`${TEARMSGET}/${result}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const TEARMSGETALLAPi = async () => {
  try {
    const response = await axios.get(`${TEARMSGETALL}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DELTEMILESTONEAPi = async (data) => {
  try {
    const response = await axios.post(`${DELTEMILESTONE}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
