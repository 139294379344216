import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
    useParams
} from "react-router-dom";
import Navigate from '../../Components/Common/Buttons/Navigate'
import Loader from '../../Components/Common/Loader';
import Title from '../../Components/Common/Heading/Title';
import SubTitle from '../../Components/Common/Heading/SubTitle';
import Crop from "../../Assets/images/Icons/MyServiceImage.png"
import { CustomerGet } from '../../Utils/services';
import CompanyService from '../../Components/Common/Cards/CompanyService';
import AssignProjects from '../../Components/Common/Modals/AssignProjects';
import { ModalContext } from '../../Utils/Context';
import AuthContext from '../../Utils/auth-context';
import { useAbility } from '../../Utils/AbilityContext';
import { toast } from 'react-toastify';

function CompanyProfile() {
    const [loading, setLoading] = useState(false);
    const [alldata, setalldata] = useState({});
    const { id } = useParams();
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const { isAdmin, permission } = useContext(AuthContext);
    const ability = useAbility();

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await CustomerGet(id);

            if (result?.res?.status == true) {
                setLoading(false)
                setalldata(result?.res?.data)
                console.log(result?.res?.data,"result?.res?.data")
            }
            else {
                setLoading(false)
                toast.error(result?.res?.message)
            }


        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };
    useEffect(() => {

        fetchData();

    }, []);
    const Handle = () => {
        const AssignProjectsModal = <AssignProjects id={id} reload={fetchData} closeModal={closeModal} />
        handleModalData(AssignProjectsModal, "md");
    }
    const filteredProjects = (alldata?.companyProjectList || []).filter((res) => {
        return res?.projectType === 2;
    });
    const CompletedProjects = (alldata?.companyProjectList || []).filter((res) => {
        return res?.projectType === 3;
    });

    const isAddable = (isAdmin === true || isAdmin === "true") ?? true;
    return (
        <div>  <div className="container-fluid pb-5">
            <div className='row'>

                <Title title="Company's Profile" />
                <div className=' wiz_text'>
                    <p className=''>
                        Service Wizard Last Used : 22/12/2022

                    </p>
                </div>
            </div>
            <div className='row'>
                {
                    isAddable && <div onClick={Handle}>
                        <Navigate text="Assign Project" />
                    </div>
                }

            </div>


            <div className='organ_tile'>
                <SubTitle title="Organization Informtion" />

                <div className='company_info'>
                    <div className='organisation_info'>
                        <div className='col-lg-5 compant_text_width'>
                            <p className='company_name'>
                                {alldata?.organisationName}
                            </p>
                        </div>
                        <div className='col-lg-5 compant_text_width'>
                            <p>
                                Types of Sensitive Data
                            </p>
                            <span>
                                {alldata?.typeofSensitiveData}

                            </span>
                        </div>



                    </div>
                    <div className='organisation_info'>
                        <div className='col-lg-5'>
                            <p>
                                Industry
                            </p>
                            <span>
                                {alldata?.industry}
                            </span>
                        </div>




                    </div>
                    <div className='organisation_info'>
                        <div className='col-lg-5'>
                            <p>
                                Company Size:
                            </p>
                            <span>
                                {alldata?.companySize}

                            </span>
                        </div>
                        <div className='col-lg-5'>
                            <p>
                                Location
                            </p>
                            <span>
                                {alldata.location}
                            </span>
                        </div>



                    </div>
                </div>


            </div>
            <div className='security_tile'>
                <SubTitle title="Security Informtion" />

                <div className='company_info '>
                    <div className='row'>
                        <div className='security_content'>
                            {alldata?.securityInformation && Object.keys(alldata?.securityInformation)?.map(key => (

                                <article className='col-lg-5' key={key}>
                                    <div>
                                        <p className='secur_head'>
                                            {key} :
                                        </p>
                                    </div>
                                    <div className='mt-2'>
                                        <div dangerouslySetInnerHTML={{ __html: alldata?.securityInformation[key] }} />

                                    </div>
                                </article>
                            ))}






                        </div>








                    </div>
                </div>


            </div>

            <Loader loading={loading} />
            <div className='personal_tile'>
                <SubTitle title="Personal Information" />
                <div className='row gx-4'>

                    {
                        alldata && alldata?.customerProfileList
                            ?.map((res) => {

                                return (
                                    <div className='col-lg-6 col-md-6'>
                                        <div className='per_profile_tile'>
                                            <div className='profile_image'>
                                                <img src={res?.imageUrl ? res?.imageUrl : Crop} />
                                            </div>
                                            <div className='Profile_text'>
                                                <p>
                                                    {res.ownerName ? res.ownerName : ""}
                                                </p>
                                                <p>
                                                    Owner
                                                </p>
                                                <p>

                                                    {res.ownerEmail ? res.ownerEmail : ""}
                                                </p>

                                            </div>
                                            <div>
                                                <p> {res.ownerMobileNo ? res.ownerMobileNo : ""}</p>
                                            </div>

                                        </div>

                                    </div>)
                            })
                    }






                </div>

            </div>


            <div className='personal_tile'>
                <SubTitle title="Projects Information" />
                <div className='row gx-4'>
                    {
                        filteredProjects && filteredProjects?.length > 0 &&
                        <p className='secur_head my-1'>
                            OnGoing
                        </p>
                    }

                    <div className='row'>
                        <div className='company_projectile_wrap'>
                            {
                                (alldata?.companyProjectList || []).filter((res) => {
                                    return res?.projectType === 2; // Add a return statement
                                }).map((filteredRes, index) => (

                                    <CompanyService data={filteredRes} />

                                ))
                            }

                        </div>
                    </div>


                </div>
                <div className='row gx-4'>
                    {
                        CompletedProjects && CompletedProjects?.length > 0 &&
                        <p className='secur_head my-1'>
                            Completed
                        </p>
                    }
                    <div className='row'>
                        <div className='company_projectile_wrap'>
                            {
                                (alldata?.companyProjectList || []).filter((res) => {
                                    return res?.projectType === 3; // Add a return statement
                                }).map((filteredRes, index) => (

                                    <CompanyService data={filteredRes} />


                                ))
                            }

                        </div>
                    </div>

                </div>
            </div>


        </div></div>
    )
}

export default CompanyProfile