// MainWrapper.js
import React, { useContext, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../../Pages/Home';
import Loader from '../../Components/Common/FallBack';
import ServiceList from '../../Pages/Master/Service/ServiceList';
import ViewService from '../../Pages/Master/Service/ViewService';
import EmployeeDetails from '../../Pages/Master/Employee/EmployeeDetails';
import OngoingProjects from '../../Pages/OngoingProjects';
import ManageProjects from '../../Pages/ManageProjects';
import CompanyProfile from '../../Pages/CompanyProfile';
import Upload from '../../Pages/Upload';
import UploadInner from '../../Pages/UploadInner';
import CompletedProjects from '../../Pages/CompletedProjects';
import Customer from '../../Pages/Customers';
import Raci from '../../Pages/Master/Service/Raci';
import RaciMaster from '../../Pages/Master/Raci_Role';
import ViewMilstone from '../../Pages/Master/Service/ViewMileStone';
import Builder from '../../Pages/Master/Service/Builder';
import ViewEmployee from '../../Pages/Master/Employee/ViewEmployee';
import Multi from '../../Pages/Master/Service/Multi';
import Toolkit from '../../Pages/SecurityToolkit';
import ToolkitInner from '../../Pages/ToolkitInner';
import Scans from '../../Pages/ScanInfo';

import AuthContext from '../../Utils/auth-context';
import { useAbility } from '../../Utils/AbilityContext';


function MainWrapper() {
    const { isAdmin, permission } = useContext(AuthContext);
    const ability = useAbility();

    const routes = [
        { path: "/", element: Home, exact: true, moduleId: 4, action: 'view' },
        { path: "/service", element: ServiceList, exact: true, moduleId: 2, action: 'view' },
        { path: "/viewservice/:id", element: ViewService, exact: true, moduleId: 2, action: 'view' },
        { path: "/EmployeeDetails/:id", element: EmployeeDetails, exact: true, moduleId: 7, action: 'view' },
        { path: "/viewEmployee", element: ViewEmployee, exact: true, moduleId: 7, action: 'view' },
        { path: "/ongoingprojects", element: OngoingProjects, exact: true, moduleId: 1, action: 'view' },
        { path: "/manageprojects/:id/:completed?", element: ManageProjects, exact: true, moduleId: 1, action: 'view' },
        { path: "/companyprofile/:id", element: CompanyProfile, exact: true, moduleId: 1, action: 'view' },
        { path: "/upload", element: Upload, exact: true, moduleId: 3, action: 'view' },
        { path: "/uploadinner/:id", element: UploadInner, exact: true, moduleId: 3, action: 'view' },
        { path: "/completedprojects", element: CompletedProjects, exact: true, moduleId: 1, action: 'view' },
        { path: "/customers", element: Customer, exact: true, moduleId: 7, action: 'view' },
        { path: "/addservices", element: Multi, exact: true, moduleId: 2, action: 'view' },
        { path: "/raci", element: RaciMaster, exact: true, moduleId: 7, action: 'view' },
        { path: "/milestone/:id", element: ViewMilstone, exact: true, moduleId: 2, action: 'view' },
        { path: "/Raci/:id", element: Raci, exact: true, moduleId: 2, action: 'view' },
        { path: "/toolkit", element: Toolkit, exact: true, moduleId: 7, action: 'view' },
        { path: "/toolkit/:id", element: ToolkitInner, exact: true, moduleId: 7, action: 'view' },
        { path: "/scan/:id", element: Scans, exact: true, moduleId: 7, action: 'view' },

    ];




    return (
        <div className='mainWrapper pt-5'>
            <Routes>

                {routes.map((route, index) => {
                    // Find the ability that matches the route's moduleId

                    if (isAdmin === false || isAdmin === "false") {
                        const matchingAbility = ability?.A?.find((a) => a?.subject === route?.moduleId);

                        if (matchingAbility) {
                            return (
                                <Route
                                    key={route.path} // You can use a more unique key here
                                    path={route.path}
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <route.element />
                                        </Suspense>
                                    }
                                />
                            );
                        }


                    }
                    else {
                        return (
                            <Route
                                key={route.path} // You can use a more unique key here
                                path={route.path}
                                element={<route.element />}
                            />
                        );
                    }


                })}
                <Route
                    // You can use a more unique key here
                    path="/chart/:id"
                    element={<Builder />}
                />

            </Routes>

        </div>

    );
}

export default MainWrapper;
