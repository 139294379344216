import React, { useContext, useEffect, useState } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import ButtonIcon from '../../../Components/Common/Buttons/ButtonIcon';
import Loader from '../../../Components/Common/Loader';
import Cross from "../../../Assets/images/Icons/cross.png";
import Download from "../../../Assets/images/Icons/download.png";
import ReviewCards from '../../../Components/Common/Cards/ReviewCards';
import { Link, Navigate, useParams } from "react-router-dom";
import DownloadFile from '../../../Components/Common/Modals/DownloadFile';
import Default from '../../../Components/Common/Buttons/Default';
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import ColorTitle from '../../../Components/Common/Heading/ColorTitle';
import service from '../../../Assets/images/service/fnC_Blog_Ransomware.png';
import { ServiceOneGet } from '../../../Utils/services';
import { ModalContext } from '../../../Utils/Context';
import { useNavigate } from 'react-router-dom';
import EditableTable from '../../../Components/Common/Tables/EditableTable';
import AuthContext from '../../../Utils/auth-context';
import { useAbility } from '../../../Utils/AbilityContext';
import ReadMore from "../../../Components/Common/ReadMore"

function ViewService() {
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState(null);
    const modalContext = useContext(ModalContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const { isAdmin, permission } = useContext(AuthContext);
    const { closeModal, handleModalData } = modalContext;
    const ability = useAbility();

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await ServiceOneGet(id);
            if (result && result.res.status === true) {
                setAllData(result.res.data);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);
    const handle = (val) => {
        if (val === 'Raci') {
            navigate(`/Raci/${id}`, { state: { id } });
        }
        else {
            navigate(`/milestone/${id}`, { state: { id } });
        }

    }
    const handledownload = () => {
        const Document = <DownloadFile closeModal={closeModal} data={allData} />;
        handleModalData(Document, "md");
    };
    const handleNavigate = () => {
        navigate('/addservices', { state: { id } });
    };

    const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'edit');

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex justify-content-between align-items-start flex-column'>
                        <div className='mb-2'>
                            <Title title={allData?.serviceName} />
                        </div>
                        <div className='service_desc mb-4'>

                            <ReadMore data={allData?.shortDescription} />
                        </div>
                        <div onClick={() => handledownload()}>
                            <ButtonIcon source={Download} text="Download Files" />
                        </div>

                    </div>
                    <div onClick={isEdit ? () => handleNavigate() : null}>
                        <Default text="Edit" disabled={!isEdit} />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='d-flex justify-content-between align-items-start'>
                        <div className='col-lg-9 view_content'>
                            <div>
                                <div>
                                    <div>
                                        {allData?.definePurpose && <SubTitle title="Purpose" />}
                                    </div>
                                    <div className='pupose_text'>
                                        <ReadMore data={allData?.definePurpose} />
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <div>
                                        {allData?.defineScope && <SubTitle title="Scope" />}
                                    </div>
                                    <div className='pupose_text'>
                                        <ReadMore data={allData?.defineScope} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            {
                                allData?.imageS3Url && <div className='service_img'>
                                    <img src={allData?.imageS3Url} alt='image' />
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <Loader loading={loading} />
                <div className='row mt-5'>
                    <div>
                        <div>
                            <SubTitle title="Project Plan" />
                        </div>
                        <div className='my-3'>
                            <ColorTitle title="Project Overview" />
                        </div>
                        <div className='row'>


                            <div className='col-lg-6 my-2'>
                                <div className='d-flex  align-items-start flex-column'>
                                    <div>
                                        <p className='overview_head'>
                                            Goal
                                        </p>
                                    </div>
                                    <div className='overview_text'>
                                        {allData?.goal && (
                                            <ReadMore data={allData?.goal} />
                                        )}</div>


                                </div>
                            </div>
                            <div className='col-lg-6 my-2'>
                                <div className='d-flex   align-items-start flex-column'>
                                    <div>
                                        <p className='overview_head'>
                                            Deliverables
                                        </p>
                                    </div>
                                    <div className='overview_text'>  {allData?.deliverables && (
                                        <ReadMore data={allData?.deliverables} />
                                    )}

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 my-2'>
                                <div className='d-flex  align-items-start flex-column'>
                                    <div>
                                        <p className='overview_head'>
                                            Objective
                                        </p>
                                    </div>
                                    <div className='overview_text'>   {allData?.objective && (
                                        <ReadMore data={allData?.objective} />
                                    )}
                                    </div>


                                </div>
                            </div>
                            <div className='col-lg-6 my-2'>


                                <div className='d-flex  align-items-center'>
                                    <div>
                                        <p className='overview_head'>
                                        Duration
                                        </p>
                                    </div>
                                    <div> <p className='overview_text'>
                                        {allData?.durationInWeeks} (in
                                        Weeks)
                                    </p>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className='my-3 d-flex justify-content-between align-items-center'>
                            <div>
                                <ColorTitle title="Project MileStone" />
                            </div>
                            <div onClick={isEdit ? () => handle('milestone') : null}>
                                <Default text="Edit Milestone" disabled={!isEdit} />
                            </div>


                        </div>
                        <div className='row'>
                            {
                                allData && allData?.mileStones?.map((res, index) => {
                                    return (<div className='col-lg-6 my-2'>
                                        <div className='d-flex  align-items-center'>
                                            <div>
                                                <p className='milesteone_head'>
                                                    Milestone
                                                    <span className='milesteone_no'>
                                                        {index + 1}
                                                    </span>
                                                </p>
                                            </div>
                                            <div> <p className='milesteone_text'>{res?.mileStoneName}
                                            </p></div>


                                        </div>
                                    </div>)
                                })
                            }





                        </div>


                    </div>


                </div>
                <div className='row '>
                    <div className='my-3 d-flex justify-content-between align-items-center'>
                        <div>
                            <SubTitle title="RACI Mapping" />
                        </div>
                        <div onClick={isEdit ? () => handle('Raci') : null}>
                            <Default text="Edit Raci" disabled={!isEdit} />
                        </div>


                    </div>
                    <div className="display_table">
                        {
                            allData && <EditableTable data={allData?.raciMappingList} isEditable={false} />
                        }

                    </div>
                </div>
                <div className='row mt-5 w-90'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <SubTitle title="Review" />
                        <div>
                            {/* <p className='Review_text'>
                                See all
                            </p> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='d-flex justify-content-between align-items-center  flex-wrap px-4 py-2'>
                            {
                                allData?.reviewList && allData?.reviewList?.slice(0, 5)?.map((res) => {
                                    return (
                                        <div className='col-lg-5'>
                                            <ReviewCards data={res} />
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>




                </div>



            </div>



        </div>

    );
}

export default ViewService;
