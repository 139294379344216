import React, { useEffect, useState } from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate";
import { toast } from 'react-toastify';
import Loader from "../../../Common/Loader"
import { ROLEONEAPi, RoleCreate } from "../../../../Utils/services"
import ColorTitle from '../../Heading/ColorTitle';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

function Role({ closeModal, id, Reload, isdisabled }) {
    const [loading, setloading] = useState(false)
    const allDataInitial = {
        role: "", modulePermissions: [
            {
                moduleId: 1,
                name: 'Projects',
                view: true,
                create: false,
                edit: false,
                delete: false,
            },
            {
                moduleId: 2,
                name: 'Services',
                view: true,
                create: false,
                edit: false,
                delete: false,
            },
            {
                moduleId: 3,
                name: 'Upload',
                view: true,
                create: false,
                edit: false,
                delete: false,
            },


            // Add other modulePermissions as needed
        ],
    };
    const [allData, setAllData] = useState(allDataInitial);
    const fetchData = async () => {
        try {
            setloading(true);
            const result = await ROLEONEAPi(id);

            if (result && result.res.status === true) {
                const apiData = result.res.data;

                const updatedAllData = {
                    role: apiData?.roleName,
                    modulePermissions: apiData?.modulePermissionDtoList?.map(permission => ({
                        moduleId: permission.moduleId,
                        modulePermissionId: permission?.modulePermissionId,
                        view: permission.view, // Corrected
                        create: permission.create,
                        edit: permission.edit, // Corrected
                        delete: permission.delete, // Corrected
                        name: permission.moduleName
                    })),
                };

                setAllData(updatedAllData);
                setloading(false);
            }
            // Handle the fetched data as needed
        } catch (error) {
            setloading(false);
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);
    const handle = async (values) => {
        if (id) {
            const data = {
                "roleName": values?.allData?.role,
                "modulePermissions": values?.allData?.modulePermissions,
                "roleId": id
            }
            setloading(true)
            try {
                const result = await RoleCreate(data)
                if (result.res.status === true) {
                    closeModal();
                    Reload();
                    setloading(false);
                    toast.success(result?.message || "Role Updated successfully")


                }
            }
            catch (error) {
                closeModal();
                toast.error(error.message);
                setloading(false); // Corrected
            }
        }
        else {
            const data = {
                "roleName": values?.allData?.role,
                "modulePermissions": values?.allData?.modulePermissions
            }

            setloading(true)
            try {
                const result = await RoleCreate(data)
                if (result.res.status === true) {
                    closeModal();
                    Reload();
                    setloading(false);
                    toast.success(result?.message || "Role created successfully")


                }
            }
            catch (error) {
                closeModal();
                toast.error(error.message);
                setloading(false); // Corrected
            }
        }

    }
    const schema = Yup.object().shape({
        allData: Yup.object().shape({
            role: Yup.string().required('Role is required'),
            modulePermissions: Yup.array().of(
                Yup.object().shape({
                    view: Yup.boolean(),
                    create: Yup.boolean(),
                    edit: Yup.boolean(),
                    delete: Yup.boolean(),
                })
            ),
        }),
    });

    return (
        <div className='row'>
            <Loader loading={loading} />
            {
                id ? (<div className='com_modal'>
                    {
                        allData?.role && <Formik
                            initialValues={{
                                allData: allData,
                            }}
                            onSubmit={handle}
                            validationSchema={schema}
                        >
                            {formik => {
                                return (
                                    <Form className="form_default">
                                        <div className='mb-4 '>
                                            <FormikControl
                                                control='heading'
                                                title='Role'

                                            />
                                        </div>

                                        <div className='mb-4 '>
                                            <div className='text_left mb-2'>
                                                <ColorTitle title="Create Role" />
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='col-lg-12'>

                                                    <FormikControl
                                                        control='Input'
                                                        placeholder='Enter Role'
                                                        name='allData.role'
                                                        disabled={isdisabled}
                                                    />
                                                </div>

                                            </div>
                                            <div className='mt-5'>
                                                <div className='text_left mb-2'>
                                                    <ColorTitle title="Access" />
                                                </div>

                                                <div className='row text_left'>
                                                    {formik.values?.allData?.modulePermissions.map((permission, index) => (
                                                        <div className='col-lg-12 my-2' key={index}>
                                                            {console.log(permission, "permisson")}
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='col-lg-6'>
                                                                    <span className='role_text'>
                                                                        {permission?.name}
                                                                    </span>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        {['view', 'create', 'edit', 'delete'].map(action => (
                                                                            <FormikControl
                                                                                key={action}
                                                                                label={action}
                                                                                control='Checkbox'
                                                                                isChecked={permission[action]}
                                                                                name={`allData.modulePermissions[${index}].${action}`}
                                                                                value={permission[action]}
                                                                                disabled={isdisabled}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <Button type='submit' text='Update' disabled={isdisabled} />

                                    </Form>
                                )
                            }}
                        </Formik>
                    }



                </div>) : (<div className='com_modal'>
                    {
                        <Formik
                            initialValues={{
                                allData: allData,
                            }}
                            onSubmit={handle}
                            validationSchema={schema}
                        >
                            {formik => {
                                return (
                                    <Form className="form_default">
                                        <div className='mb-4 '>
                                            <FormikControl
                                                control='heading'
                                                title='Role'

                                            />
                                        </div>

                                        <div className='mb-4 '>
                                            <div className='text_left mb-2'>
                                                <ColorTitle title="Create Role" />
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='col-lg-12'>

                                                    <FormikControl
                                                        control='Input'
                                                        placeholder='Enter Role'
                                                        name='allData.role'
                                                    />
                                                </div>

                                            </div>
                                            <div className='mt-5'>
                                                <div className='text_left mb-2'>
                                                    <ColorTitle title="Access" />
                                                </div>

                                                <div className='row text_left'>
                                                    {formik.values.allData.modulePermissions.map((permission, index) => (
                                                        <div className='col-lg-12 my-2' key={index}>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='col-lg-6'>
                                                                    <span className='role_text'>
                                                                        {permission.name}
                                                                    </span>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        {['view', 'create', 'edit', 'delete'].map(action => (
                                                                            <FormikControl
                                                                                key={action}
                                                                                label={action}
                                                                                control='Checkbox'
                                                                                isChecked={permission[action]}
                                                                                name={`allData.modulePermissions[${index}].${action}`}
                                                                                value={permission[action]}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <Button type='submit' text='Update' disabled={formik.isSubmitting} />

                                    </Form>
                                )
                            }}
                        </Formik>
                    }



                </div>)
            }

        </div>
    )
}

export default Role;