import React from 'react'

function ADD({ text, source, disabled }) {

    return (
        <div className={`row ${disabled ? 'cursor-none' : 'cursor-none'}`}>
            <div className='add_btn ' >
                <div className='add_image'>
                    <img src={source} />

                </div>
                <div className='add_text'>
                    <h5>
                        {text}
                    </h5>
                </div>


            </div>

        </div>


    )
}

export default ADD
