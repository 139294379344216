import React, { useEffect, useState } from 'react';
import { PROJECTONEAPi, MileStatus } from '../../../../Utils/services';
import Swicth from "../../Buttons/Switch"
import Action from "../../Buttons/Default"
import { Toast } from 'bootstrap';
import { toast } from 'react-toastify';

function UpdateMilestone(props) {
    const { id } = props;
    const [milestone, setMilestone] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState([]); // State to track disabled buttons
    const [isShow, setIsShow] = useState(false);
    const [milestoneInput, setMilestoneInput] = useState({ index: "", comment: "", id: "" });

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PROJECTONEAPi(id);
            const subMilestones = [];

            result?.res?.data?.projectMilestoneList.forEach(item => {
                subMilestones.push(...item.projectSubMilestoneDtoList);
            });

            setMilestone(subMilestones);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleComment = (index, id) => {
        if (isShow) {
       
      
            setIsShow(false);
            setDisabledButtons([]);
            setMilestoneInput({ index: index + 1, id: id });
            const updatedDisabledButtons = milestone.map((_, i) => i !== index);
            setDisabledButtons(updatedDisabledButtons);
     
        } else {
            setMilestoneInput({ index: index + 1, id: id });
            const updatedDisabledButtons = milestone.map((_, i) => i !== index);
            setDisabledButtons(updatedDisabledButtons);
            setIsShow(true);
        }
    }

    const handleCancael = () => {
        setDisabledButtons([]);
        setMilestoneInput({ index: "", comment: "", id: "" })
        setIsShow(false);
        setMilestone([])
        fetchData();

    }

    const handleSubmit = async () => {
        const data = {
            "projectSubmilestoneId": milestoneInput?.id?.projectSubmilestoneId,
            "status": true,
            "commentText": milestoneInput?.comment
        };
        try {
            const result = await MileStatus(data);
            if (result.res) {
                setIsShow(false);
                setDisabledButtons([])
                toast.success(result?.res);
                setMilestone([])
                fetchData()
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    }

    return (
        <div className='update_mile p-2'>
            <div className='row mt-3'>
                <div className='col-lg-6'>
                    {
                        milestone && <div className='mb-3'>
                            <h6>Milestone Status :</h6>
                        </div>
                    }

                    {
                        milestone && milestone?.map((res, index) => (
                            <div className='d-flex justify-content-between align-item-center' key={index}>
                                <div>
                                    <p>{index + 1}</p>
                                </div>
                                <div>
                                    <p>{res?.projectSubmilestoneName}</p>
                                </div>
                                <div >
                                    <Swicth disabled={disabledButtons[index] || res?.status} isCheck={res.status} index={index} data={res} handle={handleComment}/> {/* Disable based on disabledButtons state */}
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    isShow && (
                        <div className='col-lg-6'>
                            <div className='mb-3'>
                                <h6>Congratulations on Completing Milestone {milestoneInput?.index}</h6>
                            </div>
                            {milestoneInput.id && ( // Conditionally render text based on milestoneInput.comment
                                <div className=''>
                                    <div className='d-flex align-items-center'>
                                        <div className='col-lg-8'>
                                            <div className='d-flex justify-content-between flex-column'>
                                                <div className='mt-1'>
                                                    <textarea
                                                        rows="5"
                                                        cols="32"
                                                        onChange={(e) => setMilestoneInput({ ...milestoneInput, comment: e.target.value })}
                                                        value={milestoneInput.comment}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div>
                                                <div>
                                                    <button className='default_btn w-100' onClick={handleSubmit}>
                                                        Update
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className='default_btn w-100' onClick={() => handleCancael()}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default UpdateMilestone;
