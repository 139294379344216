import React, { useMemo } from 'react'
import FormControl from '../../Controls/FormControl';
import Download from "../../../../Assets/images/Icons/download.png";
import DefaultTable from "../../Tables/DefaultTable"
import SubTitle from '../../Heading/SubTitle';
import filesize from 'filesize';
import { saveAs } from 'file-saver';

function DownloadFile(props) {
    const { closeModal, data } = props
    const handle = async (link, name) => {
        try {
            saveAs(link, name);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }
    const FeedsColumns = useMemo(
        () => [

            {
                Header: "Name",
                accessor: "documentName"
            },


            ,
            {
                Header: "Size",
                accessor: (data) => {
                    return (
                        <>
                            <div className='acess_icon'>
                                <p>{data?.documentSize
                                }</p>
                            </div>
                        </>
                    );
                }
            },
            {
                Header: 'Download',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center cursor-pointer' onClick={() => handle(data?.documentS3Url, data.documentName)}>
                                <div className='icon_image'>
                                    <img src={Download} />
                                </div>

                            </div>
                        </>
                    );
                },
            }

        ], []);
    return (
        <div>  <div className='row'>
            <div className='com_modal'>


                <div className='mb-4 text-center'>
                    <FormControl
                        control='heading'
                        title='Download Files'

                    />

                </div>
                <div className='mb-2'>
                    <SubTitle title={data?.serviceName} />
                </div>
                <div className='project_table'>
                    {
                        data && <DefaultTable data={data?.documentFile} columns={FeedsColumns} />

                    }
                </div>



                {/* 
                <div className='d-flex justify-content-evenly align-items-center my-3'>
                    <div className='yes_button' onClick={() => handle()}>
                        DownLoad
                    </div>


                </div> */}



            </div>
        </div></div>
    )
}

export default DownloadFile