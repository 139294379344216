import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Edit from "../../../Assets/images/Icons/edit.svg";
import Delete from "../../../Assets/images/Icons/delete.svg"

function MilestoneItem({
  milestone,
  milestoneIndex,
  handlechart,
  handleSaveSubMilestone,
  handleEditSubMilestone,
  handleDeleteSubMilestone,
  handleSubMilestoneChange,
  disbledDelete,
  disbledEdit
}) {
  return (
    <Droppable droppableId={`${milestone.mileStoneId}`} type="SUBMILESTONE">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {milestone?.subMilestones?.sort((a, b) => a.subMilestoneNo - b.subMilestoneNo).map((subMilestone, subMilestoneIndex) => (
            <Draggable
              key={`${subMilestone.subMilestoneId}`}
              draggableId={`${subMilestone.subMilestoneId}`}
              index={subMilestoneIndex}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {subMilestone.isEditing ? (
                    <div className='w-100 custom_submile_wrap'>

                      <div className='col-lg-9'>
                        <span className='drag_icon'>  &#9776; {/* Hamburger icon with three lines */}</span>
                        <input
                          type='text'
                          placeholder='Enter Submilestone'
                          value={subMilestone.inputValue}

                          onChange={(e) => handleSubMilestoneChange(e, milestoneIndex, subMilestoneIndex, "input")}
                        />
                        <input
                          type='text'
                          value={subMilestone.subWeightage}
                          className='sub_weigth_input'
                          placeholder='Enter weigtage'
                          onChange={(e) => handleSubMilestoneChange(e, milestoneIndex, subMilestoneIndex, "weigth")}
                        />
                      </div>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div onClick={disbledEdit ? () => handleSaveSubMilestone(milestoneIndex, subMilestoneIndex) : null} className='chekes_icons'>

                          <span>
                            &#10004; {/* This will display the checkmark symbol (✓) */}
                          </span>
                        </div>

                        <div

                          onClick={disbledDelete ? () => handleDeleteSubMilestone(milestoneIndex, subMilestoneIndex, milestone.mileStoneId, subMilestone.subMilestoneId) : null}
                        >
                          <span>
                            &#10006;
                          </span>
                        </div>
                      </div>


                    </div>
                  ) : (
                    <div className='w-100 custom_submile_wrap'>

                      <div className='col-lg-9 d-flex  align-items-center'>
                        <span className='drag_icon'>  &#9776; {/* Hamburger icon with three lines */}</span>
                        <p>
                          {subMilestone.subMilestoneName}
                        </p>
                        <div className='sub_weigth_input'>
                          <p>
                            {subMilestone.subWeightage}
                          </p>
                        </div>


                      </div>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='sub_weigth_input' onClick={() => handlechart(subMilestone.subMilestoneId)}>
                          {
                            (subMilestone?.hasConversation == null || subMilestone?.hasConversation == false) ? (
                              <p>
                                Add Converstation
                              </p>
                            ) : (<p>
                              SOP
                            </p>)
                          }

                        </div>
                        {/* <div onClick={disbledEdit ? () => handleEditSubMilestone(milestoneIndex, subMilestoneIndex) : null} className='table_icon chekes_icons'>
                          <img src={Edit} alt='' />
                        </div>

                        <div
                          className='table_icon'
                          onClick={disbledDelete ? () => handleDeleteSubMilestone(milestoneIndex, subMilestoneIndex, milestone.mileStoneId, subMilestone.subMilestoneId) : null}
                        >
                          <img src={Delete} alt='' />
                        </div> */}
                      </div>

                    </div>
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default MilestoneItem;