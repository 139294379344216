import React, { useContext, useEffect, useMemo, useState } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import { CustomTabPanel, CustomTabs } from '../../../Components/Common/Tabs';
import DraftService from "./DraftService";
import ActiveService from "./ActiveService";
function ServiceList() {
    const initialTab = parseInt(localStorage.getItem('activeTabservice')) || 0;

    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTabservice', parseInt(activeTab));
    }, [activeTab]);

    return (
        <>
            <div className="container-fluid pb-5">
                <div className='row mb-5'>
                    <Title title="Services" />
                </div>
                <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
                    <CustomTabPanel tabLabel="Active" key="1">
                        <ActiveService />

                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Draft" key="2">
                        <DraftService />

                    </CustomTabPanel>


                </CustomTabs>


            </div >
        </>
    )
}

export default ServiceList;
