import React from 'react'

function ColorTitle({ title }) {
    return (
        <div className='color_title'>
            <p>{title}</p>
        </div>
    )
}

export default ColorTitle