import React, { useContext, useEffect, useState } from 'react'
import FnC_logo from "../../Assets/images/Logos/FnC_logo/FnC_logo.png";

import UserMenu from './UserMenu';
import { NOTIFICATIONAPI } from '../../Utils/services';
import Title from '../Common/Heading/Title';
import AuthContext from '../../Utils/auth-context';
import { toast } from 'react-toastify';

const Header = (props) => {
    const toggle = props.toggle;
    const handletoggle = props.handletoggle;
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notication, Setnotication] = useState([]);
    const [userData, SetUserData] = useState({})
    const authCtx = useContext(AuthContext);

    const fetchNotiData = async () => {

        if (userData && userData?.primaryRole) {
            if (!userData) {
                return; // Exit early if result is undefined
            }

            let data;

            if (userData?.primaryRole === "ADMIN") {
                data = {
                    "userId": null
                };
            } else {
                data = {
                    "userId": userData?.userId
                };
            }

            try {
                setLoading(true);
                const result2 = await NOTIFICATIONAPI(data);

                if (result2.res.status === true) {
                    Setnotication(result2?.res?.data);
                }
            } catch (err) {
                toast.error(err);
            } finally {
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        fetchNotiData();
    }, [userData?.primaryRole, userData?.userId]);
    useEffect(() => {
        setLoading(true)
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx.user)) {
            SetUserData(JSON.parse(authCtx.user));
            setLoading(false)
        } else {
            SetUserData(authCtx.user);
            setLoading(false)
        }
    }, [authCtx.user]);
    return (
        <header className='header'>
            <div className='header_content'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div id='hamburger-1'
                        className={`hamburger ${toggle ? 'is-active' : ''}`}
                        onClick={handletoggle}
                    >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>

                    </div>


                    <div className='fn_logo_side' onClick={() => { handletoggle() }} >
                        <img src={FnC_logo} alt="" />
                    </div>
                </div>

                <div className='profile'>
                    <UserMenu notificationOpen={notificationOpen} setNotificationOpen={setNotificationOpen} />
                </div>
            </div>
            {
                notificationOpen && <div className='noti_head_box'>
                    <div className='p-3'>
                        <Title title="Notifiactions" />
                    </div>
                    {
                        notificationOpen && notication && notication?.slice(0, 20)?.map((res) => {
                            return (
                                <div className='noti_tile noti_head_tile' >
                                    <div className='noti_text'>
                                        <p>
                                            {res?.message}
                                        </p>

                                    </div>
                                    <div>
                                        {/* <span>
                                        <FaTimes />
                                    </span> */}
                                    </div>


                                </div>
                            )
                        })
                    }







                </div>
            }


        </header >
    )
}

export default Header