export const LOGIN = "login";
export const ROLECREATE = "role/create";
export const ALLROLEGET = "role/getAll";
export const EMPLOYEECREATE = "employee/create";
export const ALLEMPLOYEEGET = "employee/getAll";
export const EMPLOYEEDELETE = "employee/delete/";
export const EMPLOYEEONE = "employee/get/";
export const SERVICEADD = "task/create";
export const SERVICEBYCATEGORY = "task/getByCategory";
export const SERVICEALL = "task/getAll";
export const SERVICEONE = "task/get";
export const SERVICEDelete = "task/delete/";
export const SERVICEUPDATE = "/task/update/";
export const DOCUMENTCREATE = "document/create";
export const DOCUMENTDELETE = "document/delete/";
export const LinkCREATE = "link/create";
export const LinkDelete = "/link/delete/";
export const GETCUSTOMERALL = "customer/getAll";
export const GETCUSTOMER = "customer/get";
export const GETPROJECTS = "project/getAll";
export const PROJECTUPDATE = "/project/update";
export const GETONEPROJECT = "project/get/";
export const RACICREATE = "masterRaci/create";
export const RACIGET = "masterRaci/getAll";
export const RACIUPDATE = "masterRaci/update/";
export const MILESTONEDELETE = "/task/delete/milestones";
export const UPDATEMILESTONE = "/project/updateStatus";
export const ROLEALL = "/role/getAll";
export const ROLEONE = "role/get/";
export const PROJECTCOMMENT = "/comment/create";
export const NOTIFICATION = "project/getAll/notification";
export const UPLOADCHAT = "/task/chatbotJson";
export const GETCHAT = "project/chatbotJson/";
export const GETCHATCONVERSATION = "project/getProjectConversation/";
export const POSTCHATCONVERSATION = "project/chatConversation";
export const GETREVIEW = "/customer/getReview/employee/";
export const REFRESHTOKEN = "/refreshToken";
export const CREATECUSTOMER = "customer/create";
export const UPDATECUSTOMER = "customer/update";
export const PURCHASETASK = "purchase/task/admin";
export const GETSCANS = "/getAllScan";
export const POSTSCANS = "/StartScanAdmin";
export const UPDATESTARTDATE = "/project/updateStart";
export const GETSCAN = "api/scan/getScan/";
export const GENRATESCANREPORT = "api/scan/createReport/";
export const DOWNLOADSCANREPORT = "api/scan/downloadReport/";
export const DOWNLOADSCANREPORTBYID = "api/scan/getReport/";
export const CUSTOMERDELETE = "customer/delete/";
export const CUSTOMERUNDO = "customer/UndoDelete/";
export const SEARCHPROJECTS = "/project/searchProjects";
export const GETALLSCANS = "/api/scan/getAllScan";
export const CLONEDSERVICE = "/task/clone";
export const TEARMSCREATE = "/terms/create";
export const TEARMSGET = "/terms/get";
export const TEARMSGETALL = "/terms/getAll";
export const DELTEMILESTONE = "/task/delete/submilestoe";


