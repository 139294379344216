import React from "react";

const Index = () => {
  return (
    <button type="button" className="login-with-micro-btn auth_btn">
      Sign in with Microsoft
    </button>
  );
};

export default Index; 
