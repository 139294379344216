import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import { toast } from 'react-toastify';
import makeData from './makeData';
import EmployeeJson from '../../../Components/DummyJsons/employee.json'
import FormikControl from "../../../Components/Common/Controls/FormControl";
import Employee from "../../../Components/Common/Modals/Employee";
import Loader from "../../../Components/Common/Loader";
import { Formik, Form } from 'formik';
import Employee_icons from "../../../Assets/images/Icons/add_employee.svg";
import Roles from "../../../Assets/images/Icons/role.svg";
import Edit from "../../../Assets/images/Icons/edit.svg";
import view from "../../../Assets/images/Icons/suit.svg"
import Delete from "../../../Assets/images/Icons/delete.svg";
import DefaultTable from "../../../Components/Common/Tables/DefaultTable"
import ADD from '../../../Components/Common/Buttons/ADD';
import { ModalContext } from '../../../Utils/Context';
import Role from '../../../Components/Common/Modals/Role';
import DeleteEmployee from '../../../Components/Common/Modals/DeleteEmployee';
import EditEmployee from '../../../Components/Common/Modals/Employee';
import { Link } from 'react-router-dom';
import { EmployeeGetAll, EmployeeDelete } from '../../../Utils/services';
import AuthContext from '../../../Utils/auth-context';
import { useAbility } from '../../../Utils/AbilityContext';




function ViewEmployee() {
    const [EmployeeData, setEmployeeData] = useState();
    const [loading, setloading] = useState(false);
    const modalContext = useContext(ModalContext);
    const [searchVal, setSearchVal] = useState('');
    const { closeModal, handleModalData } = modalContext;
    const { isAdmin, permission, user } = useContext(AuthContext);
    const authCtx = useContext(AuthContext);
    function isJSONString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }
    const Users = isJSONString(authCtx?.user)
        ? JSON.parse(authCtx?.user)
        : authCtx?.user;
    const ability = useAbility();
    const fetchData = async () => {
        setloading(true);
        try {
            const result = await EmployeeGetAll();

            if (result && result?.res?.status === true) {
                setEmployeeData(result?.res?.data);
                setloading(false);
            }
        } catch (error) {
            setloading(false);
        }

    };

    useEffect(() => {
        fetchData();
    }, [])


    const data = [{
        icons: Employee_icons,
        name: "Employee",
        links: "row-materials",
    },



    ]

    const isCreate = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'create');
    const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'edit');
    const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'delete');

    const handleDelete = async (val) => {
        if (val) {
            try {
                const result = await EmployeeDelete(val);
                if (result?.res?.status === true) {
                    toast.success(result?.res?.message);
                    closeModal();
                    fetchData();
                }


            }
            catch (error) {
                closeModal();
                toast.error(error.message);
            }
        }
    }
    const handle = (val, res) => {
        const DeleteModal = <DeleteEmployee closeModal={closeModal} data={val} handle={handleDelete} callFuntion={fetchData} />;
        const EditModal = <EditEmployee closeModal={closeModal} data={val} editdata={val} callFuntion={fetchData} />;

        switch (res) {
            case "delete":
                handleModalData(DeleteModal, "md");
                break;

            case "edit":
                handleModalData(EditModal, "md");
                break;

        }

    }

    const handleModal = (val) => {

        const tabsValue = val;
        const EmployeeModal = <Employee closeModal={closeModal} callFuntion={fetchData} />
        const RoleModal = <Role closeModal={closeModal} />;

        switch (tabsValue) {
            case "Employee":
                handleModalData(EmployeeModal, "md");
                break;
            case "Roles":
                handleModalData(RoleModal, "md");
        }

    }



    const columns = useMemo(
        () => [
            {
                Header: "Emp.ID",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Employee Name",
                accessor: "employeeName" // Use the correct accessor key
            },
            {
                Header: "Role",
                accessor: "roleName" // Use the correct accessor key
            },
            {
                Header: "Hrs/Day",
                accessor: (row) => {
                    // Calculate Hrs/Day here, assuming you have EmployeeData structure
                    const hrsPerDay = row.workingHours + '/' + row.totalHours;
                    return <>{hrsPerDay}</>;
                }
            },
            {
                Header: "Contact No.",
                accessor: "employeeNumber" // Use the correct accessor key
            },
            {
                Header: "Email ID",
                accessor: "employeeEmail" // Use the correct accessor key
            },
            {
                Header: 'Actions',
                accessor: (row) => {
                    return (
                        <>
                            <div className='d-flex justify-content-evenly align-items-center f'>
                                <span onClick={isEdit ? () => handle(row, "edit") : null} className='table_icon mx-1'>
                                    <img src={Edit} alt="Edit" />
                                </span>

                                <span className='table_icon mx-1'>
                                    <Link to={`/EmployeeDetails/${row.employeeId}`}>
                                        <img src={view} alt="View" />
                                    </Link>
                                </span>
                                {
                                    Users?.userId != row?.employeeId ? (<span onClick={isDelete ? () => handle(row, "delete") : null} className='table_icon mx-1'>
                                        <img src={Delete} alt="Delete" />
                                    </span>) : ""
                                }


                            </div>
                        </>
                    );
                },
            }
        ],
        []
    );


    return (
        <>
            <div className="container-fluid pb-5">

                <div className='row
                mt-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-8 col-md-8 employee_bar'>
                            <Formik
                            >
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>

                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Employees'
                                                setSearchVal={setSearchVal}
                                                searchVal={searchVal}

                                            />


                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-1 col-md-1 btn_wrap'>
                            <div className='d-flex justify-content-between align-items-center' >
                                {
                                    data && data?.map((val, i) => {
                                        const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'create')
                                        return (
                                            <div onClick={matchingAbility ? () => handleModal(val.name) : null}>
                                                <ADD text={val?.name} source={val.icons} />

                                            </div>


                                        );

                                    })
                                }
                            </div>

                        </div>



                    </div>

                </div>
                <Loader loading={loading} />
               
                <div className='row'>
                    <div className="display_table">
                        {
                            EmployeeData &&
                            <DefaultTable data={EmployeeData} columns={columns} searchVal={searchVal} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
}

export default ViewEmployee