import React from 'react';
import { FaTimes } from 'react-icons/fa';


function Notification({ data }) {

    return (
        <>

            <div className='noti_box' id='custom_scroll'>
                {
                    data && data?.slice(0, 30)?.map((res) => {
                        return (
                            <div className='noti_tile' >
                                <div className='noti_text'>
                                    <p>
                                        {res?.message}
                                    </p>

                                </div>
                                <div>
                                    {/* <span>
                                        <FaTimes />
                                    </span> */}
                                </div>


                            </div>
                        )
                    })
                }







            </div>
        </>


    )
}

export default Notification
