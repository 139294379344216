import React, { useEffect, useState } from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate";
import { toast } from 'react-toastify';
import Loader from "../../../Common/Loader"
import { RoleGetAll, EmployeeCreate } from "../../../../Utils/services"
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

function Employee({ closeModal, editdata, callFuntion }) {
    const [options, setOptions] = useState([]);
    const [data, setdata] = useState()
    const [loading, setLoading] = useState(false);
    const allData = {
        employeeName: "", employeeEmail: "", employeeNumber: "", roleName: "", employeeNumberCode: ""
    };
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await RoleGetAll();
            if (result && result.res.status === true) {
                setLoading(false)
                const datas = result?.res.data?.map((item) => ({
                    id: item?.roleId,
                    label: item?.roleName,
                    value: item?.roleName,
                }));
                setOptions(datas);
            }
            // Handle the fetched data as needed
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };
    useEffect(() => {

        fetchData();

    }, []);

    const handle = async (values) => {
        setLoading(true);
        try {

            if (values.formData.employeeId != null) {
                const { totalHours, workingHours, roleName, employeeEmail, ...rest } = values.formData;
                const data = {
                    ...rest,
                    employeeNumber: rest.employeeNumber.slice(-10), // Assuming employeeNumber is a string
                    employeeNumberCode: (rest.employeeNumber.slice(0, -10)).replace(/[\s-]/g, ""), // Assuming employeeNumber is a string
                    roleId: rest?.roleId || (editdata && editdata.roleId),
                    employeeEmail: values.formData?.employeeEmail.toLowerCase() // Set roleId to rest.roleId if it exists, otherwise use editdata.roleId
                };

                const result = await EmployeeCreate(data);
                if (result?.res?.status) {
                    closeModal();
                    setLoading(false);
                    toast.success(result?.res?.message || "Employee created successfully")
                    callFuntion && callFuntion();
                }
                else {
                    toast.error(result?.res.message)
                    closeModal();
                    setLoading(false);
                }
            }
            else {
                const data = {
                    ...values.formData,
                    employeeNumber: values.formData.employeeNumber.slice(-10),
                    employeeNumberCode: (values.formData.employeeNumber.slice(0, -10)).replace(/[\s-]/g, ""),
                    'roleId': values.formData?.roleId,
                    employeeEmail: values.formData?.employeeEmail.toLowerCase()

                };

                const result = await EmployeeCreate(data);

                if (result?.res?.status === true) {
                    closeModal();
                    setLoading(false);
                    toast.success(result?.res?.message || "Employee created successfully")
                    callFuntion && callFuntion();
                }
                else {
                    toast.error(result?.res.message)
                    closeModal();
                    setLoading(false);
                }
            }


        } catch (error) {
            toast.error(error.message);
            setLoading(false);
            closeModal();
        }
    }

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            roleId: Yup.string().required('Role is required'),
            employeeName: Yup.string().required('Employee name is required'),
            employeeEmail: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            // employeeNumber: Yup.string()
            //     .required('Phone number is required')
            //     .matches(
            //         /^[0-9]{0,3}[0-9]{10}$/,
            //         'Invalid phone number format'
            //     ),
        }),
    });

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: editdata?.employeeName ? editdata : allData,
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-4'>
                                <FormikControl
                                    control='heading'
                                    title='Employee Details'
                                />
                            </div>
                            <div className='mb-4'>

                                <FormikControl
                                    name='formData.roleId'
                                    placeholder='Select a Role'
                                    options={options}
                                    prevalue={formik.values.formData.roleId}
                                    control='SelectBox'
                                />
                            </div>

                            <div className='mb-4'>
                                <div className='d-flex  align-items-center justify-content-between'>
                                    <div className='col-lg-5'>
                                        <FormikControl
                                            control='Input'
                                            placeholder='Name'
                                            name='formData.employeeName'
                                            value={formik.values.formData?.employeeName}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormikControl
                                            control='Phone'
                                            name='formData.employeeNumber'
                                            value={formik.values.formData?.contactNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <FormikControl
                                    control='Input'
                                    placeholder='Email'
                                    name='formData.employeeEmail'
                                    value={formik.values.formData?.employeeEmail}
                                    disabled={editdata?.employeeEmail}
                                />
                            </div>
                            <Button type='submit' text='Update' disabled={formik.isSubmitting} />
                            <Loader loading={loading} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Employee;
