import { useCallback, useState } from "react";
import DeletableNode from "./DeletableNode";
import CustomConnectionLine from "./CustomConnectionLine";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  updateEdge,
} from "reactflow";
import Loader from "../../../Components/Common/Loader";
import "reactflow/dist/style.css";
import { useSelector } from "react-redux";
import AnswerNode from "./AnswerNode";
import QuetionNode from "./QuetionNode";
import { StepEdge } from "reactflow";
import { SmoothStepEdge } from "reactflow";
import { useDispatch } from "react-redux";
import Navigate from '../../../Components/Common/Buttons/Navigate';
import {
  addNewParent,
  updateEdgesofBuilder,
  updateNodesOfBuilder,
} from "../../../states/slices/builderSlice";
import { replaceQuestionContent, setquestions } from "../../../states/slices/questionsSlice";
import { useNavigate, useParams } from "react-router";
import "reactflow/dist/style.css";
import { GETCHATAPI, UPLOADCHATAPi } from "../../../Utils/services";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const nodeTypes = { answerNode: AnswerNode, quetionNode: QuetionNode };
const edgeTypes = { step: StepEdge, smoothstep: SmoothStepEdge, deleteablenode: DeletableNode };
function Builder() {
  let edges = useSelector((state) => state.builder.edges);
  let nodes = useSelector((state) => state.builder.nodes);
  const [loading, setLoading] = useState(false)
  const questions = useSelector((state) => state.questions.questions);
  const location = useLocation();
  const { previd } = location.state || {};
  const navTo = useNavigate()

  const disptach = useDispatch();
  const { id } = useParams()

  const onNodesChange = useCallback(
    (changes) => {
      if (changes[0].type !== "position") {
        return;
      }
      const updatedNodes = applyNodeChanges(changes, nodes);
      disptach(updateNodesOfBuilder(updatedNodes));
    },
    [nodes]
  );
  const onEdgesChange = useCallback(
    (changes) =>
      disptach(updateEdgesofBuilder(applyEdgeChanges(changes, edges))),

    [edges]
  );
  const onConnect = useCallback(
    (changes) => {
      const addStyle = {
        ...changes,
        id: Date.now().toString(),
        style: {
          strokeWidth: 2,
          stroke: "#c0902c",
        },
        type: "deleteablenode",
      };
      const targetNode = nodes.find((item) => item.id === changes.target)
      const targetParentId = targetNode.parentNode || "hello";
      disptach(updateEdgesofBuilder(addEdge(addStyle, edges)));
      disptach(replaceQuestionContent({ replace: changes.source, replaceWith: targetParentId }))
      disptach(addNewParent({ node: changes.target, parent: changes.source }))
    },
    [edges]
  );

  function gotobotHandler() {
    navTo('/chatbot')
  }
  const handleBack = () => {
    

    navTo(`/milestone/${previd}`,)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true)
        const result = await GETCHATAPI(id);
        if (result?.res.chatBotJson) {

          disptach(updateNodesOfBuilder(JSON.parse(result?.res.nodes)))
          disptach(updateEdgesofBuilder(JSON.parse(result?.res.edges)))
          disptach(setquestions(result?.res.chatBotJson))

          setLoading(false)
        } else {
          disptach(updateNodesOfBuilder([
            {
              id: "root",
              position: { x: 0, y: 60 },
              draggable: true,
              type: "answerNode",
              data: { parentNode: ["hello"], height: 190, inputValue: "" },
              style: { border: '1px solid #c0902c', borderRadius: '5px' }
            },
          ]))
          disptach(updateEdgesofBuilder([]))
          disptach(setquestions({
            hello: {
              ansId: "root",
              data: {
                ansText: "",
                isDone: false,
                followUp: [], //{ queId: "", queText: "" } array item .
              },
            },
          }))

          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
        // Handle the error if needed
      }
    };
    fetchData();
  }, [id]);

  const hadleSubmit = async () => {
    const data = {
      subMilestoneId: id,
      chatBotJson: questions,
      edges: JSON.stringify(edges),
      nodes: JSON.stringify(nodes)
    }

    if (data.chatBotJson != null) {
      try {
        setLoading(true)
        const result = await UPLOADCHATAPi(data);
        if (result.res) {
          toast.success(result.res)
          setLoading(false);

        }
        else {
          toast.error(result.res)
          setLoading(false)
        }

      }
      catch (err) {
        toast.error(err)
        setLoading(false)
      }
    }


  }
  console.log(previd,"data")
  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <Loader loading={loading} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineComponent={CustomConnectionLine}

      >
        <Background></Background>
        <MiniMap></MiniMap>
        <Controls></Controls>
      </ReactFlow>

      {/* <div className="d-flex justify-content-between align-items-center"> */}
      <div style={{ position: 'fixed', bottom: '25px', left: '37%' }} onClick={handleBack}>
        <Navigate type='button' text='Back' />
      </div>
      <div className="builder_btn" onClick={hadleSubmit}>
        <Navigate type='submit' text='Save' />
      </div>
      {/* </div> */}


    </div>
  );
}

export default Builder;

