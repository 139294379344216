import React from 'react'
import { useState } from 'react'
import Loader from '../../Loader'
import { Formik, Form } from 'formik';
import Button from "../../Buttons/Navigate"
import SubTitle from '../../Heading/SubTitle';
import FormikControl from "../.././Controls/FormControl";
import * as Yup from 'yup';
import { CREATECUSTOMERAPi, TEARMSCREATEAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';


function AddCondition({ closeModal, callFuntion }) {

    const [Loading, setLoading] = useState(false)

    const handle = async (values) => {
        setLoading(true)
        const data = { ...values?.formData }
        try {
            const result = await TEARMSCREATEAPi(data);
            if (result.res.status == true) {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
                callFuntion()
            }
            else {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
            }

        }
        catch (err) {

            setLoading(false)
            toast.error(err || err.message)
            closeModal()
        }
    };
    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            titleName: Yup.string().required("TitleName is required"),
            termsDescription: Yup.string().required("TermsDescription is required")

        }),
    });
    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: {
                            titleName: "",
                            termsDescription: "",


                        },
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => {

                        return (

                            <Form className="form_default ">

                                <div className='row'>
                                    <Loader Loading={Loading} />
                                    <div className="add_company">
                                        <div className='col-lg-3 col-md-3'>
                                            <div className='text_left my-3'>
                                                <div>
                                                    <SubTitle title="T&C  Name" />
                                                </div>
                                                <div>
                                                    <div className='col-lg-12 col-md-12 my-3'>
                                                        <FormikControl
                                                            control='Input'
                                                            placeholder='Enter Name'
                                                            name="formData.titleName"
                                                        />

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7'>
                                            <div className='text_left my-3'>
                                                <div>
                                                    <SubTitle title="T&C Content" />
                                                </div>
                                                <div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <FormikControl
                                                            control='CustomTextArea'
                                                            name="formData.termsDescription"
                                                            customStyle={{ height: '70vh' }}
                                                        />

                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <Loader loading={Loading} />

                                </div>

                                <Button type='submit' text='Save' disabled={formik.isSubmitting} />

                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default AddCondition