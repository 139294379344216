import overviewimg from "../../Assets/images/Sidebar/overview.svg";
import folder from "../../Assets/images/Sidebar/folder.svg";
import myservice from "../../Assets/images/Sidebar/service.svg";
import user from "../../Assets/images/Sidebar/user.svg";
import download from "../../Assets/images/Sidebar/download.svg";
import overviewimg2 from "../../Assets/images/Sidebar/over_active.svg";
import folder2 from "../../Assets/images/Sidebar/folder_active.svg";
import myservice2 from "../../Assets/images/Sidebar/service_new.svg";
import user2 from "../../Assets/images/Sidebar/user_new.svg";
import download2 from "../../Assets/images/Sidebar/downlaod_new.svg";
import Complete from "../../Assets/images/Sidebar/complete.svg";
import Complete2 from "../../Assets/images/Sidebar/complete_new.svg";
import ongong from "../../Assets/images/Sidebar/ongong.svg";
import ongong2 from "../../Assets/images/Sidebar/ongong_new.svg";
import Employe from "../../Assets/images/Sidebar/employee.svg";
import Employe2 from "../../Assets/images/Sidebar/new_employee.svg";
import custom from "../../Assets/images/Sidebar/customer.svg";
import custom2 from "../../Assets/images/Sidebar/customer_new.svg";
import master from "../../Assets/images/Sidebar/Master_bnw.svg";
import master2 from "../../Assets/images/Sidebar/Master_color.svg";
import scan from "../../Assets/images/Sidebar/scan.svg";
import scan2 from "../../Assets/images/Sidebar/scan_new.svg";

const SIDEBAR_UL = [
  {
    id: 1,
    label: "Overview",
    ref: "dash",
    src1: overviewimg,
    src: overviewimg2,
    path: "/",
    module_id: 4,
  },

  {
    id: 2,
    label: "Projects",
    ref: "projects",
    path: "#",
    src1: folder,
    src: folder2,
    module_id: 1,
    sublist: [
      {
        id: 1,
        label: "Ongoing",
        path: "/ongoingprojects",
        ref: "projects",
        src1: ongong,
        src: ongong2,
        module_id: 1,
      },

      {
        id: 2,
        label: "Complete",
        path: "/completedprojects",
        ref: "projects",
        src1: Complete,
        src: Complete2,
        module_id: 1,
      },
    ],
  },

  {
    id: 3,
    label: "Services",
    ref: "Service",
    src1: myservice,
    src: myservice2,
    path: "/service",
    module_id: 2,
  },

  {
    id: 5,
    label: "Upload",
    ref: "Upload",
    src1: download,
    src: download2,
    module_id: 3,
    path: "/upload",
  },

  {
    id: 6,
    label: "Toolkit",
    ref: "master",
    src1: scan,
    src: scan2,
    module_id: 6,
    path: "/toolkit",
  },
  {
    id: 7,
    label: "Master",
    ref: "master",
    src1: master,
    src: master2,
    module_id: 6,
    path: "/raci",
  },
];
export default SIDEBAR_UL;
