import React, { useState, useEffect, useMemo, useContext } from 'react';
import moment from "moment";
import Title from '../../Components/Common/Heading/Title';
import SubTitle from '../../Components/Common/Heading/SubTitle'
import Default from '../../Components/Common/Buttons/Default'
import DefaultTable from '../../Components/Common/Tables/DefaultTable';
import Loader from '../../Components/Common/Loader';
import Delete from "../../../src/Assets/images/Icons/delete.png"
import UploadLink from "../../Components/Common/Modals/UploadLink";
import DeleteLink from "../../Components/Common/Modals/DeleteLink";
import UploadDocument from "../../Components/Common/Modals/UploadDocument";
import { ModalContext } from '../../Utils/Context';
import filesize from 'filesize';
import { useParams } from 'react-router-dom';
import { DocsDelete, LinkDeleteAPi, ServiceOneGet } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useAbility } from '../../Utils/AbilityContext';
import AuthContext from '../../Utils/auth-context';



function UploadInner() {
    const modalContext = useContext(ModalContext);
    const [loading, setloading] = useState(false);
    const [allData, setallData] = useState([]);
    const [linkData, setLinkData] = useState();
    const [DocData, setDocData] = useState();
    const { closeModal, handleModalData } = modalContext;
    const { isAdmin, permission } = useContext(AuthContext);
    const { id } = useParams();
    const ability = useAbility();

    const fetchData = async () => {
        setloading(true);
        try {
            const result = await ServiceOneGet(id);

            if (result && result?.res?.status === true) {
                setallData(result?.res?.data);
                setLinkData(result?.res?.data.linksList);
                setDocData(result?.res?.data.documentFile)
                setloading(false);
            }
        } catch (error) {
            setloading(false);
        }

    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleDeleteApi = async (val) => {
        if (val) {
            const result = await LinkDeleteAPi(val);
            if (result.res.status === true) {
                toast.success(result.res.message);
                fetchData();
                closeModal();
            }


        }

    }
    const handleDocsDeleteApi = async (val) => {
        if (val) {
            const result = await DocsDelete(val);
            if (result.res.status === true) {
                toast.success(result.res.message);
                fetchData();
                closeModal();
            }

        }

    }
    const handleDelete = (data, data2) => {
        const tabsValue = data2;
        const LinkDelete = <DeleteLink data={data} closeModal={closeModal} handle={handleDeleteApi} />
        const DocDelete = <DeleteLink data={data} closeModal={closeModal} handle={handleDocsDeleteApi} />
        switch (tabsValue) {
            case "Links":
                handleModalData(LinkDelete, "md");
                break;
            case "Docs":
                handleModalData(DocDelete, "md");
                break;
        }

    }
    const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'delete');
    const FeedsColumns_1 = useMemo(
        () => [
            {
                Header: "Added on",
                accessor: (allData, index) => {

                    return (
                        <span className='text-sm'>{moment(allData?.createdAt).format('DD/MM/YYYY')}</span>
                    );
                }
            },
            {
                Header: "Title",
                accessor: allData => {
                    return (
                        <>
                            <span className='text-sm'>{allData?.linkName}</span>
                        </>
                    );
                }
            },

            {
                Header: "Url",
                accessor: allData => {
                    return (
                        <>
                            <a href={allData?.linkUrl} target='_blank'>
                                <span className='cursor-pointer'><u>{allData?.linkUrl}
                                </u> </span></a>
                        </>
                    );
                }
            },
            {
                Header: "Type",
                accessor: allData => {

                    return (
                        <>
                            <span className=''>{allData?.isCommon === true ? "(Default)" : "(Self)"}
                            </span>
                        </>
                    );
                }
            },


            {
                Header: 'Actions',
                accessor: (allData) => {
                    return (
                        <>

                            <span className='d-flex justify-content-start cursor-pointer'>
                                <div className='delte_icon' onClick={isDelete ? () => handleDelete(allData, "Links") : null}>
                                    <img src={Delete} />
                                </div>
                            </span>




                        </>
                    );
                },
            }

        ], []);
    const FeedsColumns_2 = useMemo(
        () => [
            {
                Header: "Added on",
                accessor: (allData, index) => {

                    return (
                        <span className='text-sm'>{moment(allData?.createdAt).format('DD/MM/YYYY')}</span>
                    );
                }
            },
            {
                Header: "Title",
                accessor: allData => {
                    return (
                        <>
                            <span className='text-sm'>{allData?.documentName}</span>
                        </>
                    );
                }
            },

            {
                Header: "Size",
                accessor: allData => {
                    return (
                        <>
                            <span className=''>{(allData?.documentSize)}</span>
                        </>
                    );
                }
            },


            {
                Header: 'Actions',
                accessor: (allData) => {
                    return (
                        <>

                            <span className='d-flex justify-content-start cursor-pointer'>
                                <div className='delte_icon' onClick={isDelete ? () => handleDelete(allData, "Docs") : null}>
                                    <img src={Delete} />
                                </div>
                            </span>




                        </>
                    );
                },
            }

        ], []);

    const handle = (val) => {
        const tabsValue = val;
        const Upload = <UploadLink closeModal={closeModal} Reload={fetchData} />
        const Documnet = <UploadDocument closeModal={closeModal} Reload={fetchData} />


        switch (tabsValue) {
            case "add_documents":
                handleModalData(Documnet, "md");
                break;
            case "add_links":
                handleModalData(Upload, "md");
                break;
        }

    }

    const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'create');

    return (
        <div>   <div className="container-fluid pb-5">
            <div className="row">
                {
                    allData?.serviceName && <Title title={` ${allData?.serviceName} Uploads`} />
                }

                <div className='mt-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {
                                linkData && <SubTitle title="Service Links" />
                            }

                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={matchingAbility === true ? () => handle("add_links") : null}>
                                    <Default text="Add" disabled={!matchingAbility} />
                                </div>

                            </div>
                        </div>



                    </div>
                    <Loader loading={loading} />
                    <div className=" project_table text_left  my-3">
                        {
                            linkData && <DefaultTable data={linkData} columns={FeedsColumns_1} />
                        }

                    </div>
                </div>
                <div className='mt-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <SubTitle title="Documents" />
                        </div>
                        <div className=''>

                            <div className='d-flex justify-content-between align-items-center'>

                                <div onClick={matchingAbility === true ? () => handle("add_documents") : null}>
                                    <Default text="Add" disabled={!matchingAbility} />
                                </div>
                            </div>
                        </div>



                    </div>

                    <div className=" project_table text_left  my-3">
                        {
                            DocData?.length > 0 && <DefaultTable data={DocData} columns={FeedsColumns_2} />
                        }

                    </div>
                </div>



            </div>




        </div></div>
    )
}

export default UploadInner