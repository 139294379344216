import React, { useContext, useEffect } from 'react'
import FormControl from '../../../Components/Common/Controls/FormControl';
import Navigate from '../../../Components/Common/Buttons/Navigate';
import Loader from '../../../Components/Common/Loader';
import { useState } from 'react';
import { ROLEALLAPi } from '../../../Utils/services';
import { ModalContext } from '../../../Utils/Context';
import View from '../../../Components/Common/Buttons/View';
import Role from '../../../Components/Common/Modals/Role';
import { useSearch } from '../../../Components/Common/Hooks/TableSearch/useSearch';

function Roles() {
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const [allData, setallData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { closeModal, handleModalData } = modalContext;
    useSearch(searchVal, allData, setFilteredData, ['roleName']);

    const fetchData = async () => {
        try {

            setLoading(true);
            const result = await ROLEALLAPi();
            if (result && result.res.status === true) {
                const filterData = result?.res?.data?.filter((res) => res?.roleId != 1)
                setallData(filterData);
                setFilteredData(filterData)
                setLoading(false);
            }
            // Handle the fetched data as needed
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (searchVal?.length === 0) {
            setFilteredData(allData);
        }
    }, [searchVal, allData]);

    const handle = () => {

        const Document = <Role closeModal={closeModal} Reload={fetchData} />;
        handleModalData(Document, 'md');
    }
    const handleEdit = (id, val) => {

        if (val === 'edit') {
            const Document = <Role closeModal={closeModal} Reload={fetchData} id={id} isdisabled={false} />;
            handleModalData(Document, 'md');
        }
        else {
            const Document = <Role closeModal={closeModal} Reload={fetchData} id={id} isdisabled={true} />;
            handleModalData(Document, 'md');
        }

    }

    return (
        <div>


            <div className='row mt-3 mb-5'>
                <div className='d-flex justify-content-between  align-items-center mt-1'>
                    <div className='col-lg-5 col-md-7'>
                        <FormControl
                            control='Search'
                            placeholder='Search For Roles'
                            setSearchVal={setSearchVal}
                            searchVal={searchVal}
                        />
                    </div>

                    <div className='col-lg-3 col-md-3' onClick={handle}>
                        <Navigate text='Add Role' />
                    </div>
                </div>
            </div>
            <Loader loading={loading} />
            <div className='row'>
                <div className="project_table mt-3 form_default">
                    <div>

                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Role</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredData?.map((fuel, index) => {

                                    return (
                                        <tr key={index} className='user-select-none text_align_webkit'>

                                            <td>
                                                <div className='col-lg-4'>
                                                    {index + 1}
                                                </div></td>
                                            <td>
                                                <div className='col-lg-4'>

                                                    <p>
                                                        {fuel.roleName}
                                                    </p>

                                                </div>

                                            </td>

                                            <td>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex justify-content-around align-items-center'>
                                                        <div onClick={() => handleEdit(fuel.roleId, "edit")}>
                                                            <View text="Edit" />

                                                        </div>
                                                        <div onClick={() => handleEdit(fuel.roleId, "view")}>
                                                            <View text="view" />

                                                        </div>
                                                    </div>

                                                </div>
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Roles