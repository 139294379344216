import React, { useState, useEffect } from "react";


const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage }) => {
    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];

    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    const [currentPage, setCurrentPage] = useState(1);

    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    // Onclick handlers for the butons
    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onPageSelect = (pageNo) => setCurrentPage(pageNo);

    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
        if (noOfPages === currentPage) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [noOfPages, currentPage]);

    // To set the starting index of the page
    useEffect(() => {
        const skipFactor = (currentPage - 1) * rowsPerPage;
        // Some APIs require skip for paginaiton. If needed use that instead
        // pageChangeHandler(skipFactor);
        pageChangeHandler(currentPage);
    }, [currentPage]);

    return (
        <>
            {noOfPages > 1 ? (
                <div>
                    <div className="d-flex align-items-center justify-content-between ">
                        <button
                            onClick={onPrevPage}
                            disabled={!canGoBack}
                            className="pagin_btn"
                        >
                            &#8249;
                        </button>
                        <div className="d-flex">
                            {pagesArr.map((num, index) => (
                                <div
                                    onClick={() => onPageSelect(index + 1)}
                                    className={`pagin_btn  ${index + 1 === currentPage ? 'pagin_btn_active' : ""
                                        }`}
                                >
                                    {index + 1}
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={onNextPage}
                            disabled={!canGoNext}
                            className="pagin_btn"
                        >
                            &#8250;
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Pagination;