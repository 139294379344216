import React, { useContext, useEffect, useState } from 'react'
import Title from '../../Components/Common/Heading/Title'
import { Form, Formik } from 'formik';
import Cross from "../../Assets/images/Icons/add_service.svg";
import FormControl from '../../Components/Common/Controls/FormControl'
import ADD from '../../Components/Common/Buttons/ADD';
import { ModalContext } from '../../Utils/Context';
import AddScan from '../../Components/Common/Modals/AddScan';
import { toast } from 'react-toastify';
import { GETSCANSAPi } from '../../Utils/services';
import AuthContext from '../../Utils/auth-context';
import { useNavigate } from 'react-router-dom';
import Loader from "../../Components/Common/Loader"
import { useSearch } from '../../Components/Common/Hooks/TableSearch/useSearch';
function SecurityToolkit() {
    const [allData, setallData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const [filteredData, setFilteredData] = useState([]);
    const authContext = useContext(AuthContext);
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate();
    function isJSONString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }
    const Users = isJSONString(authContext?.user)
        ? JSON.parse(authContext?.user)
        : authContext?.user;
    useSearch(searchVal, allData, setFilteredData, ['reportFormat', 'targetUrl','email']);
    const data = [{
        icons: Cross,
        name: "Scan",
        links: "row-materials",
    }];
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await GETSCANSAPi(); 
            if (result.res) {
                setLoading(false);
                setallData(result.res)
                setFilteredData(result.res)
            } else {
                setLoading(false);
            }
        } catch (err) {
            toast.error(err);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (searchVal.length === 0) {
            setFilteredData(allData);
        }
    }, [searchVal, allData]);
    const handleScan = () => {
        const Modal = <AddScan closeModal={closeModal} reload={fetchData} />;
        handleModalData(Modal, "lg");

    }
    const handle = (data) => {
        navigate(`/${data}`)
    }


    return (
        <div>   <div className="container-fluid pb-5">
            <div className='row'>
                <div className='mb-2'>
                    <Title title="Security toolkit" />
                </div>

            </div>
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex justify-content-between align-items-center my-3'>
                    <div className='col-lg-6 col-md-9'>
                        <Formik>
                            {formik => {
                                return (
                                    <Form onSubmit={e => { e.preventDefault(); }}>
                                        <FormControl
                                            control='Search'
                                            placeholder='Search For Scans'
                                            setSearchVal={setSearchVal}
                                            searchVal={searchVal}

                                        />
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    <div className='col-lg-2'>
                        <div className=''>
                            {data && data?.map((val, i) => {
                                return (
                                    <div onClick={handleScan}>
                                        <ADD text={val?.name} source={val.icons} />
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='mt-3 project_table   text-center w-100'>
                    <table>
                        <thead>
                            <tr>
                                <th>Scan ID</th>

                                <th>Scan Type</th>
                                <th>Email</th>
                                <th>Scan On</th>
                                <th>Target</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData && filteredData?.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.reportFormat}</td>
                                    <td>{item.email}</td>
                                    <td>{item.scanAt}</td>
                                    <td>{item.targetUrl}</td>
                                    <td>
                                        <a href={item?.reportUrl} target="_blank" className='cursor-pointer' rel="noreferrer">
                                            View
                                        </a>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

            </div>


        </div></div>
    )
}

export default SecurityToolkit