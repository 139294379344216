import React from "react";

const Index = () => {
  return (
    <button type="button" className="login-with-google-btn auth_btn">
      Sign in with Google
    </button>
  );
};

export default Index;
