import React from 'react'

function ButtonIcon({ text, source }) {
    return (
        <div className='cursor-pointer'>
            <div className='icon_btn'>

                <div className='icon_text'>
                    <p>
                        {text}
                    </p>
                </div>
                <div className='icon_image'>
                    <img src={source} />

                </div>


            </div>

        </div>
    )
}

export default ButtonIcon