import React, { useState, useEffect, useMemo, useContext } from 'react';
import FormikControl from "../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Loader from "../../Components/Common/Loader"
import Company from "../../Assets/images/Icons/company.svg";
import view from "../../Assets/images/Icons/View_profile.svg";
import DefaultTable from "../../Components/Common/Tables/DefaultTable"
import { ModalContext } from '../../Utils/Context'
import { Link } from 'react-router-dom';
import { GETALLSCANSAPi } from '../../Utils/services';
import Navigate from '../../Components/Common/Buttons/Default';
import DatePickerComponent from '../../Components/Common/Controls/Date';
function Scans() {
    const [loading, setLoading] = useState(false);
    const [Customer, setCustomer] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [completeddata, setcompleteddata] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await GETALLSCANSAPi();
            if (result && result?.res) {
                setCustomer(result?.res.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    };
    useEffect(() => {

        fetchData();


    }, [])
    const areDatesEqual = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };
    const filterData = () => {
        debugger
        if (startDate && endDate) {
            // setloading(true);
            let filtered = Customer.filter((product) => {
                let productDate = new Date(product.createdAt);
                return (
                    areDatesEqual(productDate, startDate) ||
                    (productDate >= startDate && productDate <= endDate)
                );
            });
            // setongoingdata(filtered.filter((res) => res?.completedStatus === null))
            // setcompleteddata(filtered.filter((res) => res?.completedStatus != null))
            // setloading(false);
        }

    }

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Name",
                accessor: (Customer) => Customer?.userName || "" // Use conditional rendering
            },
            {
                Header: "Role", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.userRole || "" // Use conditional rendering
            },
            {
                Header: "Scans", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.scanCount || "" // Use conditional rendering
            },
            {
                Header: 'Actions',
                accessor: (Customer) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center cus_input_wid'>
                                <div>
                                    <Link to={`/scan/${Customer?.userId}`}>
                                        <span>
                                            <img src={view} alt="View" />
                                        </span>
                                    </Link>
                                </div>




                            </div>
                        </>
                    );
                },
            }
        ],
        []
    );
    const data = [{
        icons: Company,
        name: "Add Company",

    },



    ]


    return (
        <>
            <div className="container-fluid pb-5">

                <div className='row
                mt-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-6 col-md-6 employee_bar'>
                            <Formik
                            >
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>

                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Scans'
                                                setSearchVal={setSearchVal}
                                                searchVal={searchVal}

                                            />


                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>





                    </div>

                </div>
                <div className='employee_content_inner col-lg-12 col-md-12'>
                    <div className='col-lg-6 col-md-6'>
                        <DatePickerComponent startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} minDate={startDate} />
                    </div>
                    <div onClick={() => filterData()}>
                        <Navigate text="Find Project" />
                    </div>


                </div>
                <div className='row'>
                    <div className="display_table">
                        <Loader loading={loading} />
                        {
                            Customer && <DefaultTable data={Customer} columns={FeedsColumns} searchVal={searchVal} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
}

export default Scans