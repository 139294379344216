import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from "../../Errros/index"

function TextArea({ name, type, placeholder }) {
    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div >
                            <textarea name={name} type={type} placeholder={placeholder} rows="7" maxRows="8" {...field}></textarea>
                            <ErrorMessage component={TextError} name={name} />
                        </div>



                    </div>
                )}
            </Field>
        </div>
    )
}

export default TextArea