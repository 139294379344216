import React, { useContext, useEffect, useState } from 'react'
import Title from '../../Components/Common/Heading/Title'
import { Form, Formik } from 'formik';
import Cross from "../../Assets/images/Icons/add_service.svg";
import FormControl from '../../Components/Common/Controls/FormControl'
import ADD from '../../Components/Common/Buttons/ADD';
import { ModalContext } from '../../Utils/Context';
import AddScan from '../../Components/Common/Modals/AddScan';
import { toast } from 'react-toastify';
import { GETSCANAPi, GENRATESCANREPORTAPi, DOWNLOADSCANREPORTAPi, DOWNLOADSCANREPORTBYIDAPi } from '../../Utils/services';
import AuthContext from '../../Utils/auth-context';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Loader from "../../Components/Common/Loader"
import ButtonLoader from "../../Components/Common/ButtonLoader"
import FileSaver, { saveAs } from 'file-saver';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `2px solid #292728`,
    '&:before': {
        display: 'none',
    },
    borderRadius: '8px',
    margin: 10,
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary  {...props} />

))(({ theme }) => ({
    border: `5px solid #292728`,
    borderRadius: '10px',
    borderColor: 'rgba(255, 255, 255, .05)',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginRight: theme.spacing(5),
    },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),

}));
function ToolkitInner() {
    const [allData, setallData] = useState([]);
    const [completeData, setCompleteData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btnloading, setBtnLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const modalContext = useContext(ModalContext);
    const authContext = useContext(AuthContext);
    const { id } = useParams()
    const { closeModal, handleModalData } = modalContext;
    const data = [{
        icons: Cross,
        name: "Scan",
        links: "row-materials",
    }];
    const fetchData = async () => {

        try {
            setLoading(true);
            const result = await GETSCANAPi(id);
            if (result.res.status === true) {
                setCompleteData(result.res.data)
                const new_result = JSON.parse(result.res.data?.scanDetail);
                if (new_result?.data?.status == "SUCCEEDED") {
                    setDisabled(false)
                }
                setallData(new_result)
                setLoading(false);
            }

        } catch (err) {
            setLoading(false);
            toast.error(err);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handlwe = async () => {
        if (!completeData?.reportUrl) {
            const data =
            {
                "report_format": "PDF",
                "targets_filter": {
                    "target_ids": [allData?.data?.requested_targets[0]?.target_id]
                }
            }
            try {
                setBtnLoading(true)
                // Assuming GENRATESCANREPORTAPi and DOWNLOADSCANREPORTAPi are asynchronous functions
                const result = await GENRATESCANREPORTAPi(id, data);

                if (result.res.status === true) {
                    try {
                        // Assuming DOWNLOADSCANREPORTAPi returns a promise
                        const newResult = await DOWNLOADSCANREPORTAPi(result.res.data.reportId);
                        if (newResult?.res?.status === true) {
                            // Assuming newResult.pdfBlobUrl is the Blob URL of the PDF
                            FileSaver.saveAs(newResult?.res?.data, "Report");
                            toast.success("Report is Genrated")
                            setBtnLoading(false);
                            fetchData();
                        } else {
                            setBtnLoading(false);
                            console.error('Error: No PDF Blob URL received');
                        }
                    } catch (error) {
                        setBtnLoading(false);
                        console.error('Error downloading the PDF:', error);
                    }
                } else {
                    setBtnLoading(false);
                    console.error('Error: Report generation failed');
                }

            }
            catch (err) {

            }
        }
        else {
            toast.warning("Report is already Generate")
        }


    }
    const handleReport = async () => {
        setLoading(true)
        try {
            // Assuming DOWNLOADSCANREPORTAPi returns a promise

            FileSaver.saveAs(completeData?.reportUrl, "Report");
            setLoading(false)
        } catch (error) {
            console.error('Error downloading the PDF:', error);
            setLoading(false)
        }
    }




    return (
        <div>   <div className="container-fluid pb-5">
            <div className='row'>
                <div className='mb-2'>
                    <Title title="Security toolkit" />
                </div>

            </div>
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between align-items-center my-3'>
                    <div className='col-lg-6 col-md-9'>
                        {/* <Formik>
                            {formik => {
                                return (
                                    <Form onSubmit={e => { e.preventDefault(); }}>
                                        <FormControl
                                            control='Search'
                                            placeholder='Search For Scans'

                                        />
                                    </Form>
                                )
                            }}
                        </Formik> */}
                    </div>

                </div>
            </div>
            <div className='row'>


                <Accordion>
                    <AccordionSummary


                        expandIcon={
                            <ArrowForwardIosSharpIcon
                                style={{ cursor: 'pointer' }}
                            />
                        }
                        sx={{ cursor: 'unset !important' }}
                    >
                        <div className='row w-100'>
                            <div className='col-lg-3'>
                                <p>
                                    Type : {allData?.data?.type}
                                </p>
                            </div>
                            <div className='col-lg-3'>

                                Targets : {allData?.data?.requested_targets.map((res) => {

                                    return (
                                        <>
                                            {res?.target}
                                        </>
                                    )
                                })}

                            </div>
                            <div className='col-lg-3'>
                                <p>
                                    Status : {allData?.data?.state}
                                </p>
                            </div>
                            <div className='col-lg-3'>
                                <div className='d-flex justify-content-between'>

                                    <div className='d-flex align-items-center'>
                                        {
                                            completeData && completeData?.reportUrl &&
                                            <div onClick={handleReport} >
                                                <p className='cursor-pointer'>
                                                    View Report
                                                </p>
                                            </div>
                                        }



                                    </div>
                                    {
                                        <div onClick={handlwe} className='d-flex align-items-center'>
                                            <div>
                                                <p className='cursor-pointer'>
                                                    Generate Report
                                                </p>
                                            </div>

                                            <ButtonLoader loading={btnloading} />
                                        </div>

                                    }
                                </div>

                            </div>


                        </div>








                    </AccordionSummary>
                    {/* <AccordionDetails>
                        <Typography>

                        </Typography>
                    </AccordionDetails> */}
                </Accordion>

            </div>


        </div></div>
    )
}

export default ToolkitInner