import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from 'react-select';
import TextError from "../Errros";

function SelectBox(props) {
    const { name, defaultValue, placeholder, options, value, handle,row, ...rest } = props;
    const defaultOptions = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" }
    ];

    const selectOptions = options || defaultOptions;

    return (
        <div>

            <div className='row'>
                <div className="text_left">
                    <Select

                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'black' : 'black',
                                borderWidth: '1px',
                                boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                            }),
                        }}
                        onChange={handle}
                        options={selectOptions}
                        value={value}

                        placeholder={placeholder}
                    />
                    <ErrorMessage component={TextError} name={name} />
                </div>
            </div>

        </div>
    );
}

export default SelectBox;