import React, { useEffect, useState } from 'react';
import DragDrop from '../../../Components/Common/Other/DragDrop';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FormControl from '../../../Components/Common/Controls/FormControl';
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import Button from '../../../Components/Common/Buttons/Navigate';
import MilestoneForm from '../../../Components/Common/Modals/MilestoneForm';
import EditMilestone from '../../../Components/Common/Modals/EditMilestone';
import { ModalContext } from '../../../Utils/Context';
import { useContext } from 'react';
import MultiStepFormContext from "../../../Utils/MultiStepContext";
import CustomMilestoneComponent from './CustomMilestoneComponent';
import View from '../../../Components/Common/Buttons/View';
import { MileStoneDelete, ServiceOneGet, ServiceUpdate } from '../../../Utils/services';
import { toast } from 'react-toastify';
import { useSearch } from '../../../Components/Common/Hooks/TableSearch/useSearch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAbility } from '../../../Utils/AbilityContext';
import AuthContext from '../../../Utils/auth-context';
import Loader from '../../../Components/Common/Loader';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
  margin: 10,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function ViewMileStone({ nextStep, prevStep, setDetails }) {
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const { details } = useContext(MultiStepFormContext);
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(false);
  const [alldata, setalldata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const location = useLocation();
  const state = location.state;
  const isDirect = useParams();
  const navigate = useNavigate();
  const ability = useAbility();
  const { isAdmin, permission } = useContext(AuthContext);
  const [milestoneInput, setMilestoneInput] = useState('');
  const [subMilestoneInput, setSubMilestoneInput] = useState('');
  const [subMilestoneWeightage, setSubMilestoneWeightage] = useState('');
  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState(-1);
  const [editingSubMilestone, setEditingSubMilestone] = useState({
    milestoneIndex: -1,
    subMilestoneIndex: -1,
  });
  const [milestoneError, setMilestoneError] = useState('');
  const [subMilestoneError, setSubMilestoneError] = useState('');

  useSearch(searchVal, alldata, setFilteredData, ['mileStoneName']);

  function getTotalSubMilestoneWeightage(data) {
    return parseInt(data.reduce((totalWeightage, milestone) => {
      // Check if subMilestones property exists and is an array
      if (milestone.subMilestones && Array.isArray(milestone.subMilestones)) {
        // Use reduce to calculate subMilestone weightage
        const subMilestoneWeightage = milestone.subMilestones.reduce((subTotal, subMilestone) => {
          // Check if subWeightage property exists
          if (subMilestone.subWeightage !== undefined) {
            // Add subWeightage to the subtotal
            return subTotal + parseInt(subMilestone.subWeightage);
          }
          return subTotal;
        }, 0);

        // Add subMilestone weightage to the total
        return totalWeightage + subMilestoneWeightage;
      }

      return totalWeightage;
    }, 0));
  }
  const handle = async () => {
    const updatedAlldata = {
      mileStones: alldata.map((milestone) => ({
        ...milestone,
        subMilestones: milestone.subMilestones.map(({ isEditing, ...rest }) => rest),
      })),
    };

    const totalWeightage = getTotalSubMilestoneWeightage(updatedAlldata?.mileStones);

    if (totalWeightage === 100) {
      const data = new FormData();
      data.append("taskCreateRequest", JSON.stringify(updatedAlldata))
      setLoading(true);

      if (updatedAlldata?.mileStones?.length > 0) {
        try {
          const result = await ServiceUpdate(details?.step1?.serviceId ? details?.step1?.serviceId : isDirect?.id, data);
          if (result?.res.status == true) {

            if (isDirect && isDirect.id != null) {
              setLoading(false);
              toast.success(result?.res.message || "Service Updated successfully");
              navigate(`/viewservice/${isDirect?.id}`)
            }
            else {
              nextStep()
              setLoading(false);
              toast.success(result?.res.message || "Service Updated successfully");
            }

          }
          else {
            setLoading(false);
            toast.error(result?.res.message)
          }
        } catch (error) {
          toast.error(error.message);
          setLoading(false);
        }
      }
      else {
        toast.error('Minimum 1 MileStone is Required')
      }

    }
    else {
      toast.error('Miletone is Required with Total Weightage of it is 100')
    }

    // updatedAlldata now contains the same data as alldata but without the "isEditing" field

  }
  const handlechart = (data) => {
    localStorage.removeItem('questions');
    localStorage.removeItem('nodes');
    localStorage.removeItem('edges');
    console.log(isDirect.id,"isDirect.id")
    if (isDirect && isDirect.id) {
      navigate(`/chart/${data}`, { state: { previd: isDirect.id } });
  } else {
      // Navigate to a different route and pass the padding function
      navigate(`/chart/${data}`, { state: { previd:details?.step1?.serviceId } });
  }

  }
  const backHandle = () => {
    if (isDirect && isDirect?.id != null) {

      navigate(`/viewservice/${isDirect.id}`)
    }
    else {
      prevStep()
    }
  }

  const getCheckboxColorClass = (value) => {
    if (value) {
      return 'correct-icon';
    } else {
      return 'wrong-icon';
    }
  };
  const fetchData = async () => {

    try {
      setLoading(true);
      const result = await ServiceOneGet(details?.step1?.serviceId ? details?.step1?.serviceId : isDirect.id);
      if (result?.res?.status === true) {
        setLoading(false);
        setalldata(result?.res?.data?.mileStones)
        setFilteredData(result?.res?.data?.mileStones);

      }
    } catch (error) {
      // Fallback to test data
      setLoading(false);

    }

  };
  useEffect(() => {
    fetchData();
  }, [details?.step1?.serviceId, isDirect.id]);


  const onDragEnd = (updatedData) => {
    setalldata(updatedData);
  };

  const handleDragEndOnce = (result) => {
    if (!result.destination) {
      return; // Dragged outside of list, no action needed
    }
    const updatedMilestones = [...alldata];
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Reorder milestones within the list
    const movedMilestone = updatedMilestones[sourceIndex];
    updatedMilestones.splice(sourceIndex, 1);
    updatedMilestones.splice(destinationIndex, 0, movedMilestone);

    // Update the order property for all milestones
    updatedMilestones.forEach((milestone, index) => {
      milestone.mileStoneNo = index + 1;
    });


    setalldata(updatedMilestones);
    setFilteredData(updatedMilestones)
    // Assuming you want to update the state or perform other actions here
    // (replace this with your actual logic)
    // dispatch(updateMilestones(updatedMilestones));
  };

  const handleMilestoneChange = (event) => {
    setMilestoneInput(event.target.value);
  };

  const handleSubMilestoneChange = (event, milestoneIndex, subMilestoneIndex, key) => {
    const updatedalldata = [...alldata];

    switch (key) {
      case 'input':
        updatedalldata[milestoneIndex].subMilestones[subMilestoneIndex].inputValue = event.target.value;
        break;
      case 'weigth':
        updatedalldata[milestoneIndex].subMilestones[subMilestoneIndex].subWeightage = event.target.value;
        break;
      // Add more cases as needed
      default:
        break;
    }

    setalldata(updatedalldata);
  };


  const addMilestone = () => {
    const trimmedMilestoneInput = milestoneInput.trim();
    if (trimmedMilestoneInput !== '') {
      const updatedalldata = [...alldata];
      updatedalldata.push({
        mileStoneName: trimmedMilestoneInput,
        subMilestones: [],
      });
      setalldata(updatedalldata);
      setMilestoneInput('');
      setMilestoneError('');
    } else {
      setMilestoneError('Milestone name is required.');
    }
  };

  const addSubMilestone = (milestoneIndex) => {
    const trimmedSubMilestoneInput = subMilestoneInput.trim();
    if (trimmedSubMilestoneInput !== '') {
      const updatedalldata = [...alldata];
      updatedalldata[milestoneIndex].subMilestones.push({
        subMilestoneName: trimmedSubMilestoneInput,
        inputValue: trimmedSubMilestoneInput,
        isEditing: false,
      });
      setalldata(updatedalldata);
      setSubMilestoneInput('');
      setSubMilestoneError('');
    } else {
      setSubMilestoneError('Sub Milestone name is required.');
    }
  };

  const handleEditMilestone = (milestoneIndex, ids) => {
    let updated_ = filteredData?.filter(item => item.mileStoneId === ids);

    handleModalData(<EditMilestone id={details ? details : isDirect.id} data={updated_} closeModal={closeModal} Reload={fetchData} />, 'lg');
    // setEditingMilestoneIndex(milestoneIndex);
    // setMilestoneInput(alldata[milestoneIndex].mileStoneName);
  };

  const handleSaveMilestone = (milestoneIndex) => {
    const updatedalldata = [...alldata];
    const milestoneToSave = updatedalldata[milestoneIndex];
    const trimmedMilestoneInput = milestoneInput.trim();

    if (trimmedMilestoneInput !== '') {
      milestoneToSave.mileStoneName = trimmedMilestoneInput;
      setalldata(updatedalldata);
      setEditingMilestoneIndex(-1);
      setMilestoneInput('');
      setMilestoneError('');
    } else {
      setMilestoneError('Milestone name is required.');
    }
  };

  const handleDeleteMilestone = async (milestoneIndex, id) => {
    if (filteredData?.length > 1) {
      const updatedalldata = [...alldata];
      updatedalldata.splice(milestoneIndex, 1);
      setalldata(updatedalldata);
      try {
        const data =
        {
          "taskId": details?.step1?.serviceId ? details?.step1?.serviceId : isDirect.id,
          "milestoneId": id
        }
        const result = await MileStoneDelete(data);
        if (result.res.status == true) {
          toast.success(result.res.message);
          fetchData()
        }
        else {
          toast.error(result.res.message)
        }

      }
      catch (err) {
        toast.error(err.message)
      }
    }
    else {
      toast.error("Minimum 1 MileStone is Necessary");
    }


  };

  const handleEditSubMilestone = (milestoneIndex, subMilestoneIndex) => {
    const updatedalldata = [...alldata];
    const subMilestoneToEdit = updatedalldata[milestoneIndex].subMilestones[subMilestoneIndex];
    subMilestoneToEdit.isEditing = true;
    // Set the input value to the current sub-milestone's subMilestoneName
    subMilestoneToEdit.inputValue = subMilestoneToEdit.subMilestoneName;
    setEditingSubMilestone({ milestoneIndex, subMilestoneIndex });
    setSubMilestoneInput(
      updatedalldata[milestoneIndex].subMilestones[subMilestoneIndex].inputValue
    );
    setalldata(updatedalldata);
  };

  const handleSaveSubMilestone = (milestoneIndex, subMilestoneIndex) => {
    const updatedalldata = [...alldata];
    const subMilestoneToSave =
      updatedalldata[milestoneIndex].subMilestones[subMilestoneIndex];
    const trimmedSubMilestoneInput = subMilestoneToSave.inputValue.trim();

    if (trimmedSubMilestoneInput !== '') {
      subMilestoneToSave.subMilestoneName = trimmedSubMilestoneInput;
      delete subMilestoneToSave.inputValue;
      subMilestoneToSave.isEditing = false;
      setalldata(updatedalldata);
      setEditingSubMilestone({ milestoneIndex: -1, subMilestoneIndex: -1 });
      setSubMilestoneError('');
    } else {
      setSubMilestoneError('Sub Milestone name is required.');
    }
  };

  const handleDeleteSubMilestone = async (milestoneIndex, subMilestoneIndex, id1, id2) => {
    const updatedalldata = [...alldata];
    const count = updatedalldata[milestoneIndex].subMilestones
    if (count?.length > 1) {

      updatedalldata[milestoneIndex].subMilestones.splice(subMilestoneIndex, 1);
      setalldata(updatedalldata);
      try {
        const data =
        {
          "taskId": details?.step1?.serviceId ? details?.step1?.serviceId : isDirect.id,
          "milestoneId": id1,
          "subMilestoneId": id2,
        }
        const result = await MileStoneDelete(data);
        if (result.res.status == true) {
          toast.success(result.res.message);
          fetchData()
        }
        else {
          toast.error(result.res.message)
        }

      }
      catch (err) {
        toast.error(err)
      }
    }
    else {
      toast.error("Minimum 1 Sub-milstone is needed")
    }

  };

  const handleAddMilestone = () => {

    handleModalData(<MilestoneForm id={details ? details : isDirect.id} closeModal={closeModal} Reload={fetchData} />, 'lg');
  };
  useEffect(() => {
    if (searchVal.length === 0) {
      setFilteredData(alldata);
    }
  }, [searchVal, alldata]);
  const isCreate = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
  const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'delete');
  const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'edit');

  return (
    <div>
      <div>
        <div className='container-fluid pb-5'>
          <Loader loading={loading} />
          <div className='row mb-5'>
            <FormControl control='heading' title='Services Details' />
          </div>
          <div className='row'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col-lg-10'>
                <SubTitle title='MileStone & Sub-MileStone' />
              </div>
              <div className='col-lg-2' onClick={isCreate ? () => handleAddMilestone() : null}>
                <Button type='button' text='Add MileStone' disabled={!isCreate} />
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-lg-6'>


              <FormControl
                control='Search'
                title='Search for Services, title, or Url'
                setSearchVal={setSearchVal}
                searchVal={searchVal}

              />
            </div>
          </div>
          <DragDropContext onDragEnd={handleDragEndOnce}>
            {filteredData?.sort((a, b) => a.mileStoneNo - b.mileStoneNo)?.map((milestone, milestoneIndex) => (
              <Droppable key={milestone.mileStoneId} droppableId={`${milestone.mileStoneId}`} type="MILESTONE">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Draggable key={`${milestone.mileStoneId}`} draggableId={`${milestone.mileStoneId}`} index={milestoneIndex}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <Accordion key={milestoneIndex} className="cursor_pointer">
                            <AccordionSummary
                              aria-controls={`panel${milestoneIndex}-content`}
                              id={`panel${milestoneIndex}-header`}
                            >
                              {editingMilestoneIndex === milestoneIndex ? (
                                <div className='view_mile_wrapper'>
                                  <div className='col-lg-9 col-md-8'>
                                    <input
                                      type='text'
                                      value={milestoneInput}
                                      onChange={handleMilestoneChange}
                                    />
                                  </div>
                                  <div className='col-lg-3 d-flex justify-content-between align-items-center col-md-4'>
                                    <div onClick={isEdit ? () => handleSaveMilestone(milestoneIndex) : null} className='mx-2'>
                                      <View text="Save" />
                                    </div>
                                    {/* <div onClick={isDelete ? () => handleDeleteMilestone(milestoneIndex, milestone.mileStoneId) : null}>
                                      <View text="Delete" />
                                    </div> */}
                                  </div>
                                </div>
                              ) : (
                                <div className='view_mile_wrapper'>
                                  <div className='col-lg-9 col-md-8'>
                                    {milestone.mileStoneName}
                                  </div>
                                  <div className='col-lg-3 d-flex justify-content-between align-items-center col-md-4'>
                                    <div className='mx-2' onClick={isEdit ? () => handleEditMilestone(milestoneIndex, milestone?.mileStoneId) : null}>
                                      <View text="Edit" />
                                    </div>
                                    {/* <div onClick={isDelete ? () => handleDeleteMilestone(milestoneIndex, milestone.mileStoneId) : null}>
                                      <View text="Delete" />
                                    </div> */}
                                  </div>
                                </div>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <DragDrop
                                  onDragEnd={onDragEnd}
                                  Component={CustomMilestoneComponent}
                                  milestone={milestone}
                                  data={alldata}
                                  milestoneIndex={milestoneIndex}
                                  handleSaveSubMilestone={handleSaveSubMilestone}
                                  handleSubMilestoneChange={handleSubMilestoneChange}
                                  handleEditSubMilestone={handleEditSubMilestone}
                                  handleDeleteSubMilestone={handleDeleteSubMilestone}
                                  handlechart={handlechart}
                                  subMilestoneInput={subMilestoneInput}
                                  subMilestoneWeightage={subMilestoneWeightage}
                                  disbledDelete={isDelete}
                                  disbledEdit={isEdit}

                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>

        </div >
        <div className='d-flex justify-content-center align-items-center'>
          <div onClick={backHandle}>
            <Button type='button' text='Back' white={true} />
          </div>
          <div onClick={(isCreate || isEdit) ? () => handle() : null}>
            <Button type='button' text='Update' disabled={!(isCreate || isEdit)} />
          </div>
        </div>


      </div >

    </div >
  );
}

export default ViewMileStone;
