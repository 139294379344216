import React from 'react';
import download from "../../../../Assets/images/Icons/download.png";
import Delete from "../../../../Assets/images/Icons/delete.png";
import { saveAs } from 'file-saver';

function FileNotification({ icons, data, handleDownload, hadleDelete }) {
    return (
        <>

            {data?.length != undefined ? (
                <div className='load_tile'>
                    <div className='load_head'>
                        <span>{data?.length} File Uploaded</span>

                    </div>
                    {
                        icons == "1" ? (

                            data && data.map((res) => {
                                return (
                                    <>
                                        <div className='load_tile_content'>
                                            <div>
                                                <p>
                                                    {res?.documentName}
                                                </p>
                                            </div>
                                            <div className='load_icon' >
                                                <img src={Delete} />
                                            </div>
                                        </div>
                                    </>
                                )

                            })


                        ) : (
                            data && data.map((res) => {
                                return (
                                    <div className='load_tile_content'>
                                        <div className='col-lg-6 col-md-6'>
                                            <p>
                                                {res?.documentName}
                                            </p>
                                        </div>
                                        <div>
                                            <p className='default_text'>
                                                {
                                                    res?.isCommon != null ? "(Default)" : "(Self)"
                                                }
                                            </p>
                                        </div>
                                        <div className='load_icon cursor-pointer' onClick={() => handleDownload(res?.documentName, res?.documentS3Url)}>
                                            <img src={download} alt="" />
                                        </div>
                                        <div className='load_icon cursor-pointer' onClick={() => hadleDelete(res)}>
                                            {
                                                (res?.isProject === true || res?.isCommon === null) ? (<img src={Delete} />) : (<div className='mx-3'></div>)
                                            }
                                        </div>
                                    </div>
                                )

                            })

                        )
                    }




                </div>
            ) : (
                <>
                    <div className='load_tile'>
                        <div className='load_head'>
                            <span>{data?.length} File Uploaded</span>

                        </div>
                        {
                            icons == "1" ? (
                                data && data.map((res) => {
                                    <div className='load_tile_content'>
                                        <div>
                                            <p>
                                                {res?.documentName}
                                            </p>
                                        </div>
                                        <div className='load_icon'>
                                            <img src={Delete} />
                                        </div>
                                    </div>
                                })


                            ) : (

                                data && data.map((res, i) => {
                                    <div className='load_tile_content' key={i}>
                                        <div>
                                            <p>
                                                {res?.documentName}
                                            </p>
                                        </div>
                                        <div>
                                            <p className='default_text'>
                                                {
                                                    res?.isCommon === null ? "(Default)" : ""
                                                }

                                            </p>
                                        </div>
                                        <div className='load_icon'>
                                            <img src={download} alt="" />
                                        </div>
                                        <div className='load_icon'>
                                            {
                                                res?.isProject === true ? (<img src={Delete} />) : ("")
                                            }

                                        </div>
                                    </div>
                                })

                            )
                        }




                    </div>
                </>
            )}

        </>
    );
}

export default FileNotification;
