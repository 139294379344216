import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import FormikControl from "../../Controls/FormControl";
import Button from "../../Buttons/Navigate";
import Dummy from "../../../DummyJsons/number_service.json";
import { ServiceALLGet, LinkAdd } from '../../../../Utils/services';
import Loader from '../../Loader';

function UploadLinkManage({ ApiHnadle, closeModal, Reload, projectId }) {

    const [loading, setLoading] = useState(false);

    const allData = {

        linkName: "",
        linkUrl: "",
    };



    const handle = async (values) => {

        const data = {
            "updateLink": values.formData, projectId: projectId,
        }
        const allSubmitData = new FormData();
        allSubmitData.append("projectUpdateRequest", JSON.stringify(data))
        try {

            const result = await ApiHnadle(allSubmitData);

            if (result?.status === true) {

                setLoading(false);
                Reload();
                toast.success(result?.message || "Link Upload successfully");
                closeModal();


            }
            else {
                closeModal();
                toast.success(result?.res.message);
                setLoading(true);
            }
        } catch (error) {
            toast.error(error || "An error occurred");
            closeModal();
            setLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({

            linkName: Yup.string().required("Link name is required"),
            linkUrl: Yup.string().url("Invalid URL format").required("Link URL is required"),
        }),
    });

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: allData,
                    }}
                    onSubmit={handle}
                    validationSchema={validationSchema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-4'>
                                <FormikControl
                                    control='heading'
                                    title='Upload Link'
                                />
                            </div>

                            <Loader loading={loading} />
                            <div className='mb-4'>
                                <FormikControl
                                    control='Input'
                                    placeholder='Enter Title'
                                    name='formData.linkName'
                                />
                            </div>
                            <div className='mb-4'>
                                <FormikControl
                                    control='Input'
                                    placeholder='Paste URL'
                                    name='formData.linkUrl'
                                    type="url"
                                />
                            </div>
                            <div>

                            </div>
                            <Button type='submit' text='Upload' />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default UploadLinkManage;
