import React from "react";
import Input from "../Inputs/DefaultInput";
import InputChange from "../Inputs/InputChange";
import UploadFile from "../DropZone";
import SelectBox from "../SelectBox";
import SelectChange from "../SelectChange";
import Heading from "../Heading";
import SearchInput from "../SearchInput";
import DynmaicTable from "../../Controls/Tables/DynmaicTable";
import DynamicTable2 from "../../Controls/Tables/DynamicTable2"
import Checkbox from "../Checkbox";
import TextArea from "../../Controls/Inputs/TextArea";
import PhoneNumber from "../../Controls/PhoneNumber"
import CustomTextArea from "../Inputs/CustomTextArea"
import MultiSelect from "../MultiSelect"
const FormControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "Input":
      return <Input {...rest} />;
    case "InputChange":
      return <InputChange {...rest} />;

    case "Checkbox":
      return <Checkbox {...rest} />;

    case "UploadFile":
      return <UploadFile {...rest} />;

    case "SelectBox":
      return <SelectBox {...rest} />;
    case "SelectChange":
      return <SelectChange {...rest} />;

    case "heading":
      return <Heading {...rest} />;

    case "Search":
      return <SearchInput {...rest} />;
    case "Phone":
      return <PhoneNumber {...rest} />;

    case "Textarea":
      return <TextArea {...rest} />;

    case "DynmaicTable":
      return <DynmaicTable {...rest} />;

    case "DynmaicTable2":
      return <DynamicTable2 {...rest} />;

    case "CustomTextArea":
      return <CustomTextArea {...rest} />;

    case "MultiSelect":
      return <MultiSelect {...rest} />;
    default:
      return null;
  }
};

export default FormControl;
