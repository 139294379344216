import axios from "axios";
import { GETCHATAPI, GETCHATCONVERSATIONAPi } from "../Utils/services";
import { MileStatus } from "../Utils/services";
import { toast } from "react-toastify";

const handleSubmit = async (ProjectId, setChatOpen) => {
  const data = {
    projectSubmilestoneId: ProjectId,
    status: true,
  };

  try {
   
    const result = await MileStatus(data);

    if (result.res?.status === true) {
      toast.success(result?.res.message);
      setChatOpen(false);
    } else {
      toast.success(result?.res.message);
      setChatOpen(false);
    }
  } catch (error) {
    toast.error(error && error?.message);
  }
};

const updateChatHandler = async (mid, messages, ProjectId) => {
  const postData = {
    projectSubmilestoneId: ProjectId,
    chatConversation: JSON.stringify(messages),
  };

  try {
    const result = await GETCHATCONVERSATIONAPi(postData);
    if (result.res.status == true) {
      toast.success(result.res.message);
    } else {
      toast.success(result.res);
    }
  } catch (err) {
    toast.error(err);
  }
};

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleJavascriptList = (id, questions, mid, ProjectId, setChatOpen) => {
    if (questions?.[id]?.data.statusMarker) {
      handleSubmit(ProjectId, setChatOpen);
    }
    const message = this.createChatBotMessage(questions?.[id]?.data.ansText, {
      widget: "followups",
      payload: id,
    });

    this.updateChatbotState(message, mid, ProjectId);
  };

  updateChatbotState(message, mid, ProjectId) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

    this.setState((prevState) => {
      const msg = [...prevState.messages, message];
      updateChatHandler(mid, msg, ProjectId);
      return {
        ...prevState,
        messages: msg,
      };
    });
  }
}

export default ActionProvider;
