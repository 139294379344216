
import React from 'react'

function Index({ type, text, disabled, white, edit,onClick }) {
    return (
        <div>
            {
                edit && edit ? 
                <button className={edit ? "navigate_btn_edit" : null} type={type} disabled={disabled} onClick={onClick}>
                {text}
            </button> : <button className={white ? "navigate_btn_white" : "navigate_btn"} type={type} disabled={disabled}>
                {text}
            </button>
            }
        </div>
    )
}

export default Index
