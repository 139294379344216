import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import { ModalContext } from '../../../Utils/Context';
import DatePickerComponent from '../../../Components/Common/Controls/Date';
import Navigate from '../../../Components/Common/Buttons/Navigate';
import Profile from '../../../Components/Common/Cards/Profile';
import OngoingMilstone from '../../../Components/Common/Cards/OngoingMilstone';
import CompletedMilestone from '../../../Components/Common/Cards/CompletedMilestone';
import { useParams } from 'react-router-dom';
import { EMPLOYEEONEAPi } from '../../../Utils/services';
import Loader from '../../../Components/Common/Loader';

function EmployeeDetails() {
    const [loading, setloading] = useState(false);
    const modalContext = useContext(ModalContext);
    const [alldata, setallData] = useState({});
    const [mileStoneData, setMileStoneData] = useState([]);
    const [ongoingdata, setongoingdata] = useState([]);
    const [completeddata, setcompleteddata] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const { closeModal, handleModalData } = modalContext;
    const { id } = useParams()

    const fetchData = async () => {
        try {
            setloading(true);
            const result = await EMPLOYEEONEAPi(id);
            if (result?.res?.status === true) {
                setallData(result?.res?.data);
                setMileStoneData(result?.res?.data?.projectSubMilestones)
                setongoingdata(result?.res?.data.projectSubMilestones?.filter((res) => res?.completedStatus === null))
                setcompleteddata(result?.res?.data.projectSubMilestones?.filter((res) => res?.completedStatus != null))

                // Update raciMappingList with fetched data
            }
            setloading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setloading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    const areDatesEqual = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };
    const filterData = () => {
        if (startDate && endDate) {
            setloading(true);
            let filtered = mileStoneData.filter((product) => {
                let productDate = new Date(product.createdAt);
                return (
                    areDatesEqual(productDate, startDate) ||
                    (productDate >= startDate && productDate <= endDate)
                );
            });
            setongoingdata(filtered.filter((res) => res?.completedStatus === null))
            setcompleteddata(filtered.filter((res) => res?.completedStatus != null))
            setloading(false);
        }

    }




    return (
        <>
            <div className="container-fluid pb-5">
                <div className='row'>
                    <div className='employee_content'>
                        <div className='d-flex justify-content-between align-items-start flex-column col-lg-7 col-md-7'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <Title title={alldata?.employeeName} />
                                </div>
                                <div className='my-3'>
                                    <SubTitle title={alldata?.role?.roleName} />

                                </div>
                                <Loader loading={loading} />
                                <div className='employee_content_inner col-lg-12 col-md-12'>
                                    <div className='col-lg-9 col-md-9'>
                                        <DatePickerComponent startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} minDate={new Date(alldata.createdAt)} />
                                    </div>
                                    <div onClick={() => filterData()}>
                                        <Navigate text="Find Project" />
                                    </div>


                                </div>
                            </div>
                            <div className=' my-5 w-100'>
                                <SubTitle title="Ongoing SubMilestone" />
                                <div className='row w-100'>
                                    {
                                        ongoingdata && ongoingdata?.map((res) => {
                                            return (
                                                <div className='col-lg-6 mb-2'>
                                                    <OngoingMilstone data={res} />
                                                </div>
                                            )
                                        })
                                    }



                                </div>

                            </div>



                        </div>


                        <div className='col-lg-3 col-md-3'>
                            <Profile data={alldata} />

                        </div>

                    </div>

                </div>
                <div className='row my-4'>
                    <div>
                        <SubTitle title="Completed SubMilestone" />
                    </div>
                    <div className='row'>
                        {
                            completeddata && completeddata?.map((res) => {
                                return (
                                    <div className='col-lg-4 mb-2'>
                                        <CompletedMilestone data={res} />
                                    </div>
                                )
                            })
                        }




                    </div>

                </div>

            </div>
        </>
    )
}

export default EmployeeDetails                                                         