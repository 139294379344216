import React, { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';
import AuthContext from '../../Utils/auth-context';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import Profile from "../../Assets/images/Icons/profile_head.svg";
import Noti from "../../Assets/images/Icons/notifications.svg";
import Noti_new from "../../Assets/images/Icons/new_notification.svg";
import { Link, useNavigate } from 'react-router-dom';


function UserMenu(props) {
    const { notificationOpen, setNotificationOpen } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const [userData, SetUserData] = useState({})
    const trigger = useRef(null);
    const dropdown = useRef(null);

    const handlelog = () => {
        localStorage.clear();
        navigate('/login')

    }
    const handlePage = () => {
        setDropdownOpen(!dropdownOpen);
    };

    let iconStyles = { fontSize: "1.4em" };
    useEffect(() => {
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx.user)) {
            SetUserData(JSON.parse(authCtx.user));
        } else {
            SetUserData(authCtx.user);
        }
    }, [authCtx.user]);
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });
    const handleNav = () => {
        navigate(`/EmployeeDetails/${userData?.userId}`);
        handlePage();
    }

    return (
        <>


            <div className='d-flex justify-content-between align-items-center'>
                <div className='header_notification'>
                    {
                        !notificationOpen && <span onClick={() => setNotificationOpen(!notificationOpen)} className=''>
                            <img src={Noti} />
                        </span>
                    }



                    {
                        notificationOpen && <span onClick={() => setNotificationOpen(!notificationOpen)} className='' >
                            <img src={Noti_new} />
                        </span>
                    }


                </div >
                <div className="dropdown mx-3">
                    <button
                        ref={trigger}
                        type="button"
                        onClick={handlePage}
                        id="userDropdown"
                        className='user_profile'
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={dropdownOpen}
                    >
                        <img src={Profile} />
                    </button>

                    <div
                        ref={dropdown}
                        className={`dropdown-menu${dropdownOpen ? ' show ' : ''} user_details`}
                        aria-labelledby="userDropdown"
                    >
                        <div className='user_content' >
                            <div className='row'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='user_info'>
                                        <ul onClick={handleNav}>
                                            <li className='user_name' >

                                                {userData?.name}

                                            </li>
                                            <li className='user_role'>

                                                {userData?.primaryRole}

                                            </li>
                                            <li className='user_no'>
                                                {userData?.number ? userData?.number : '+91-9999999999'}


                                            </li>
                                            <li className='user_no'>
                                                {userData?.email}

                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-flex justify-between align-items-center'>
                                        <span><AiFillStar style={iconStyles} /></span>
                                        <span className='mx-1'>{userData ? userData?.rating : ""}</span>
                                    </div>

                                </div>


                            </div>
                            <div className='row my-1'>
                                <hr />

                                <div className='d-flex align-items-center sing_out' onClick={handlelog}>
                                    <div>
                                        <RiLogoutCircleRLine style={iconStyles} />
                                    </div>

                                    <span className='sign_text'>
                                        Sign Out
                                    </span>



                                </div>


                            </div>

                        </div>

                    </div>
                </div>

            </div >

        </>
    );
}

export default UserMenu;
