import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import call from "../../../../Assets/images/Icons/call.svg";
import mail from "../../../../Assets/images/Icons/mail.svg";
import schedule from "../../../../Assets/images/Icons/schedule.svg";
import star from "../../../../Assets/images/Icons/star.svg";


function Profile({ data }) {
    return (
        <>
            <div className='profile_tile'>
                <div className='row'>
                    <div className='d-flex justify-content-center'>
                        {
                            data?.employeeImageUrl ? (<div className='profile_circle'><img src={data?.employeeImageUrl} /></div>) : (<div className='profile_circle_blank profile_circle'></div>)
                        }

                    </div>
                </div>
                <div className='text_left row'>
                    <div className='row mb-1'>
                        <div className='d-flex align-items-center '>
                            <div className='emplyee_star'>

                                <img src={star} />{/* Adjust the size as needed */}
                            </div>
                            <div className='em_pro_cont'>
                                <p className='profile_text'>{data?.rating} Ratings</p>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>
                        <div className='d-flex align-items-center '>
                            <div className='emplyee_star'>
                                {/* <AiFillStar className='emplyee_star' /> */}
                                <img src={schedule} />{/* Adjust the size as needed */}
                            </div>
                            <div className='em_pro_cont'>
                                <p className='profile_text'>{data?.workingHours}/{data?.totalWorkingHours} Duration</p>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='d-flex align-items-center '>
                            <div className='emplyee_star'>
                                {/* <AiFillStar className='emplyee_star' /> */}
                                <img src={call} />{/* Adjust the size as needed */}
                            </div>
                            <div className='em_pro_cont'>
                                <p className='profile_text'>{data?.employeeNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='d-flex align-items-center flex-wrap'>
                            <div className=''>
                                {/* <AiFillStar className='emplyee_star' /> */}
                                <img src={mail} />{/* Adjust the size as needed */}
                            </div>
                            <div className='em_pro_cont'>
                                <p className='profile_text'>
                                    {data?.employeeEmail}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;
