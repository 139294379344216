import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormikControl from "../../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Loader from "../../../Components/Common/Loader";
import ADD from '../../../Components/Common/Buttons/ADD';
import Cross from "../../../Assets/images/Icons/add_service.svg";
import { Link, useNavigate } from "react-router-dom";
import { ServiceALLGet, ServiceGet } from '../../../Utils/services';
import { useAbility } from '../../../Utils/AbilityContext';
import AuthContext from '../../../Utils/auth-context';
import { toast } from 'react-toastify';
import Edit from "../../../Assets/images/Icons/View_profile.svg"; 
import content from "../../../Assets/images/Icons/content_copy.svg"; 
import DefaultTable from '../../../Components/Common/Tables/DefaultTable';
import { ModalContext } from '../../../Utils/Context';
import ClonedService from "../../../Components/Common/Modals/ClonedService"
function ServiceList() {
    const [serviceList, setServiceList] = useState([]);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { isAdmin, permission } = useContext(AuthContext);
    let navigate = useNavigate();
    const ability = useAbility();

    const fetchData = async () => {
        setLoading(true);
        try {

            const result = await ServiceALLGet();
            if (result?.res?.status === true) {
                setLoading(false);
                const filtered = result?.res?.data?.filter(item => item.serviceId !== '38f934ca-4b11-11ee-be56-0242ac120002' && item?.isDraft == true);
                setServiceList(filtered);
                setFilteredData(filtered)
            }
            else {
                setLoading(false);
                toast.error(result?.res?.message)
            }
        } catch (error) {
            setLoading(false);
            toast.error(error)
        }
    };

    useEffect(() => {
        fetchData();

    }, []);
    const handleClone = (result) => {
        handleModalData(<ClonedService resultData={result} Reload={fetchData} closeModal={closeModal} />, 'lg');
    }

    const data = [{
        icons: Cross,
        name: "Services",
        links: "row-materials",
    }];

    const handleNavigate = () => {
        navigate('/addservices');
    }
    useEffect(() => {
        if (searchQuery?.length === 0) {
            setFilteredData(serviceList);
        }
    }, [searchQuery, serviceList]);
    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Name",
                accessor: (Customer) => Customer?.serviceName || "" // Use conditional rendering
            },
            {
                Header: "Type", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.categoryType || "" // Use conditional rendering
            },
            {
                Header: "Status", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.serviceType || "" // Use conditional rendering
            },
            {
                Header: 'Actions',
                accessor: (Customer) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center '>
                                <span className=''>
                                    <Link to={`/viewservice/${Customer.serviceId}`}>

                                        <img src={Edit} alt="Edit" />

                                    </Link>

                                </span>
                                <span className='mx-2 cursor-pointer' onClick={() => handleClone(Customer?.serviceId)}>


                                    <img src={content} alt="Edit" />



                                </span>







                            </div>
                        </>
                    );
                },
            },



        ],
        []
    );

    const matchingAbility = ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
    return (
        <>
            <div className="container-fluid pb-5 px-0">
                <div className='row'>
                    <div className='d-flex justify-content-between align-items-center my-3'>
                        <div className='col-lg-6 col-md-9'>
                            <Formik>
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Services'
                                                setSearchVal={setSearchQuery}
                                                searchVal={searchQuery}
                                            />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-2'>
                            <div className=''>
                                {data && data?.map((val, i) => {

                                    const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create')
                                    return (
                                        <div onClick={matchingAbility ? handleNavigate : undefined}>
                                            <ADD text={val?.name} source={val.icons} disabled={!matchingAbility} />
                                        </div>

                                    );
                                })}
                            </div>
                        </div>



                    </div>
                    <div className='row'>
                        <div className="display_table">
                            <Loader loading={loading} />
                            {
                                serviceList && <DefaultTable data={serviceList} columns={FeedsColumns} searchVal={searchQuery} />
                            }

                        </div>

                    </div>

                </div>


            </div>
        </>
    )
}

export default ServiceList;