import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Navigate from "../../../Components/Common/Buttons/Navigate"
import MDEditor from "@uiw/react-md-editor";

import {
  deleteEdgesofBuilder,
  deleteNodesofBuilder,
  makeNodeConnactable,
  updateEdgesofBuilder,
  updateHeightOfAnswerNode,
  updateInputValueOfNode,
  updateNodesOfBuilder,
} from "../../../states/slices/builderSlice";
import {
  addFollowup,
  addQuestion,
  updateQuestion,
} from "../../../states/slices/questionsSlice";
import { Delete, Edit, Save } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import './tooltip.css'

const handleStyle = {
  backgroundColor: "transparent",
  height: "0px",
  width: "0px",
  borderTop: "12px solid transparent",
  borderBottom: "12px solid transparent",
  borderLeft: "9px solid #c0902c",
};

export default function AnswerNode({ data, isConnectable, id, parentNode }) {
  const [preview, setPreview] = useState("preview");
  const [inputValue, setInputValue] = useState("");
  const dispatchh = useDispatch();
  const nodes = useSelector((state) => state.builder.nodes);
  const edges = useSelector((state) => state.builder.edges);
  const questions = useSelector((state) => state.questions.questions);
  const [deleteButtonVisibility, setDeleteButtonVisibility] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  useEffect(() => {

    if (data.inputValue) {
      setInputValue(data.inputValue)
      setPreview("preview");
    } else {
      setPreview("edit");
    }
  }, [data.inputValue]);

  useEffect(() => {
    let flag;
    data.parentNode.forEach((item) => {
      if (questions[item]?.data?.followUp?.length === 0) {
        flag = true;
      }
    });
    if (flag) {
      setDeleteButtonVisibility(true);
    } else {
      setDeleteButtonVisibility(false);
    }
  }, [questions]);



  function saveClickHandler() {
    if (inputValue) {
      setPreview((pre) => {
        return pre === "preview" ? "edit" : "preview";
      });
    }
    if (preview === "edit") {
      dispatchh(updateInputValueOfNode({ id: id, inputValue: inputValue }));
      data.parentNode.forEach((id) => {
        dispatchh(
          updateQuestion({
            queId: id,
            ansText: inputValue,
          })
        );
      });
    }
  }

  function createFollowupsHandler() {
    let pos = 0;
    nodes.forEach((item) => {
      if (item?.parentNode === id) {
        if (item.position.y > pos) {
          pos = item.position.y;
        }
      }
    });

    if (pos === 0) {
      pos = 120;
    }

    const newQuestionNode = {
      id: Date.now().toString(),
      position: { x: 0, y: pos + 60 },
      draggable: false,
      isConnectable: false,
      isConnectableStart: false,
      type: "quetionNode",
      parentNode: id,
      data: { parentNode: id, inputValue: "", statusMarker: false, maxConnections: 1 },
      extent: "parent",
      style: { width: "450px" },
    };

    const newAnswerNode = {
      id: Date.now().toString() + "1",
      parentNode: newQuestionNode.id,
      position: { x: 600, y: pos - 60 },
      draggable: true,
      type: "answerNode",
      isConnectable: true,
      data: { parentNode: [newQuestionNode.id], height: 190, inputValue: "" },
      style: { border: "1px solid #c0902c", borderRadius: "5px", width: "480px" },
    };

    const newEdge = {
      id: Date.now().toString(),
      source: newQuestionNode.id,
      target: newAnswerNode.id,
      type: "smoothstep",
      style: {
        strokeWidth: 2,
        stroke: "#c0902c",
      },
    };

    dispatchh(updateEdgesofBuilder([...edges, newEdge]));
    dispatchh(updateNodesOfBuilder([...nodes, newQuestionNode, newAnswerNode]));
    dispatchh(
      addQuestion({ queId: newQuestionNode.id, ansId: newAnswerNode.id })
    );
    data.parentNode.forEach((id) => {
      dispatchh(addFollowup({ queId: id, newQueId: newQuestionNode.id }));
    });

    dispatchh(updateHeightOfAnswerNode({ id: id, type: "increase" }));
  }

  const divStyle = {
    height: `${data.height}px`,
    backgroundColor: 'white',
    borderRadius: '5px'
  };
  const customToolbarConfig = {
    options: ["bold", "italic", "underline"], // Include only the buttons you want
  };

  function deleteClickHandler() {
    dispatchh(deleteNodesofBuilder(id));
    dispatchh(deleteEdgesofBuilder(data.parentNode + ""));
    dispatchh(makeNodeConnactable({ id: data.parentNode[0] }))
  }

  return (
    <div style={divStyle}>

      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={handleStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && deleteButtonVisibility && id !== "root" ? (
          <IconButton
            onClick={deleteClickHandler}
            style={{
              position: "relative",
              top: "-22px",
              left: "-25px",
              zIndex: "2",
            }}

          >
            <Delete sx={{ color: "red" }}></Delete>
          </IconButton>
        ) : (
          ""
        )}
      </Handle>

      <div className="d-flex align-items-start justify-content-between flex-column p-2">
        <div className="d-flex align-items-center justify-content-between custooltip " style={{ width: '100%' }}>
          <div data-color-mode="light" className="nodrag" style={{ width: '100%' }}>
          <MDEditor
              value={inputValue}
              onChange={(val) => {
                setInputValue(val);
              }}
              height={100}
              preview={preview}
              extraCommands={[]}
              visibleDragbar={false}
              config={customToolbarConfig}
              overflow={false}
              highlightEnable={false}
            ></MDEditor>
            {/* <textarea 
            
               onChange={(val) => {
                 setInputValue(val);
               }}>
                {inputValue}
            </textarea> */}

            <div className="custooltip-text">
              <MDEditor.Markdown
                source={inputValue}
                highlightEnable={true}
              />
            </div>
          </div>

          <div className="text-center mx-2 ">
            <IconButton onClick={saveClickHandler}>
              {preview === "edit" ? (
                <Save sx={{ color: "green" }}></Save>
              ) : (
                <Edit sx={{ color: "blue" }}></Edit>
              )}
            </IconButton>
          </div>
        </div>
        <div
          onClick={createFollowupsHandler}
        >
          <Navigate text="Create followups +" Type="button" />
        </div>

      </div>
    </div>
  );
}
