const Module_UL = [
  {
    module_id: 1,
    module_name: "Project",
    check: "Projects",
    Action: ["create", "edit", "view", "delete"],
  },
  {
    module_id: 3,
    module_name: "Upload",
    check: "Upload",
    Action: ["create", "edit", "view", "delete"],
  },

  {
    module_id: 2,
    module_name: "Services",
    check: "Services",
    Action: ["create", "edit", "view", "delete"],
  },
  {
    module_id: 4,
    module_name: "Overview",
    check: "Overview",
    Action: ["create", "edit", "view", "delete"],
  },
];

export default Module_UL;
