import React, { useState, useEffect, useMemo, useContext } from 'react';
import FormikControl from "../../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Loader from "../../../Components/Common/Loader"
import Company from "../../../Assets/images/Icons/company.svg";
import view from "../../../Assets/images/Icons/View_profile.svg";
import DefaultTable from "../../../Components/Common/Tables/DefaultTable";
import AddCondition from "../../../Components/Common/Modals/AddCondition"
import EditConditions from "../../../Components/Common/Modals/EditConditions"
import { ModalContext } from '../../../Utils/Context'
import { Link } from 'react-router-dom';
import { TEARMSGETALLAPi } from '../../../Utils/services';
import ADD from '../../../Components/Common/Buttons/ADD';




function TermsAndCondition() {
    const [loading, setLoading] = useState(false);
    const [Customer, setCustomer] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await TEARMSGETALLAPi();
            if (result && result?.res) {
                setCustomer(result?.res.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    };
    useEffect(() => {

        fetchData();


    }, [])

    const handleADD = () => {
        const EmployeeModal = <AddCondition closeModal={closeModal} callFuntion={fetchData} />
        handleModalData(EmployeeModal, "lg");
    }
    const handleEdit = (data) => {
        const EmployeeModal = <EditConditions closeModal={closeModal} callFuntion={fetchData} id={data} />
        handleModalData(EmployeeModal, "lg");
    }


    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Name",
                accessor: (Customer) => Customer?.titleName || "" // Use conditional rendering
            },
            {
                Header: 'Actions',
                accessor: (Customer) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center cus_input_wid'>
                                <div onClick={() => handleEdit(Customer?.termsId)}>

                                    <span>
                                        <img src={view} alt="View" />
                                    </span>

                                </div>




                            </div>
                        </>
                    );
                },
            }
        ],
        []
    );
    const data = [{
        icons: Company,
        name: "Add T&C",

    },



    ]


    return (
        <>
            <div className="container-fluid pb-5">

                <div className='row
                mt-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-6 col-md-6 employee_bar'>
                            <Formik
                            >
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>

                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Scans'
                                                setSearchVal={setSearchVal}
                                                searchVal={searchVal}

                                            />


                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>

                        <div className='col-lg-2 col-md-2'>
                            {
                                data && data?.map((val, i) => {

                                    return (
                                        <div onClick={handleADD}>
                                            <ADD text={val?.name} source={val.icons} />

                                        </div>


                                    );

                                })
                            }
                        </div>



                    </div>

                </div>
                <div className='row'>
                    <div className="display_table">
                        <Loader loading={loading} />
                        {
                            Customer && <DefaultTable data={Customer} columns={FeedsColumns} searchVal={searchVal} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
}

export default TermsAndCondition