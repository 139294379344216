import React, { useContext, useEffect, useMemo, useState } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import FormikControl from "../../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Loader from "../../../Components/Common/Loader";
import ADD from '../../../Components/Common/Buttons/ADD';
import Subheading from '../../../Components/Common/Heading/SubTitle';
import Square from "../../../Components/Common/Buttons/Square";
import Cross from "../../../Assets/images/Icons/add_service.svg";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { ServiceALLGet, ServiceGet, ServiceUpdate } from '../../../Utils/services';
import { useSearch } from "../../../Components/Common/Hooks/TableSearch/useSearch"
import Can from "../../../Utils/Can"
import { useAbility } from '../../../Utils/AbilityContext';
import AuthContext from '../../../Utils/auth-context';
import { toast } from 'react-toastify';
import Edit from "../../../Assets/images/Icons/View_profile.svg";
import DefaultTable from '../../../Components/Common/Tables/DefaultTable';
import Mainbtn from "../../../Components/Common/Buttons/Navigate";
function ActiveService() {
    const [serviceList, setServiceList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValues, setInputValues] = useState({}); 
    const { isAdmin, permission } = useContext(AuthContext);
    let navigate = useNavigate();
    const ability = useAbility();

    const fetchData = async () => {
        setLoading(true);
        try {

            const result = await ServiceALLGet();
            if (result?.res?.status === true) {
                setLoading(false);
                const filtered = result?.res?.data?.filter(item => item.serviceId !== '38f934ca-4b11-11ee-be56-0242ac120002' && (item?.isDraft == false));
                setServiceList(filtered);
                setFilteredData(filtered)
            }
            else {
                setLoading(false);
                toast.error(result?.res?.message)
            }
        } catch (error) {
            setLoading(false);
            toast.error(error)
        }
    };

    useEffect(() => {
        fetchData();

    }, []);


    const data = [{
        icons: Cross,
        name: "Services",
        links: "row-materials",
    }];

    const handleNavigate = () => {
        navigate('/addservices');
    }
    useEffect(() => {
        if (searchQuery?.length === 0) {
            setFilteredData(serviceList);
        }
    }, [searchQuery, serviceList]);


    const SaveData = async (serviceId) => {
        console.log(inputValues, "inputValues");
        const inputValue = inputValues[serviceId]; // Get input value specific to the serviceId
        console.log(inputValue, "inputValue");
      
        const data = new FormData();
        data.append('taskCreateRequest', JSON.stringify({ serviceOrder: parseInt(inputValue, 10) }));
      
        try {
          setLoading(true);
      
          const result = await ServiceUpdate(serviceId, data);
          if (result && result.res.status === true) {
            toast.success(result.res.message);
            fetchData();
            setLoading(false);
            // setInputValues({})
          } else {
            toast.error(result.res.message);
          }
        } catch (error) {
          setLoading(false);
          console.error('Validation failed or error fetching data:', error);
        }
      };


      const handleInputChange = (event, serviceId) => { 
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [serviceId]: event.target.value, // Update input value specific to the serviceId
        }));
      };
      
      // Log updated input values whenever they change
      useEffect(() => {
        console.log(inputValues, "inputValues");
      }, [inputValues]);
      const FeedsColumns = useMemo(
        () => [
          {
            Header: "Sno.",
            accessor: (row, index) => {
              return (
                <>
                  <span className="text-sm">{index + 1}</span>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: (Customer) => Customer?.serviceName || "", // Use conditional rendering
          },
          {
            Header: "Type", // The header text for this column is "Service"
            accessor: (Customer) => Customer?.categoryType || "", // Use conditional rendering
          },
          {
            Header: "Status", // The header text for this column is "Service"
            accessor: (Customer) => Customer?.serviceType || "", // Use conditional rendering
          },
          {
            Header: "Actions",
            accessor: (Customer) => {
              return (
                <>
                  <div className="d-flex justify-content-around align-items-center ">
                    <span className="">
                      <Link to={`/viewservice/${Customer.serviceId}`}>
                        <img src={Edit} alt="Edit" />
                      </Link>
                    </span>
                    <div className="d-flex align-items-center  ">
                      <input
                        type="number"
                        placeholder="Value"
                        className="form-control mr-2"
                        style={{ width: "100px" }}
                        //value={inputValues[Customer.serviceId] || ""} // Bind state value to input
                        defaultValue={Customer?.serviceOrder}
                        onChange={(e) => handleInputChange(e, Customer.serviceId)} // Update state on change
                      />
                      <Mainbtn
                        text="Save"
                        edit="edit"
                        onClick={() => SaveData(Customer?.serviceId)}
                      />
                    </div>
                  </div>
                </>
              );
            },
          },
        ],
        [inputValues] // Add inputValues as a dependency
      );
      

    const matchingAbility = ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
    return (
        <>
            <div className="container-fluid pb-5  px-0">
                <div className='row'>
                    <div className='d-flex justify-content-between align-items-center my-3'>
                        <div className='col-lg-6 col-md-9'>
                            <Formik>
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Services'
                                                setSearchVal={setSearchQuery}
                                                searchVal={searchQuery}
                                            />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-2'>
                            <div className=''>
                                {data && data?.map((val, i) => {

                                    const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create')
                                    return (
                                        <div onClick={matchingAbility ? handleNavigate : undefined}>
                                            <ADD text={val?.name} source={val.icons} disabled={!matchingAbility} />
                                        </div>

                                    );
                                })}
                            </div>
                        </div>



                    </div>
                    <div className='row'>
                        <div className="display_table">
                            <Loader loading={loading} />
                            {
                                serviceList && <DefaultTable data={serviceList} columns={FeedsColumns} searchVal={searchQuery} />
                            }

                        </div>

                    </div>

                </div>


            </div>
        </>
    )
}

export default ActiveService;