import React from 'react'
import { LuClock3 } from 'react-icons/lu';
import { AiFillStar } from 'react-icons/ai';
import star from "../../../../Assets/images/Icons/star.svg";

function CompletedMilestone({ data }) {

    return (
        <>
            <div className='completed_milestone'>
                <div className='d-flex justify-content-between align-items-center flex-column'>

                    <div className='ongoing_milestone_inner ser_name'>

                        <div>
                            <span>
                                {data?.serviceName}
                            </span>
                        </div>
                        <div>

                            <span className='mx-2'><LuClock3 /></span>


                            <span>{data?.workingHours}</span>

                        </div>

                    </div>
                    <div className='ongoing_milestone_inner'>

                        <div>
                            <span className=''>
                                Company Name:
                            </span>
                            <span>
                                {data?.companyName}
                            </span>
                        </div>
                        <div>

                            <span className='mx-2 comple_mile_star'>
                                <img src={star} />
                            </span>


                            <span>4.3</span>

                        </div>

                    </div>
                    <div className='ongoing_milestone_inner'>

                        <div>
                            <span className=''>
                                Action :
                            </span>

                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}

export default CompletedMilestone