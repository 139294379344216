import React, { useEffect, useState } from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate";
import { toast } from 'react-toastify';
import Loader from "../../../Common/Loader"
import { RaciCreate } from "../../../../Utils/services";
import { ModalContext } from '../../../../Utils/Context';
import { Formik, Form } from 'formik';

import * as Yup from 'yup'

function AddValue({ closeModal,Reload }) {
    const [options, setOptions] = useState([]);
    const [data, setdata] = useState()
    const [loading, setLoading] = useState(false);
    const allData = {
        stakeHolder: "",
    };

    const handle = async (values) => {
        const data = {
            ...values.formData,

        };

        setLoading(true);
        try {
            const result = await RaciCreate(data);
            if (result?.status == true) {
                closeModal();
                setLoading(false);
                toast.success(result?.message || "Raci created successfully")
                Reload && Reload();

            }

        } catch (error) {
            closeModal();
             toast.error(error.message);
            setLoading(false);
        }
    }

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            stakeHolder: Yup.string().required('StakeHolder is required'),

        }),
    });

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: allData,
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-5'>
                                <FormikControl
                                    control='heading'
                                    title='Add Value'
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='d-flex  align-items-center justify-content-between'>
                                    <div className='col-lg-12 mb-5'>
                                        <FormikControl
                                            control='Input'
                                            placeholder='Value'
                                            name='formData.stakeHolder'
                                            value={formik.values.formData?.stakeHolder}
                                        />
                                    </div>

                                </div>
                            </div>

                            <Button type='submit' text='Add' disabled={formik.isSubmitting} />
                            <Loader loading={loading} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AddValue;
