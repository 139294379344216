import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
const Progressbar = (props) => {
    const { maxCompleted, completed } = props;
    return (
        <div className="">
            <ProgressBar
                isLabelVisible={false}
                completed={completed}
                maxCompleted={maxCompleted}
                bgColor="#c0902c"
                baseBgColor="#ecd8af"
                labelSize="12px"
            />
        </div>
    );
};

export default Progressbar;