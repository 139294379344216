// Index.js (or App.js)
import React, { useContext, useEffect, useMemo, useState } from 'react';
import MainWrapper from './MainWrapper';
import { useLocation } from 'react-router-dom';
import Sidenav from './Sidenav';
import CommonModal from '../Common/CommonModal';
import Header from './Header';
import { ModalContext } from '../../Utils/Context';
import { AbilityContext, createAppAbility } from '../../Utils/AbilityContext';
import store from "../../states/store";
import { Provider } from "react-redux";

import AuthContext from '../../Utils/auth-context';

export default function   Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [modalSize, setModalSize] = useState('');
  const [data, setData] = useState({});
  const [toggle, setToggle] = useState(true);
  const [toggle2, setToggle2] = useState(true);
  const location = useLocation();
  const isPageAddService = location.pathname.includes('addservice') || location.pathname.includes('manageprojects');
  const containerClassName = `page-container ${isPageAddService ? '' : 'bg_def'}`;
  const { isAdmin, perMission } = useContext(AuthContext);

  const appAbility = useMemo(() => createAppAbility(isAdmin, perMission), [
    isAdmin,
    perMission,
  ]);

  const handleModalData = (data, size) => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handletoggle = () => {
    if (toggle2) {
      setToggle(!toggle);
    }
  };

  // Code to get the current screen size and change the toggle of the sidebar
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    ;
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (windowSize.innerWidth < 963) {
      setToggle(false);
      setToggle2(true);
    } else if (windowSize.innerWidth > 963) {
      setToggle(true);
      setToggle2(false);
    }
  }, [windowSize.innerWidth]);

  return (
    <>
      <Provider store={store}>
        <AbilityContext.Provider value={appAbility}>
          <ModalContext.Provider
            value={{
              handleModalData,
              closeModal,
              setData,
              data,
              isModalOpen,
              modalData,
            }}
          >
            <Header handletoggle={handletoggle} toggle={toggle} />  
            <div className="Layout d-flex">
              <div className={toggle ? 'side-nav' : 'side-nav1'}>
                <Sidenav toggle={toggle} handletoggle={handletoggle} />
              </div>
              <div className={containerClassName}>

                <MainWrapper />

              </div>
            </div>
          </ModalContext.Provider>
        </AbilityContext.Provider>
      </Provider>

      <CommonModal
        handleModalData={handleModalData}
        isModalOpen={isModalOpen}
        modalData={modalData}
        modalSize={modalSize}
        closeModal={closeModal}
      />
    </>
  );
}
