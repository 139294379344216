import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useGoogleLogin } from "react-google-login";
import { loginService } from "../../../Utils/services";
import { toast } from 'react-toastify';
import FnC_logo from "../../../Assets/images/Logos/FnC_logo/FnC_logo.png";
import loginlogo from "../../../Assets/images/Auth/Login/login_banner/desk.png";
import Loader from "../../../Components/Common/Loader"
import Google from "../../../Components/Common/Buttons/Google";
import Microsoft from "../../../Components/Common/Buttons/Microsoft"
import AuthContext from '../../../Utils/auth-context';
import MicrosoftLogin from "react-microsoft-login";
import { useNavigate } from 'react-router-dom';



function Index() {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const clientId = "260794713610-p9pd3lvdutj6q482af1hmcq7aa51urbj.apps.googleusercontent.com";
    const REDIRECT_URI = 'http://localhost:3000/login';

    const onLoginSuccess = async (response) => {
        if (response) {
            const data = {
                'idToken': response?.tokenId, // Use correct property name
                "isEmployee": "true",
                "loginProvider": 1,
                'name': response?.profileObj?.name,
                'imageUrl': response?.profileObj?.imageUrl,
                'email': response?.profileObj?.email,

            };
            try {
                setloading(true)
                const result = await loginService(data);
                if (result?.res.status === true) {
                    authCtx.login(result?.res?.data);
                    setloading(false)
                    localStorage.setItem('user', JSON.stringify(result?.res?.data));
                    localStorage.setItem('access_token', result?.res?.data?.accessToken);
                    localStorage.setItem('permission', JSON.stringify(result?.res?.data?.modulePermissions));
                    localStorage.setItem('admin', result?.res?.data?.primaryRole === "ADMIN" ? true : false);
                    navigate('/');
                }
            } catch (error) {

                setloading(false)
                toast.error(error);
                {
                    error && error.message && toast.error(error.message)
                }
                console.error('Error in loginService:', error);
                // Handle error from loginService
            }
        }
    };
    const onLoginFailure = (error) => {
        console.error('Google login failure:', error);
        // Handle login failure here
    };

    const { signIn } = useGoogleLogin({
        clientId,
        onSuccess: onLoginSuccess,
        onFailure: onLoginFailure,
        cookiePolicy: 'single_host_origin'
    });

    const onLoginStart = (data) => {

    }
    const signInM = async (err, data) => {
        if (data) {
            const alldata = {
                "idToken": data?.tokenId,  // Use correct property name
                "isEmployee": "true",
                "loginProvider": 2,
                "name": data?.account?.name,
                "email": data?.account?.username,

            };
            try {
                setloading(true)
                const result = await loginService(alldata);
                if (result?.res.status === true) {
                    authCtx.login(result?.res?.data);
                    setloading(false)
                    localStorage.setItem('user', JSON.stringify(result?.res?.data));
                    localStorage.setItem('access_token', result?.res?.data?.accessToken);
                    localStorage.setItem('permission', JSON.stringify(result?.res?.data?.modulePermissions));
                    localStorage.setItem('admin', result?.res?.data?.primaryRole === "ADMIN" ? true : false);
                    navigate('/');
                }
            } catch (error) {

                setloading(false)
                toast.error(error);
                {
                    error && error.message && toast.error(error.message)
                }
                console.error('Error in loginService:', error);
                // Handle error from loginService
            }
        }
        else {
            toast.error(err)
        }
    }
    return (
        <>
            <div className="login-screen">
                <div className="container-fluid">
                    <div className="row">
                        <div className='login_grid'>
                            <div className="col-lg-6  col-md-6 p-0">
                                <div className='row login-logo'>
                                    <div className='fn_logo'>
                                        <img src={FnC_logo} alt="" />
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="login-banner">
                                        <div className='login-content'>
                                            <img src={loginlogo} alt="" />

                                        </div>



                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 logins_wrap">
                                <div className='row'>
                                    <div className="form">
                                        <div className='login_text'>
                                            <h1 className='login_text_1'>Welcome </h1>
                                            <p className='login_text_2'>Log In with to continue</p>
                                        </div>

                                    </div>
                                </div>
                                <Loader loading={loading} />
                                <div className="row">
                                    <div className="login_btn">
                                        <div onClick={signIn}>

                                            <Google />

                                        </div>
                                        <div className='mt-4'>

                                            <div>
                                                <MicrosoftLogin clientId='c4bc00e9-155f-465a-b25b-64567288c530' authCallback={signInM} >
                                                    <Microsoft />
                                                </MicrosoftLogin>


                                            </div>
                                        </div>



                                    </div>
                                </div>


                            </div> 
                        </div>



                    </div>
                </div>
            </div>
        </>

    )
}

export default Index