import React, { useEffect, useState } from 'react';
import View from '../../Buttons/View';

function EditableTable({ data, handle, isEditable }) {
    const [editedData, setEditedData] = useState(data);
    const [editIndex, setEditIndex] = useState(-1);

    const handleEdit = (index) => {
        setEditIndex(index);
    };

    const handleSave = (index, ids) => {

        // Perform validation and save the data
        // For simplicity, let's assume validation is successful
        const data = editedData?.filter((res) => res?.taskRaciId == ids);
        const updatedData = [...editedData];
        setEditIndex(-1); // Exit edit mode
        setEditedData(updatedData);
        handle(data)


    };

    const handleInputChange = (index, field, value) => {
        const updatedData = [...editedData];
        updatedData[index][field] = value;
        setEditedData(updatedData);
    };

    const getCheckboxColorClass = (value) => {
        if (value) {
            return 'correct-icon';
        } else {
            return 'wrong-icon';
        }
    };
    useEffect(() => {
        setEditedData(data)
    }, [data]);
    console.log(editedData, "editedData")
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>S.No. </th>
                        <th>Value</th>
                        <th>Responsible(R)</th>
                        <th>Accountable(A)</th>
                        <th>Consulted(C)</th>
                        <th>Informed(I)</th>
                        {
                            isEditable && <th></th>
                        }

                    </tr>
                </thead>
                <tbody>
                    {editedData?.map((res, index) => {
                        const isRowEdited = index === editIndex;

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <span>{res?.stakeHolder}</span>
                                </td>
                                <td>
                                    {isRowEdited ? (
                                        <input
                                            type='checkbox'
                                            checked={res.raciR}
                                            onChange={(e) =>
                                                handleInputChange(index, 'raciR', e.target.checked)
                                            }
                                            className={getCheckboxColorClass(res.raciR)}
                                        />
                                    ) : res.raciR ? (
                                        <span className={getCheckboxColorClass(res.raciR)}>
                                            &#10004;
                                        </span>
                                    ) : (
                                        <span className={getCheckboxColorClass(res.raciR)}>

                                        </span>
                                    )}
                                </td>
                                <td>
                                    {isRowEdited ? (
                                        <input
                                            type='checkbox'
                                            checked={res.raciA}
                                            onChange={(e) =>
                                                handleInputChange(index, 'raciA', e.target.checked)
                                            }
                                            className={getCheckboxColorClass(res.raciA)}
                                        />
                                    ) : res.raciA ? (
                                        <span className={getCheckboxColorClass(res.raciA)}>
                                            &#10004;
                                        </span>
                                    ) : (
                                        <span className={getCheckboxColorClass(res.raciA)}>

                                        </span>
                                    )}
                                </td>
                                <td>
                                    {isRowEdited ? (
                                        <input
                                            type='checkbox'
                                            checked={res.raciC}
                                            onChange={(e) =>
                                                handleInputChange(index, 'raciC', e.target.checked)
                                            }
                                            className={getCheckboxColorClass(res.raciC)}
                                        />
                                    ) : res.raciC ? (
                                        <span className={getCheckboxColorClass(res.raciC)}>
                                            &#10004;
                                        </span>
                                    ) : (
                                        <span className={getCheckboxColorClass(res.raciC)}>

                                        </span>
                                    )}
                                </td>
                                <td>
                                    {isRowEdited ? (
                                        <input
                                            type='checkbox'
                                            checked={res.raciI}
                                            onChange={(e) =>
                                                handleInputChange(index, 'raciI', e.target.checked)
                                            }
                                            className={getCheckboxColorClass(res.raciI)}
                                        />
                                    ) : res.raciI ? (
                                        <span className={getCheckboxColorClass(res.raciI)}>
                                            &#10004;
                                        </span>
                                    ) : (
                                        <span className={getCheckboxColorClass(res.raciI)}>

                                        </span>
                                    )}
                                </td>
                                {
                                    isEditable && <td className='d-flex justify-content-center'>
                                        {!isRowEdited && (
                                            <div onClick={() => handleEdit(index)}>
                                                <View text="Edit" />
                                            </div>
                                        )}
                                        {isRowEdited && (
                                            <>
                                                <div onClick={() => handleSave(index, res?.taskRaciId)}>
                                                    <View text="Save" />
                                                </div>
                                            </>
                                        )}
                                    </td>
                                }

                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default EditableTable;
