// DragDrop.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

function DragDrop(props) {
  const { data, onDragEnd, Component, ...rest } = props;
  const [updatedData, setUpdatedData] = useState(data);
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside of list, no action needed
    }

    const updatedMilestones = [...data];
    const source = result.source;
    const destination = result.destination;

    // Find the source milestone and sub-milestone

    const sourceMilestone = updatedMilestones.find((milestone) => `${milestone.mileStoneId}` === source.droppableId);

    const movedSubMilestone = sourceMilestone.subMilestones[source.index];


    if (`${sourceMilestone.mileStoneId}` === destination.droppableId) {
      // Reorder within the same milestone
      sourceMilestone.subMilestones.splice(source.index, 1);
      sourceMilestone.subMilestones.splice(destination.index, 0, movedSubMilestone);
      // Update the order property for all sub-milestones in the source milestone
      sourceMilestone.subMilestones.forEach((subMilestone, index) => {
        subMilestone.subMilestoneNo = index + 1;
      });
    } else {

      // Different milestones, prevent the move
      return;
    }


    // onDragEnd(updatedMilestones);
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Component data={data} {...rest} />
    </DragDropContext>
  );
}

DragDrop.propTypes = {
  data: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

export default DragDrop;
