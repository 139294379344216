import React, { useEffect, useState } from 'react';
import Button from "../../Buttons/Navigate";
import { GETREVIEWAPi, CLONEDSERVICEAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import FormikControl from "../../Controls/FormControl"
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

function ClonedService({ resultData, closeModal, Reload }) {
    const [loading, setLoading] = useState(false);
    const allData = {
        serviceName: "",
    };
    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            serviceName: Yup.string().required('ServiceName is required'),

        }),
    });
    const handle = async (values) => {
        
        const data = {
            ...values.formData,
            "serviceId": resultData

        };
        
        setLoading(true);
        try {
            const result = await CLONEDSERVICEAPi(data);
            if (result?.res?.status == true) {
                closeModal();
                setLoading(false);
                toast.success(result?.res?.message)
                Reload && Reload();

            }

        } catch (error) {
            closeModal();
            toast.error(error.message);
            setLoading(false);
        }
    }
    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: allData,
                    }}
                    onSubmit={handle}
                validationSchema={schema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-5'>
                                <FormikControl
                                    control='heading'
                                    title='Clone Service'
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='d-flex  align-items-center justify-content-between'>
                                    <div className='col-lg-12 mb-5'>
                                        <FormikControl
                                            control='Input'
                                            placeholder='Service Name'
                                            name='formData.serviceName'
                                            value={formik.values.formData?.serviceName}
                                        />
                                    </div>

                                </div>
                            </div>

                            <Button type='submit' text='Add' disabled={formik.isSubmitting} />
                            <Loader loading={loading} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ClonedService