import React from 'react'

function FallBack() {
    return (
        <div>
            {
                 <div className='loadingSpinnerOverlay'>
                    <div className='loadingSpinnerContainer'>
                        <div className='loadingSpinner'></div>
                    </div>
                </div>
            }

        </div>
    )
}

export default FallBack


