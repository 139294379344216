import React from 'react'
import { useState, useEffect } from 'react'
import Loader from '../../Loader'
import { Formik, Form } from 'formik';
import Button from "../../Buttons/Navigate"
import SubTitle from '../../Heading/SubTitle';
import FormikControl from "../.././Controls/FormControl";
import * as Yup from 'yup';
import { TEARMSCREATEAPi, TEARMSGETAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';


function EditConditions({ closeModal, callFuntion, id }) {

    const [Loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({})

    const handle = async (values) => {

        setLoading(true)
        const data = { ...values?.formData, "termsId": id }
        try {
            const result = await TEARMSCREATEAPi(data);
            if (result?.res?.status == true) {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
                callFuntion()
            }
            else {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
                callFuntion()
            }

        }
        catch (err) {

            setLoading(false)
            toast.error(err || err.message)
            closeModal()
        }
    };
    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            titleName: Yup.string().required("TitleName is required"),
            termsDescription: Yup.string().required("TermsDescription is required")

        }),
    });
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TEARMSGETAPi(id);
            if (result && result.res.status === true) {

                setAllData(result?.res?.data)
                setLoading(false);
            }
            // Handle the fetched data as needed
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };
    useEffect(() => {

        fetchData();

    }, [id]);

    return (
        <div className='row'>
            <div className='com_modal'>
                {
                    allData?.titleName && allData?.termsDescription && <Formik
                        initialValues={{
                            formData: {
                                titleName: allData?.titleName,
                                termsDescription: allData?.termsDescription,


                            },
                        }}
                        onSubmit={handle}
                        validationSchema={schema}
                    >
                        {formik => {

                            return (

                                <Form className="form_default ">

                                    <div className='row'>
                                        <Loader Loading={Loading} />
                                        <div className="add_company">
                                            <div className='col-lg-3 col-md-3'>
                                                <div className='text_left my-3'>
                                                    <div>
                                                        <SubTitle title="T&C  Name" />
                                                    </div>
                                                    <div>
                                                        <div className='col-lg-12 col-md-12 my-3'>
                                                            <FormikControl
                                                                control='Input'
                                                                placeholder='Enter Name'
                                                                name="formData.titleName"
                                                            />

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-7 col-md-7'>
                                                <div className='text_left my-3'>
                                                    <div>
                                                        <SubTitle title="T&C Content" />
                                                    </div>
                                                    <div>
                                                        <div className='col-lg-12 col-md-12  my-3' >
                                                            <FormikControl
                                                                control='CustomTextArea'
                                                                name="formData.termsDescription"
                                                                value={formik?.values?.formData?.termsDescription}
                                                                customStyle={{ height: '70vh' }}


                                                            />

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <Loader loading={Loading} />

                                    </div>

                                    <Button type='submit' text='Save' disabled={formik.isSubmitting} />

                                </Form>
                            )
                        }}
                    </Formik>
                }

            </div>
        </div>
    )
}

export default EditConditions