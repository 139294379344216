import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../Components/Common/Heading/Title';
import FormikControl from "../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Edit from "../../Assets/images/Icons/edit.svg";
import DELETE from "../../Assets/images/Icons/delete.png";
import Loader from "../../Components/Common/Loader"
import Company from "../../Assets/images/Icons/company.svg";
import view from "../../Assets/images/Icons/View_profile.svg";
import DefaultTable from "../../Components/Common/Tables/DefaultTable"
import { ModalContext } from '../../Utils/Context'
import { Link, useParams } from 'react-router-dom';
import { GETSCANAPi, GETSCANSAPi } from '../../Utils/services';
import ADD from '../../Components/Common/Buttons/ADD';
import UpdateCompany from '../../Components/Common/Modals/UpdateCompany';
import AddCompany from '../../Components/Common/Modals/AddCompany';
import DeleteCompany from '../../Components/Common/Modals/DeleteCompany';
import { toast } from 'react-toastify';
import Swicth from "../../Components/Common/Buttons/Switch"
import UndoCompany from "../../Components/Common/Modals/UndoCompany"

function ScanInfo() {
    const [loading, setLoading] = useState(false);
    const [Customer, setCustomer] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const { id } = useParams()
    const fetchData = async () => {

        try {
            setLoading(true);
            const result = await GETSCANSAPi(id);
            if (result.res.status === true) {
                setCustomer(result.res.data)
                setLoading(false);
            }

        } catch (err) {
            setLoading(false);
            toast.error(err);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);



    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "ScanType",
                accessor: (Customer) => Customer?.scanType || "" // Use conditional rendering
            },
            {
                Header: "Target", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.target || "" // Use conditional rendering
            },


        ],
        []
    );


    return (
        <>
            <div className="container-fluid pb-5">
                <div className='row'>
                    <div className='mb-2'>
                        <Title title="Scan By User" />
                    </div>

                </div>
                <div className='row
                mt-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-6 col-md-6 employee_bar'>
                            <Formik
                            >
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>

                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Scans'
                                                setSearchVal={setSearchVal}
                                                searchVal={searchVal}

                                            />


                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>





                    </div>

                </div>
                <div className='row'>
                    <div className="display_table">
                        <Loader loading={loading} />
                        {
                            Customer && <DefaultTable data={Customer} columns={FeedsColumns} searchVal={searchVal} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
}

export default ScanInfo