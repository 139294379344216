import React from 'react'

const Title = ({ title }) => {
    return (
        <div className='row'>
            <div className='title'>
                <h1>{title}</h1>
            </div>

        </div>

    )
}

export default Title