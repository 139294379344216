import React from 'react'
import { useState } from 'react';

function Switch({ disabled, index, handle, data, isCheck }) {
    const [isDayTheme, setIsDayTheme] = useState(isCheck ? isCheck : false);

    const toggleValue = () => {
        setIsDayTheme((prevState) => !prevState);
        handle(index, data)
    }
    return (
        <div>

            {isDayTheme ? (

                <button className="toggle-btn btn-night" onClick={toggleValue} disabled={disabled} >
                    <div className="sun-moon"></div>
                </button>
            ) : (
                <button className="toggle-btn btn-day" onClick={toggleValue} disabled={disabled}>
                    <div className="sun-moon"></div>
                </button>
            )}

        </div>
    )
}

export default Switch