import { createContext, useContext } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";

import module from "../Components/Layout/moduleList";

export function createAppAbility(isAdmin, perMission) {
  const { can, rules } = new AbilityBuilder();

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const perMissionArray = isJSONString(perMission)
    ? JSON.parse(perMission)
    : perMission;

  const moduleIdArray = perMissionArray?.map((item) => item.moduleId);

  if (module?.length > 0 && moduleIdArray?.length > 0) {
    module.forEach((route) => {
      if (moduleIdArray?.includes(route.module_id)) {
        const matchingPermission = perMissionArray.find(
          (permission) => permission.moduleId === route.module_id
        );
        if (matchingPermission) {
          if (matchingPermission.create) {
            can("create", route.module_id);
          }
          if (matchingPermission.view) {
            can("view", route.module_id);
          }
          if (matchingPermission.edit) {
            can("edit", route.module_id);
          }
          if (matchingPermission.delete) {
            can("delete", route.module_id);
          }
        }
      }
    });
  }



  return new Ability(rules);
}

export const AbilityContext = createContext();

export function useAbility() {
  return useContext(AbilityContext);
}
