import { useEffect } from "react";

export const useSearch = (query, data, setData, names) => {
    useEffect(() => {
        if (!Array.isArray(names) || names.length === 0) {
            // Handle the case where names is not a valid array
            setData(data); // Reset data to its original state
            return;
        }
        if (query.length > 0) {
            const filtered = data.filter((item) => {
                return names.some((name) =>
                    typeof item[name] === 'string' &&
                    item[name].toLowerCase()?.includes(query.toLowerCase())
                );  
            });
            if (JSON.stringify(filtered) !== JSON.stringify(data)) {
                setData(filtered);
            }
        }



        // Update the state only if the filtered data is different from the current data

    }, [query, data, setData, names]);
};
