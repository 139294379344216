import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../Components/Common/Heading/Title';
import FormikControl from "../../Components/Common/Controls/FormControl";
import { Formik, Form } from 'formik';
import Edit from "../../Assets/images/Icons/edit.svg";
import DELETE from "../../Assets/images/Icons/delete.png";
import Loader from "../../Components/Common/Loader"
import Company from "../../Assets/images/Icons/company.svg";
import view from "../../Assets/images/Icons/View_profile.svg";
import DefaultTable from "../../Components/Common/Tables/DefaultTable"
import { ModalContext } from '../../Utils/Context'
import { Link } from 'react-router-dom';
import { CustomerALLGet, CUSTOMERDELETEAPI, CUSTOMERUNDOAPi } from '../../Utils/services';
import ADD from '../../Components/Common/Buttons/ADD';
import UpdateCompany from '../../Components/Common/Modals/UpdateCompany';
import AddCompany from '../../Components/Common/Modals/AddCompany';
import DeleteCompany from '../../Components/Common/Modals/DeleteCompany';
import { toast } from 'react-toastify';
import Swicth from "../../Components/Common/Buttons/Switch"
import UndoCompany from "../../Components/Common/Modals/UndoCompany"

function Customers() {
    const [loading, setLoading] = useState(false);
    const [Customer, setCustomer] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await CustomerALLGet();
     
            if (result && result?.res) {
                setCustomer(result?.res.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    };
    useEffect(() => {

        fetchData();


    }, [])

    const updateStatus = (index, data) => {
        if (data && data?.isDeleted == true) {
            const EmployeeModal = <UndoCompany closeModal={closeModal} handle={handleDeleteEmployee} data={data} reload={fetchData} setCustomer=
                {setCustomer} />
            handleModalData(EmployeeModal, "lg");
        }
        else {
            handleDelete(data)
        }

    }

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Cus.ID",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Company Name",
                accessor: (Customer) => Customer?.companyName || "" // Use conditional rendering
            },
            {
                Header: "Service", // The header text for this column is "Service"
                accessor: (Customer) => {
                    if (Array.isArray(Customer.purchaseTaskList)) {
                        return Customer.purchaseTaskList.join(', '); // Join the array elements with a comma
                    } else {
                        return ""; // Empty string if the purchaseTaskList is not an array or is empty
                    }
                }
            },
            {
                Header: "Contact No.",
                accessor: (Customer) => Customer?.contactNumber || "" // Use conditional rendering
            },
            {
                Header: 'Actions',
                accessor: (Customer) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center cus_input_wid'>
                                <div>
                                    <Link to={`/companyprofile/${Customer?.companyDetailId}`}>
                                        <span>
                                            <img src={view} alt="View" />
                                        </span>
                                    </Link>
                                </div>
                                {!Customer?.isDeleted &&
                                    <div onClick={() => handle(Customer?.companyDetailId)} className='table_icon mx-2'>
                                        <img src={Edit} alt="Edit" />

                                    </div>
                                }


                                <div>
                                    <Swicth isCheck={Customer?.isDeleted} handle={updateStatus} data={Customer} />
                                </div>
                                {/* <div onClick={() => handleDelete(Customer)} className='table_icon mx-2'>
                                    <img src={DELETE} alt="Edit" />

                                </div> */}
                            </div>
                        </>
                    );
                },
            }
        ],
        []
    );
    const data = [{
        icons: Company,
        name: "Add Company",

    },



    ]
    const handle = (data) => {
        setLoading(true);
        if (data && typeof data === "number") {
            setLoading(false);
            const EmployeeModal = <UpdateCompany closeModal={closeModal} callFuntion={fetchData} id={data} />
            handleModalData(EmployeeModal, "xl");
        }
        else if (data = "company") {
            setLoading(false);
            const EmployeeModal = <AddCompany closeModal={closeModal} callFuntion={fetchData} />
            handleModalData(EmployeeModal, "xl");
        }


    }
    const handleDelete = (data) => {
        const EmployeeModal = <DeleteCompany closeModal={closeModal} handle={handleDeleteEmployee} data={data} reload={fetchData} setCustomer={setCustomer} />
        handleModalData(EmployeeModal, "lg");


    }
    const handleDeleteEmployee = async (data) => {
        if (data && data?.isDeleted == true) {
            try {
                setLoading(true);
                const result = await CUSTOMERUNDOAPi(data?.companyDetailId);
                if (result && result?.res) {
                    toast.success(result?.res?.message)
                    setLoading(false);
                    closeModal()
                    fetchData();
                }
            } catch (error) {
                toast.error(error || error?.message)
                setLoading(false);
                closeModal()
            }
        }
        else {

            try {
                setLoading(true);
                const result = await CUSTOMERDELETEAPI(data?.companyDetailId);
                if (result && result?.res) {
                    toast.success(result?.res?.message)
                    setLoading(false);
                    closeModal()
                    fetchData();
                }
            } catch (error) {
                setLoading(false);
                closeModal()
            }
        }



    }
    return (
        <>
            <div className="container-fluid pb-5">

                <div className='row
                mt-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-8 col-md-8 employee_bar'>
                            <Formik
                            >
                                {formik => {
                                    return (
                                        <Form onSubmit={e => { e.preventDefault(); }}>

                                            <FormikControl
                                                control='Search'
                                                placeholder='Search For Companies'
                                                setSearchVal={setSearchVal}
                                                searchVal={searchVal}

                                            />


                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-2 col-md-2'>
                            {
                                data && data?.map((val, i) => {

                                    return (
                                        <div onClick={() => handle("addCompany")}>
                                            <ADD text={val?.name} source={val.icons} />

                                        </div>


                                    );

                                })
                            }
                        </div>




                    </div>

                </div>
                <div className='row'>
                    <div className="display_table">
                        <Loader loading={loading} />
                        {
                            Customer && <DefaultTable data={Customer} columns={FeedsColumns} searchVal={searchVal} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
}

export default Customers