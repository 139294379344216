import React from 'react'
import { useState } from 'react'
import Loader from '../../Loader'
import { Formik, Form } from 'formik';
import Button from "../../Buttons/Navigate"
import SubTitle from '../../Heading/SubTitle';
import FormikControl from "../.././Controls/FormControl";
import * as Yup from 'yup';
import { CREATECUSTOMERAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';


function AddCompany({closeModal,callFuntion}) {
    
    const [Loading, setLoading] = useState(false)

    const handle = async (values) => {
        setLoading(true)
        const data = { "fromAdmin": true, ...values?.formData }
        try {
            const result = await CREATECUSTOMERAPi(data);

            if (result.res.status == true) {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
                callFuntion()
            }
            else {
                toast.success(result?.res.message)
                setLoading(false)
                closeModal()
            }

        }
        catch (err) {

            setLoading(false)
            toast.error(err || err.message)
            closeModal()
        }
    };
    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            userName: Yup.string().required("Name is required"),
            userEmail: Yup.string().email("Invalid email address").required("Email is required"),
            organisationName: Yup.string().required("Organisation Name is required"),
            userNumber: Yup.string()
                .required("Number is required")
                .min(10, "Number must be at least 10 characters"),
            companySize: Yup.number()
                .required("Company Size is required")
                .integer("Company Size must be an integer")
                .positive("Company Size must be a positive number")
                .min(1)
                .max(1000),
            industry: Yup.string().required("Industry is required"),
            location: Yup.string().required("Location is required"),
            typeofSensitiveData: Yup.string().required(
                "Type of Sensitive Data is required"
            ),
            securityInformation: Yup.object().shape({
                cyberSecurityChallenge: Yup.string().required(
                    "Cyber Security Challenge is required"
                ),
                securityMeasure: Yup.string().required(
                    "Security Measure Challenge is required"
                ),
                complianceRequirement: Yup.string().required(
                    "Compliance Requirement Challenge is required"
                ),
                pastExperiences: Yup.string().required(
                    "Past Experiences Challenge is required"
                ),
            }),
        }),
    });
    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: {
                            userName: "",
                            userEmail: "",
                            primaryRole: "Admin",
                            useremail: "",
                            organisationName: "",
                            companySize: "",
                            industry: "",
                            location: "",
                            typeofSensitiveData: "",
                            securityInformation: {
                                cyberSecurityChallenge:
                                    "",
                                securityMeasure:
                                    "",
                                complianceRequirement:
                                    "",
                                pastExperiences:
                                    "",
                            },
                        },
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => {

                        return (

                            <Form className="form_default ">

                                <div className='row'>
                                    <Loader Loading={Loading} />
                                    <div className="add_company">
                                        <div className='col-lg-5 col-md-5'>
                                            <div className='text_left my-3'>
                                                <div>
                                                    <SubTitle title="About Company Admin" />
                                                </div>
                                                <div>
                                                    <div className='col-lg-12 col-md-12 my-3'>
                                                        <FormikControl
                                                            control='Input'
                                                            placeholder='Enter Name'
                                                            name="formData.userName"
                                                        />

                                                    </div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <FormikControl
                                                            control='Input'
                                                            type="Email"
                                                            placeholder='Enter Email'
                                                            name="formData.userEmail"
                                                        />

                                                    </div>
                                                    <div className='col-lg-12 col-md-12  my-3'>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className='col-lg-5 col-md-5' >
                                                                <FormikControl
                                                                    control='Input'
                                                                    type="text"
                                                                    placeholder='Enter Role'
                                                                    name="formData.primaryRole"
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className='col-lg-5 col-md-5' >
                                                                <FormikControl
                                                                    control='Phone'
                                                                    placeholder='Enter Phone'
                                                                    name="formData.userNumber"
                                                                />

                                                            </div>


                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text_left my-5'>
                                                <div>
                                                    <SubTitle title="About Organisation" />
                                                </div>
                                                <div>
                                                    <div className='col-lg-12 col-md-12 my-3'>
                                                        <FormikControl
                                                            control='Input'
                                                            placeholder='Name of Organisation'
                                                            name="formData.organisationName"
                                                        />

                                                    </div>

                                                    <div className='col-lg-12 col-md-12  my-3'>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className='col-lg-5 col-md-5' >
                                                                <FormikControl
                                                                    control='Input'
                                                                    type="text"
                                                                    placeholder='Enter Industry'
                                                                    name="formData.industry"
                                                                />
                                                            </div>
                                                            <div className='col-lg-5 col-md-5' >
                                                                <FormikControl
                                                                    control='Input'
                                                                    type="Number"
                                                                    min="1"
                                                                    placeholder='Enter Company Size'
                                                                    name="formData.companySize"
                                                                />

                                                            </div>


                                                        </div>

                                                    </div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <FormikControl
                                                            control='Input'
                                                            type="text"
                                                            placeholder='Enter Location'
                                                            name="formData.location"
                                                        />

                                                    </div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <span className='com_ques_test'>
                                                            What type of sensitive data does your organisation
                                                            handle?
                                                        </span>
                                                        <FormikControl
                                                            control='Input'
                                                            placeholder="(e.g.,customer information,financial data, intellectual property)"
                                                            name="formData.typeofSensitiveData"
                                                        />

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div className='col-lg-5 col-md-5'>
                                            <div className='text_left my-3'>
                                                <div>
                                                    <SubTitle title="About Security" />
                                                </div>
                                                <div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <span className='com_ques_test'>
                                                            What are your primary cybersecurity concerns or
                                                            challenges?
                                                        </span>
                                                        <FormikControl
                                                            control='CustomTextArea'
                                                            name="formData.securityInformation.cyberSecurityChallenge"

                                                        />

                                                    </div>
                                                    <div className='col-lg-12 col-md-12  my-3' >
                                                        <div className='col-lg-12 col-md-12  my-3' >
                                                            <span className='com_ques_test'>
                                                                What are your current security measures?
                                                            </span>
                                                            <FormikControl
                                                                control='CustomTextArea'
                                                                name="formData.securityInformation.securityMeasure"
                                                            />

                                                        </div>
                                                        <div className='col-lg-12 col-md-12  my-3' >
                                                            <span className='com_ques_test'>
                                                                Past experience in cybersecurity incidents or
                                                                breaches?
                                                            </span>
                                                            <FormikControl
                                                                control='CustomTextArea'

                                                                name="formData.securityInformation.pastExperiences"
                                                            />

                                                        </div>  <div className='col-lg-12 col-md-12  my-3' >
                                                            <span className='com_ques_test'>
                                                                Do you need to be compliant with any specific
                                                                cybersecurity standards?
                                                            </span>
                                                            <FormikControl
                                                                control='CustomTextArea'
                                                                name="formData.securityInformation.complianceRequirement"
                                                            />

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <Loader loading={Loading} />

                                </div>

                                <Button type='submit' text='Save' disabled={formik.isSubmitting} />

                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default AddCompany