import React, { useState } from 'react';

const SanitizedHTMLDisplay = ({ html, maxLength }) => {
    const [showFullText, setShowFullText] = useState(false);

    // Check if html is defined before using substring
    const truncatedHtml = html ? html.substring(0, maxLength) : '';

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: showFullText ? html : truncatedHtml }}  style={{ display: 'inline-block', }}/>
            {html && html.length > maxLength && (
                <span
                    className='read_more'
                    onClick={() => setShowFullText(!showFullText)}
                >
                    {showFullText ? 'Read Less' : 'Read More'}
                </span>
            )}
            {!showFullText && html && html.length > maxLength && ''}
        </div>
    );
};

function ReadMore(props) {
    const truncLength = 200;
    const [isShow, setShowHide] = useState(false);
    return (
        <>
            {" "}
            <SanitizedHTMLDisplay html={props?.data} maxLength={250} />
        </>
    );
}

export default ReadMore;
