import React, { useState, useMemo, useEffect } from 'react';
import AddRaci from "../../../Components/Common/Modals/AddRaci"
import DefaultTable from "../../../Components/Common/Tables/DefaultTable";
import FormControl from '../../../Components/Common/Controls/FormControl';
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import Button from '../../../Components/Common/Buttons/Navigate';
import { ModalContext } from '../../../Utils/Context';
import { useContext } from 'react';
import { useTable } from 'react-table';
import EditableTable from '../../../Components/Common/Tables/EditableTable';
import { ServiceOneGet, ServiceUpdate } from '../../../Utils/services';
import { async } from 'q';
import { toast } from 'react-toastify';
import MultiStepFormContext from "../../../Utils/MultiStepContext";
import Loader from '../../../Components/Common/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../../Utils/auth-context';
import { useAbility } from '../../../Utils/AbilityContext';
function Raci({ prevStep }) {

    
    const modalContext = useContext(ModalContext);
    const { handleModalData, closeModal } = modalContext;
    const { details } = useContext(MultiStepFormContext);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const location = useLocation();
    const state = location.state;
    const isDirect = useParams();
    const { isAdmin, permission } = useContext(AuthContext);
    const ability = useAbility();
    const isCreate = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
    const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'edit');

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await ServiceOneGet(details?.step1?.serviceId ? details?.step1?.serviceId : isDirect?.id);
            if (result?.res?.status === true) {
                setData(result?.res?.data?.raciMappingList);

            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    useEffect(() => {

        fetchData();
    }, [details?.step1?.serviceId, isDirect?.id]);

    const handleModal = () => {
        handleModalData(
            <AddRaci
                closeModal={closeModal}
                Reload={fetchData}
                id={details ? details : isDirect?.id}
                getData={data}
            />,
            'xl'
        );
    }
    const handle = async (result, result2) => {
        if(result2 == undefined || result2 == null)
        {
            const data = new FormData();
            data.append("taskCreateRequest", JSON.stringify({ 'raciMappingList': result, "isDraft": result2?.isDraft }))
            setLoading(true);
    
            if (result?.length > 0) {
                try {
                    const result = await ServiceUpdate(details?.step1?.serviceId ? details?.step1?.serviceId : isDirect?.id, data);
                    if (result?.res.status == true) {
                        if (isDirect?.id !== null) {
                            setLoading(false);
                            toast.success(result?.res.message || "Service Updated successfully");
                            // details?.step1?.serviceId ? navigate(`/viewservice/${details?.step1?.serviceId}`) : navigate(`/viewservice/${isDirect?.id}`)
    
                        }
                        else {
                            setLoading(false);
                            toast.success(result?.res.message || "Service Updated successfully");
                            fetchData()
                            // navigate('/service')
                        }
    
                    }
                    else {
                        setLoading(false);
                        toast.error(result?.res.message)
                    }
                } catch (error) {
                    toast.error(error.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Minimum 1 value is Required')
            }
        }
        else{
            const data = new FormData();
            data.append("taskCreateRequest", JSON.stringify({ 'raciMappingList': result, "isDraft": result2?.isDraft }))
            setLoading(true);
    
            if (result?.length > 0) {
                try {
                    const result = await ServiceUpdate(details?.step1?.serviceId ? details?.step1?.serviceId : isDirect?.id, data);
                    if (result?.res.status == true) {
                        if (isDirect?.id !== null) {
                            setLoading(false);
                            toast.success(result?.res.message || "Service Updated successfully");
                            details?.step1?.serviceId ? navigate(`/viewservice/${details?.step1?.serviceId}`) : navigate(`/viewservice/${isDirect?.id}`)
    
                        }
                        else {
                            setLoading(false);
                            toast.success(result?.res.message || "Service Updated successfully");
                            fetchData()
                            navigate('/service')
                        }
    
                    }
                    else {
                        setLoading(false);
                        toast.error(result?.res.message)
                    }
                } catch (error) {
                    toast.error(error.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Minimum 1 value is Required')
            }
        }

        


    }

    const handleBack = () => {
        if (isDirect && isDirect?.id != null) {

            navigate(`/viewservice/${isDirect.id}`)
        }
        else {
            prevStep()
        }
    }
    const handlelast = (res) => {

        handle(data, res);

    }
    const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create')
    return (
        <div>
            <div className='container-fluid pb-5'>
                <div className='row mb-3'>
                    <FormControl control='heading' title='Services Details' />
                </div>
                <div className='row mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-10 col-md-8'>
                            <SubTitle title='Service/RACI Mapping' />
                        </div>

                        <div className='col-lg-2 col-md-3 raci_text_btn' onClick={matchingAbility ? () => handleModal() : null}>
                            <Button type='button' text='Select RACI Values' disabled={!matchingAbility} />

                        </div>
                    </div>
                </div>
                <Loader loading={loading} />
                <div className='row mb-5'>
                    <div className="display_table">
                        {
                            data && <EditableTable data={data} handle={handle} isEditable={true} isDelete={true} />
                        }

                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div onClick={() => handlelast({ "isDraft": true })}>
                        <Button type='button' text='Save As Draft' white={true} />
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div onClick={() => handleBack()}>
                            <Button type='button' text='Back' white={true} />
                        </div>
                        <div onClick={(isCreate || isEdit) ? () => handlelast({ "isDraft": false }) : null}>
                            <Button type='button' text='Save' disabled={!(isCreate || isEdit)} />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}


export default Raci;