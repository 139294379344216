import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { LuClock3 } from 'react-icons/lu';
import Navigate from '../../../Common/Buttons/Navigate';
import { useNavigate } from 'react-router-dom';

function OngoingMilstone({ data }) {
    const navigate = useNavigate();
    const handleClick = (val) => {

        navigate(`/manageprojects/${val}`)
    }
    return (
        <>
            <div className='ongoing_milestone'>
                <div className='d-flex justify-content-between align-items-center flex-column'>

                    <div className='ongoing_milestone_inner ser_name'>

                        <div>
                            <span>
                                {data?.serviceName
                                }
                            </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>

                            <span className='mx-2'><LuClock3 /></span>
                            <span>{data?.workingHours}</span>

                        </div>

                    </div>
                    <div className='ongoing_milestone_inner ser_name'>

                        <div className='col-lg-6'>
                            <span className='comapny_name'>
                                Company Name :
                            </span>
                            <span>
                                {data?.companyName}
                            </span>
                        </div>
                        <div className='col-lg-5'>

                            <span className='action_name'>Action :</span>


                            <span>{data?.projectSubmilestoneName}</span>

                        </div>

                    </div>
                    <div className='progess_bar'>
                        <ProgressBar
                            completed={60}
                            className="wrapper"
                            isLabelVisible={false}  
                            bgColor="#edd39f" // Change background color
                            baseBgColor="#FFF" // Change base background color
                            height="10px" // Change the height of the progress bar

                        />
                        <div className='progess_text'>
                            <span >
                                Proj.Start Date
                            </span>
                            <span>
                                Proj.End Date
                            </span>
                        </div>



                    </div>
                    <div className='' onClick={() => handleClick(data?.projectId)}>


                        <Navigate text="Manage Project" />
                    </div>
                </div>

                {/* <ProgressBar completed="60" isLabelVisible={false} /> */}

            </div>
        </>
    )
}

export default OngoingMilstone  