import React, { useState, useEffect, useMemo } from 'react';
import Title from '../../Components/Common/Heading/Title';
import SubTitle from '../../Components/Common/Heading//SubTitle'
import FormControl from '../../Components/Common/Controls/FormControl'
import { Formik, Form } from 'formik'
import yellow from "../../../src/Assets/images/Icons/yellow.svg"
import DefaultTable from '../../Components/Common/Tables/DefaultTable';
import profile from "../../../src/Assets/images/Icons//View_profile.svg";
import Cross from "../../../src/Assets/images/Icons/cross.png";
import View from '../../Components/Common/Buttons/View';
import Navigate from '../../Components/Common/Buttons/Navigate';
import UpdateMilestone from '../../Components/Common/Modals/UpdateMilestone';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../Utils/Context';
import { GetProjects, SEARCHPROJECTSOAPi } from '../../Utils/services';
import { toast } from 'react-toastify';
import Loader from '../../Components/Common/Loader';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { useContext } from 'react';
import Pagination from '../../Components/Common/Pagination';
import useDebounce from "../../Components/Common/Hooks/useDebounce"
import AddScan from '../../Components/Common/Modals/AddScan';
import AddStartDate from '../../Components/Common/Modals/AddStartDate';


function OngoingProjects() {
    const [searchVal, setSearchVal] = useState('');
    const [searchVal1, setSearchVal1] = useState(''); // Search for Project Pending Allocation
    const [searchVal2, setSearchVal2] = useState(''); // Search for Project In Progress
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [onGoing, SetonGoing] = useState([]);
    const [newGoing, SetnewGoing] = useState([]);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentComPage, setCurrentComPage] = useState(1);
    const [metaData, setmetaData] = useState({});
    const [metaComData, setCommetaData] = useState({});
    const [debouncedSearch1, setDebouncedSearch1] = useDebounce('', 1000);
    const [debouncedSearch2, setDebouncedSearch2] = useDebounce('', 1000);



    const fetchData = async (type) => {

        try {
            setLoading(true);
            const result = await GetProjects(type?.type, type?.page, type?.size)

            if (result.res.status === true) {
                setmetaData(result?.res?.metadata)
                SetnewGoing(result?.res?.data)
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(result?.res?.message);
            }
        } catch (err) {
            toast.error(err);
        }
    };
    const fetchComData = async (type) => {
        try {
            setLoading(true);
            const result = await GetProjects(type?.type, type?.page, type?.size)
            if (result.res.status === true) {
                setCommetaData(result?.res?.metadata)
                SetonGoing(result?.res?.data);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(result?.res?.message);
            }
        } catch (err) {
            toast.error(err);
        }
    };

    const searchData = async (type) => {


        try {
            setLoading(true);
            const result = await SEARCHPROJECTSOAPi(type?.type, type?.page, type?.size, type?.searchKeyword)
            if (result.res.status === true) {
                if (type?.type == 1) {
                    // setCommetaData(result?.res?.metadata)
                    SetnewGoing(result?.res?.data);
                }
                else {
                    // setCommetaData(result?.res?.metadata)
                    SetonGoing(result?.res?.data);
                }



                setLoading(false);
            } else {
                setLoading(false);
                toast.error(result?.res?.message);
            }
        } catch (err) {
            toast.error(err);
            setLoading(false)
        }
    };
    useEffect(() => {
        if (!searchVal1) {
            fetchData({ type: 1, page: currentPage, size: 10 });
        } else {
            setDebouncedSearch1(searchVal1);
        }
    }, [currentPage, searchVal1]);

    useEffect(() => {
        if (!debouncedSearch1 || debouncedSearch1.length < 0) return;

        setCurrentPage(1);
        searchData({ type: 1, page: 1, size: 300, searchKeyword: debouncedSearch1 });
    }, [debouncedSearch1]);

    useEffect(() => {
        if (!searchVal2) {
            fetchComData({ type: 2, page: currentComPage, size: 10 });
        } else {
            setDebouncedSearch2(searchVal2);
        }
    }, [currentComPage, searchVal2]);

    useEffect(() => {
        if (!debouncedSearch2 || debouncedSearch2.length < 0) return;

        setCurrentComPage(1);
        searchData({ type: 2, page: 1, size: 300, searchKeyword: debouncedSearch2 });
    }, [debouncedSearch2]);
    const handleStatus = (id) => {
        if (id) {

            const Modal = <UpdateMilestone id={id} />;
            handleModalData(Modal, "lg");
        }
    }

    const handleScan = (projectdata) => { 
        console.log(projectdata,"projectdata")
        const Modal = <AddStartDate closeModal={closeModal} reload={fetchData}  projectdata={projectdata}/>;
        handleModalData(Modal, "md");

    }


    const FeedsColumns_1 = useMemo(
        () => [
            {
                Header: "Emp.ID",
                accessor: (data, index) => {
                    return (
                        <>
                            <span className='text-sm'>{(currentPage - 1) * 10 + index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Company Name",
                accessor: "companyName"
            },
            {
                Header: "Service",
                accessor: "serviceName"

            },
            {
                Header: () => (
                    <div className="flex">
                        <div className="">Status</div>
                        <div className='sub_text'>(Completed Milestone)</div>
                    </div>
                ),
                accessor: "status", // Assign a unique string accessor
                Cell: (cell ) => ( // Use Cell for rendering the cell content
                    // console.log(cell.row.original,"row.original")
                    // <span onClick={handleScan} className='text-sm'>{data.row.original.completedStatus}/{data.row.original.totalStatus}</span>
                    <>
        {cell.row.original.startDate === null ? (
            <span onClick={() => handleScan(cell.row.original)} className='text-sm cursor-pointer'>
                
                <Navigate text="Add Start Date" />
            </span>
        ) :  <span onClick={() => handleScan(cell.row.original)} className='text-sm cursor-pointer'>
                
        <Navigate text="Started" disabled={true} />
    </span>}
    </>
                   // 
                )
            },
            ,
            {
                Header: "Access",
                accessor: data => {
                    return (
                        <div className='acess_icon'>
                            <p>
                                Not Allocated
                            </p>
                            {/* <ul>
                                <li><img src={red} alt="" /></li>
                                <li><img src={yellow} alt="" /></li>
                                <li><img src={blue} alt="" /></li>
                            </ul> */}
                        </div>
                    );
                }
            },
            {
                Header: 'Actions',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex justify-content-evenly align-items-center f'>
                                <Link to={`/companyprofile/${data?.companyId}`}>
                                    <View text="View Profile" source={profile} />
                                </Link>
                                {
                                    data?.startDate !== null ?
                                    <Link to={`/manageprojects/${data?.projectId}`}>
                                    <Navigate text="Manage Project" />
                                </Link> : <Link to={`/manageprojects/${data?.projectId}`}>
                                    <Navigate text="Manage Project"  disabled={true}/>
                                </Link>
                                }
                                



                            </div>
                        </>
                    );
                },
            }

        ], []);
    const FeedsColumns_2 = useMemo(
        () => [
            {
                Header: "Emp.ID",
                accessor: (data, index) => {
                    return (
                        <>
                            <span className='text-sm'>{(currentComPage - 1) * 10 + index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Company Name",
                accessor: "companyName"
            },
            {
                Header: "Service",
                accessor: "serviceName"

            },
            {
                Header: () => (
                    <div className="flex">
                        <div className="">Status</div>
                        <div className='sub_text'>(Completed Milestone)</div>
                    </div>
                ),
                accessor: "status", // Assign a unique string accessor
                Cell: (data) => (
                    <>
                        <>
                            {!data.row.original.isHovered && (
                                <span
                                    className='text-sm'

                                >
                                    {data.row.original.completedStatus}/{data.row.original.totalStatus}
                                </span>
                            )}
                            {data.row.original.isHovered && (
                                <span className='text-sm' onClick={() => handleStatus(data.row.original.projectId)}>Update Status</span>
                            )}
                        </>
                    </>
                )
            },
            ,
            {
                Header: "Access",
                accessor: data => {
                    return (
                        <div className='acess_icon icons_wrap'>
                            {data?.employeeImageUrls
                                && <ul>
                                    {
                                        data?.employeeImageUrls && data?.employeeImageUrls?.slice(0, 3)?.map((res) => {


                                            return (
                                                res ? (<li><img src={res} alt="p" /></li>) : (<li><img src={yellow} alt="" /></li>)
                                            )
                                        })
                                    }

                                </ul>}

                        </div>
                    );
                }
            },
            {
                Header: 'Actions',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex justify-content-evenly align-items-center f'>
                                <Link to={`/companyprofile/${data?.companyId}`}>
                                    <View text="View Profile" source={profile} />
                                </Link>
                                <Link to={`/manageprojects/${data?.projectId}`}>
                                    <Navigate text="Manage Project" />
                                </Link>



                            </div>
                        </>
                    );
                },
            }

        ], [isShow, newGoing,fetchComData]);





    return (
        <div>   <div className="container-fluid pb-5">
            <div className='row'>
                <div className='mb-2'>
                    <Title title="Ongoing Projects" />
                </div>

                <div className='searchbox'>
                    <Loader loading={loading} />
                    <div className='col-lg-4 col-md-12 col-12'>
                        <Formik
                        >
                            {formik => {
                                return (
                                    <Form onSubmit={e => { e.preventDefault(); }}>

                                        <FormControl
                                            control='Search'
                                            placeholder='Search For Projects'
                                            setSearchVal={setSearchVal1}
                                            searchVal={searchVal1}

                                        />


                                    </Form>
                                )
                            }}
                        </Formik>

                    </div>


                </div>
                <div className=''>
                    {
                        !searchVal && <SubTitle title="Project Pending Allocation" />
                    }

                </div>
                {
                    newGoing?.length > 0 && <div className=" project_table  mb-5 my-3">
                        <div>
                            <DefaultTable data={newGoing} columns={FeedsColumns_1} />
                        </div>
                        <div className='pagnation_wrap my-2'>
                            <Pagination
                                totalRows={metaData.totalElements}
                                pageChangeHandler={setCurrentPage}
                                rowsPerPage={metaData?.pageSize}
                            />
                        </div>





                    </div>
                }



            </div>
            <div className='searchbox'>
                <Loader loading={loading} />
                <div className='col-lg-4 col-md-12 col-12'>
                    <Formik
                    >
                        {formik => {
                            return (
                                <Form onSubmit={e => { e.preventDefault(); }}>

                                    <FormControl
                                        control='Search'
                                        placeholder='Search For Projects'
                                        setSearchVal={setSearchVal2}
                                        searchVal={searchVal2}


                                    />


                                </Form>
                            )
                        }}
                    </Formik>

                </div>


            </div>
            {
                onGoing?.length > 0 && <div className='row'>
                    <div className=''>
                        {
                            !searchVal && <SubTitle title="Project In Progress" />
                        }

                    </div>
                    <div className=" project_table  my-3">
                        {
                            onGoing && <DefaultTable data={onGoing} columns={FeedsColumns_2} />
                        }

                    </div>

                    <div className='pagnation_wrap my-2'>
                        <Pagination
                            totalRows={metaComData.totalElements}
                            pageChangeHandler={setCurrentComPage}
                            rowsPerPage={metaComData?.pageSize}
                        />
                    </div>
                </div>
            }



        </div></div>
    )
}

export default OngoingProjects