import React, { useState, Children, cloneElement, useEffect } from 'react';
import SubTitle from '../Heading/SubTitle';

export function CustomTabs({ children, onChange, activeTab, setActiveTab }) {

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    onChange && onChange(activeTab);
  }, [activeTab, onChange]);

  return (
    <div className=''>
      <div className="d-flex align-items-center">
        {Children.map(children, (child, index) => (
          <div
            className={`custom-tab ${index === activeTab ? 'active-settings cursor-pointer me-4' : 'cursor-pointer me-4'}`}
            onClick={() => handleTabClick(index)}
          >
            <SubTitle title={child.props.tabLabel} />
          </div>
        ))}
      </div>
      <div className="custom-tabs-content">
        {Children.map(children, (child, index) => (

          cloneElement(child, { active: index === activeTab })
        ))}
      </div>
    </div>
  );
}

export function CustomTabPanel({ active, children }) {
  return active ? children : null;
}
