import React, { useState } from 'react';
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import ActionProvider from "../../../../chat-bot-utills/ActionProvider"; // Correct the path to your ActionProvider
import MessageParser from "../../../../chat-bot-utills/MessageParser"; // Correct the path to your MessageParser
import config from "../../../../chat-bot-utills/config"; // Correct the path to your chatbot configuration
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GETCHATAPI } from '../../../../Utils/services';
import Followups from '../../../../chat-bot-utills/Followups';
import { createChatBotMessage } from 'react-chatbot-kit';
import Loader from '../../Loader';
import { GETCONVERSATION } from '../../../../Utils/services';
import MyCustomChatMessage from './MyCustomChatMessage';
import { toast } from 'react-toastify';
import { RxCrossCircled } from "react-icons/rx";


function Chat({ id, closeModal, ProjectId, setChatOpen }) {
  const [loading, setLoading] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const [config, setConfig] = useState(null)




  const handle = () => {
    setChatOpen(false);
  }

  const fetchData = async () => {
    if (id && ProjectId) {
      try {

        setLoading(true)
        const result = await GETCHATAPI(id);
        const result2 = await GETCONVERSATION(ProjectId)
        const conversation = JSON.parse(result2?.res?.chatConversation);
        if (result?.res?.nodes != null) {
          setisVisible(true)
        }
        else {
          toast.error("No Chat available")
          setLoading(false)
          closeModal()
        }
        let initalmsg = [
          createChatBotMessage(result?.res?.chatBotJson?.['hello'].data.ansText, {
            widget: "followups",
            payload: "hello"
          }),

        ];
        if (conversation) {
          initalmsg = conversation;
          setLoading(false)
        }
        if (result.res.chatBotJson) {
          setConfig({
            initialMessages: initalmsg,
            // customStyles:{
            //   botMessageBox: {
            //     backgroundColor: "#c0902c",
            //     color:'white'
            //   },
            // },
            customComponents: {
              // Replaces the default header
              header: () => <div className='react-chatbot-kit-chat-header'><div className='d-flex justify-content-between align-items-center w-100'>
                <div>
                  <p>
                    Conversation with Bot

                  </p></div><div className='cursor-pointer  caht_icon_btn' onClick={handle}><RxCrossCircled />

                </div></div></div>,
              botChatMessage: (props) => <MyCustomChatMessage {...props} />,
            },
            widgets: [
              {
                widgetName: "followups",
                widgetFunc: (props) => <Followups {...props} />,
                props: {
                  questions: result?.res?.chatBotJson,
                  mid: id,
                  ProjectId: ProjectId,
                  setChatOpen,
                }
              },
            ],
          })
          setLoading(false)
          if (config == null && isVisible) {
            toast.warning("No converstation present at this momemnt ");
            closeModal()
            setLoading(false)
          }
          setLoading(false)

        }
      } catch (error) {
        closeModal()
        setLoading(false)
        toast.error(error)
        console.error('Error fetching data:', error);
        // Handle the error if needed
      }
    }

  };


  useEffect(() => {


    fetchData();



  }, [id]);



  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Loader loading={loading} />

      {config && <Chatbot
        config={config}
        actionProvider={ActionProvider}
        messageParser={MessageParser}
      />}
    </div>

  );
}

export default Chat;
