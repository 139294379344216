import React from 'react'
import { Link } from "react-router-dom";
import Progressbar from "../../Other/Progressbar";
import Mainbtn from "../../Buttons/Navigate";

function CompanyService({ data }) {
    return (


        <div key={data.id} className='company_projectile'>

            <div className='mb-2'>
                <span className='company_projectile_heading'>
                    {data.serviceName}
                </span>
            </div>
            <div className='mb-2'>
                <span className='company_projectile_subheading'>{data?.clientName ? data?.clientName : data.serviceName}</span>
            </div>
            <div className='mb-2 company_projectile_bar'>
                <Progressbar maxCompleted={data?.totalStatus} completed={data?.completedStatus} />
            </div>

            <div className='d-flex justify-content-center'>
                <Link to={`/manageprojects/${data?.projectId}${data?.projectType === 3 ? '/completed' : ''}`}>
                    <Mainbtn text="Manage Projects" />
                </Link>
            </div>
        </div>

    )
}

export default CompanyService