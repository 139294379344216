import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ErrorMessage, useField } from 'formik';
import dropzone from "../../../../Assets/images/Icons/DropZone.png";
import TextError from '../Errros';
import { toast } from 'react-toastify';

function DropZone(props) {
  const { name, setField, allowedExtensions, maxFileSize } = props;
  const [, , helpers] = useField(name);
  const [preview, setPreview] = useState([]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: allowedExtensions.map(ext => `.${ext}`).join(','),
    maxSize: maxFileSize * 1024 * 1024, // Convert maxFileSize to bytes

    onDrop: (acceptedFiles) => {

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]; // Assuming only one file is dropped
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        const fileSize = file.size;

        if (allowedExtensions.includes(fileExtension)) {
          if (fileSize <= maxFileSize * 1024 * 1024) {
            helpers.setValue(acceptedFiles);
            setField(acceptedFiles);
            setPreview(
              acceptedFiles.map((upFile) =>
                Object.assign(upFile, {
                  preview: URL.createObjectURL(upFile),
                })
              )
            );
          } else {
            toast.error(`File size is too large. Max size allowed is ${maxFileSize}MB. Actual size: ${(fileSize / (1024 * 1024)).toFixed(2)}MB`);
          }
        } else {
          toast.error(`Invalid file type. Please select a valid file with extensions: ${allowedExtensions.join(', ')}.`);
        }
      }
      else {
        toast.error(`Invalid file`);
      }
    },
  });

  return (
    <div>
      <div className='row'>
        <div>
          <div {...getRootProps()} className="inputDrops">
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>
                <p>Drag and drop file here or Choose file</p>
                <p>Allowed File : {allowedExtensions.join(', ')}</p>
              </div>

            ) : (
              <>
                <img src={dropzone} className="upload_icon" alt="Upload Icon" />
              </>
            )}
            <div>
              <p>Drag and drop file here or Choose file</p>
              <p>Allowed File : {allowedExtensions.join(', ')}</p>
            </div>
            {isDragReject && <p>File type not accepted, sorry!</p>}
            <div className="imagePreview">
              {Array.isArray(preview) &&
                preview.map((upFile, index) => {

                  return (
                    <div className="previewImage" key={index}>
                      {(upFile.type === "image/png" || upFile.type === "image/jpg" || upFile.type === "image/jpeg") ? (
                        <img src={upFile.preview} alt="preview" />
                      ) : (
                        <p>{upFile?.name}</p>
                      )}
                    </div>
                  );
                })}


            </div>
          </div>
          <ErrorMessage component={TextError} name={name} />
        </div>
      </div>
    </div>
  );
}

export default DropZone;
