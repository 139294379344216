import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import Loader from "../../../Components/Common/Loader"
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import FormikControl from "../../../Components/Common/Controls/FormControl"
import Button from "../../../Components/Common/Buttons/Navigate"
import * as Yup from 'yup'
import FileSaver, { saveAs } from 'file-saver';
import FileNotification from '../../../Components/Common/Notifications/FileNotification';
import DeleteModal from "../../../Components/Common/Modals/DeleteLink";
import { ServiceAdd, ServiceOneGet, ServiceUpdate, SERVICEDeleteAPi, DocsDelete } from '../../../Utils/services';
import UnderLine from '../../../Components/Common/Buttons/UnderLine';
import DeleteLink from "../../../Components/Common/Modals/DeleteLink";
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '../../../Utils/Context';
import { useAbility } from '../../../Utils/AbilityContext';
import AuthContext from '../../../Utils/auth-context';

function EditService(props) {
  const { details, setDetails, nextStep } = props
  const navigate = useNavigate();
  const [FieldValue, setFieldValue] = useState([]);
  const [editData, seteditData] = useState({});
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isAdmin, permission } = useContext(AuthContext);
  const ability = useAbility();
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;

  const fetchData = async () => {
    if (details?.data) {
      try {

        setLoading(true);

        const result = await ServiceOneGet(details?.data);
        if (result?.res?.status === true) {
          seteditData(result?.res?.data)
          setLoading(false);

        }
      } catch (error) {
        // Fallback to test data
        setLoading(false);

      }

    }
    else {
      try {

        setLoading(true);

        const result = await ServiceOneGet(details?.step1.serviceId);
        if (result?.res?.status === true) {
          seteditData(result?.res?.data)
          setLoading(false);

        }
      } catch (error) {
        // Fallback to test data
        setLoading(false);

      }

    }

  };
  useEffect(() => {

    fetchData();
  }, [details]);

  const categories = [
    {
      id: 4,
      label: 'Essential Service',
      value: 'Essential Service'
    },
    {
      id: 1,
      label: 'Operational Service',
      value: 'Operational Service'
    },
    {
      id: 2,
      label: 'Tactical Service',
      value: 'Tactical Service'
    },
    {
      id: 3,
      label: 'Strategic Service',
      value: 'Strategic Service'
    }
  ];
  const MonthsCategorry = [
    { "id": 1, "label": "1 Months", "value": "1" },
    { "id": 2, "label": "2 Months", "value": "2" },
    { "id": 3, "label": "3 Months", "value": "3" },
    { "id": 4, "label": "4 Months", "value": "4" },
    { "id": 5, "label": "5 Months", "value": "5" },
    { "id": 6, "label": "6 Months", "value": "6" },
    { "id": 7, "label": "7 Months", "value": "7 " },
    { "id": 8, "label": "8 Months", "value": "8" },
    { "id": 9, "label": "9 Months", "value": "9" },
    { "id": 10, "label": "10 Months", "value": "10" },
    { "id": 11, "label": "11 Months", "value": "11" },
    { "id": 12, "label": "12 Months", "value": "12" },

    // ... up to 12
  ];
  const paymentCategorry = [
    { "id": 1, "label": "One Time Payment", "value": "1" },
    { "id": 2, "label": "Monthly Payment", "value": "2" },
  ];

  const handleDelete = async () => {
    if (details?.data) {
      try {
        const result = await SERVICEDeleteAPi(details?.data)
        if (result.res.status === true) {
          toast.success(result.res.message);
          closeModal()
          navigate('/service')
        }
        else {
          toast.error(result.res.message)
        }
      }
      catch (err) {
        toast.error(err)
      }
    }
    else {
      try {
        const result = await SERVICEDeleteAPi(editData?.serviceId)
        if (result.res.status === true) {
          toast.success(result.res.message);
          closeModal()
          navigate('/service')
        }
        else {
          toast.error(result.res.message)
        }
      }
      catch (err) {
        toast.error(err)
      }

    }

  }



  const handleDownload = (name, link) => {

    FileSaver.saveAs(link, name);
  }

  const hadleDeletePdf = async (values) => {
    if (values && isDelete) {
      const result = await DocsDelete(values?.documentId);
      if (result.res.status === true) {
        toast.success(result.res.message);
        fetchData();
        closeModal();
      }



    }

  }
  const handleDeleteModal = () => {
    const DocDelete = <DeleteLink closeModal={closeModal} handle={handleDelete} data={editData} />
    handleModalData(DocDelete, "md");
  }
  const handle = async (values) => {

    if (editData?.serviceId) {
      const { image, documents, ...formDataWithoutImageAndDocuments } = values;

      const stringValue = formDataWithoutImageAndDocuments.durationInWeeks;
      formDataWithoutImageAndDocuments.durationInWeeks = parseInt(stringValue, 10);

      var arraay = { ...formDataWithoutImageAndDocuments, 'categoryId': formDataWithoutImageAndDocuments.categoryId, 'price': parseInt(values.price), 'oneTimePayment': values?.oneTimePayment == 1 ? true : false }
      const data = new FormData();
      if (values?.image && values.image.length > 0) {
        data.append("serviceImage", values.image[0])
      }

      if (values?.documents && values.documents.length > 0) {
        data.append("documents", values.documents[0]);
      }

      data.append("taskCreateRequest", JSON.stringify(arraay));
      setLoading(true);
      try {
        const result = await ServiceUpdate(editData?.serviceId, data);
        if (result?.res.status == true) {
          setDetails({ 'step1': result?.res.data })
          setLoading(false);
          toast.success(result?.res.message || "Service Updated successfully")
          nextStep();
        }
        else {
          setLoading(false);
          toast.error(result?.res.message)
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    }




  }

  const schema = Yup.object().shape({
    oneTimePayment: Yup.number()
      .required('Payment mode is required')
      .oneOf([1, 2], 'Invalid payment mode'),

    months: Yup.number()
      .test({
        name: 'months',
        message: 'Months is required when Monthly payment is selected',
        test: function (value) {
          const oneTimePaymentValue = this.resolve(Yup.ref('oneTimePayment'));
          // Check if oneTimePayment is 2 and months is provided
          if (oneTimePaymentValue === 2) {
            return value !== undefined && value !== null;
          }
          // For other cases, validation passes
          return true;
        },
      }),
    serviceName: Yup.string().required('Service Name is required'),
    categoryId: Yup.string().required('Category is required'),
    shortDescription: Yup.string().required('Short Description is required'),
    definePurpose: Yup.string().required('Define Purpose is required'),
    defineScope: Yup.string().required('Define Scope is required'),
    goal: Yup.string().required('Goal is required'),
    deliverables: Yup.string().required('Deliverables is required'),
    objective: Yup.string().required('Objective is required'),
    price: Yup.number()
      .required('Price is required')
      .integer('Price must be an integer')
      .positive('Price must be a positive number')
      .min(1, 'Price must be at least 1')
      .max(999999, 'Price must be at most 999,999'),
    durationInWeeks: Yup.number()
      .required('Duration is required')
      .integer('Duration must be an integer')
      .positive('Duration must be a positive number')
      .min(1, 'Duration must be at least 1')
      .max(52, 'Duration must be at most 52'),


  });
  const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'edit');
  const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'delete');

  return (
    <div>
      {
        editData && editData.serviceName ? (<div className='container-fluid pb-5'>
          <div className='row'>
            <FormikControl
              control='heading'
              // title='Services Details'

            />

          </div>

          <div className='row'>
            <Formik
              initialValues={{
                serviceName: editData?.serviceName,
                categoryId: editData?.categoryId, // Assuming "Operational Service"
                shortDescription: editData?.shortDescription,
                definePurpose: editData?.definePurpose,
                defineScope: editData?.defineScope,
                goal: editData?.goal,
                deliverables: editData?.deliverables,
                objective: editData?.objective, // Assuming "Operational Service"
                durationInWeeks: editData?.durationInWeeks,
                price: editData?.price,
                oneTimePayment: editData?.oneTimePayment == true ? 1 : 2,
                months: editData?.months
              }}
              onSubmit={handle}
              validationSchema={schema}
            >
              {formik => {

                return (

                  <Form className="form_default ">

                    <div className='row'>
                      <div className='d-flex justify-content-between align-items-center my-4 service_wrappeer'>
                        <div className='col-lg-9 col-md-9' >
                          <div className='row'>
                            <div className='d-flex justify-content-between align-items-center row'>
                              <div className='col-lg-4 col-md-6 service_input'>
                                <FormikControl
                                  control='Input'
                                  placeholder='Service Name'
                                  name='serviceName'
                                  value={formik.values.serviceName}
                                />
                              </div>

                              <div className='col-lg-4 col-md-6 service_input'>
                                <FormikControl
                                  control='SelectBox'
                                  name='categoryId' // Use the correct field name
                                  placeholder='Choose a Service'
                                  options={categories}
                                  prevalue={formik.values.categoryId}
                                />

                              </div>
                            </div>

                          </div>
                          <div className='row'>
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                              <div className="col-md-8  col-md-8">
                                <FormikControl
                                  control='CustomTextArea'
                                  placeholder='Service Description'
                                  name='shortDescription'
                                  value={formik.values.shortDescription}
                                />
                              </div>

                            </div>

                          </div>
                          <div className='row mt-5 text_left'>

                            <SubTitle title="Recurrence Payment" />


                            <div className="col-lg-12 col-md-12">
                              <div className="price_wrap">
                                <div className='col-lg-3 col-md-3 price_field'>
                                  <FormikControl
                                    control='Input'
                                    placeholder='Price in INR'
                                    name='price'
                                    value={formik.values.price}
                                  />

                                </div>
                                <div className='col-lg-3 col-md-3 price_field'>

                                  <FormikControl
                                    control='SelectBox'
                                    name='oneTimePayment' // Use the correct field name
                                    placeholder='Choose a Payment Mode'
                                    options={paymentCategorry}
                                    prevalue={(formik?.values?.oneTimePayment)}

                                  />
                                </div>

                                <div className='col-lg-3 col-md-3 price_field'>

                                  <FormikControl
                                    control='SelectBox'
                                    name='months' // Use the correct field name
                                    placeholder='Choose a Months'
                                    options={MonthsCategorry}
                                    disabled={formik.values.oneTimePayment !== 2}
                                    prevalue={formik.values.months}
                                  />
                                </div>


                              </div>
                            </div>
                          </div>

                          <div className='row mt-5 text_left'>

                            <SubTitle title="Define Pupose" />


                            <div className="col-lg-12 col-md-12">
                              <FormikControl
                                control='CustomTextArea'
                                placeholder=''
                                name='definePurpose'
                                value={formik.values.definePurpose}
                              />
                            </div>
                          </div>
                          <div className='row mt-5 text_left'>

                            <SubTitle title="Define Scope" />


                            <div className="col-lg-12 col-md-12">
                              <FormikControl
                                control='CustomTextArea'
                                placeholder=''
                                name='defineScope'
                                value={formik.values.defineScope}
                              />
                            </div>
                          </div>





                        </div>
                        <div className='col-lg-2 files col-md-2'>
                          <div className='text_left'>
                            <SubTitle title="Upload Image" />
                          </div>
                          <div>
                            <FormikControl
                              control='UploadFile'
                              placeholder=''
                              name='image'
                              allowedExtensions={['jpg', 'jpeg', 'png']}
                              maxFileSize={1}
                              setField={setFieldValue}
                            />
                          </div>

                        </div>


                        <Loader loading={loading} />
                      </div>
                      <div className='row mt-5'>
                        <div className='d-flex justify-content-between align-items-start'>
                          <div className='col-lg-5 text_left col-md-5'>
                            <SubTitle title="Project Overview" />

                            <div className='d-flex justify-content-between  flex-column'>

                              <div className='my-2'>
                                <div className='pro_overview'>
                                  <p>Goal</p>
                                </div>

                                <div>
                                  <FormikControl
                                    control='CustomTextArea'
                                    placeholder=''
                                    name='goal'
                                    value={formik.values.goal}
                                  />
                                </div>
                              </div>
                              <div className='my-2'>
                                <div className='pro_overview'>
                                  <p>Deliverables</p>
                                </div>

                                <div>
                                  <FormikControl
                                    control='CustomTextArea'
                                    placeholder=''
                                    name='deliverables'
                                    value={formik.values.deliverables}
                                  />
                                </div>
                              </div>
                              <div className='my-2'>
                                <div className='pro_overview'>
                                  <p>Objective</p>
                                </div>

                                <div>
                                  <FormikControl
                                    control='CustomTextArea'
                                    placeholder=''
                                    name='objective'
                                    value={formik.values.objective}
                                  />
                                </div>
                              </div>
                              <div className='my-2'>
                                <div className='pro_overview'>
                                  <p>Duration (InWeeks)</p>
                                </div>

                                <div>
                                  <FormikControl
                                    control='Input'
                                    placeholder=''
                                    name='durationInWeeks'
                                    value={formik.values.durationInWeeks}
                                  />
                                </div>
                              </div>



                            </div>
                          </div>


                        </div>

                      </div>
                      <div className='row mt-5'>
                        <div className='text_left'>
                          <SubTitle title="Upload Files" />
                        </div>

                        <div className='d-flex justify-content-between align-items-start upload_wrap'>
                          <div className='col-lg-5 text_left col-md-4'>


                            <div className='d-flex justify-content-between  flex-column'>

                              <FormikControl
                                control='UploadFile'
                                placeholder=''
                                name='documents'
                                allowedExtensions={['xlsx', 'docx', 'pdf', 'pptx', 'csv', 'txt']}
                                maxFileSize={5}
                                setField={setDocument}
                              />



                            </div>
                          </div>


                          <div className='text_left col-lg-6 col-md-6 file_tile'>
                            <div>
                              {
                                editData?.documentFile.length > 0
                                && <FileNotification icons={2} data={editData.documentFile} handleDownload={handleDownload} hadleDelete={hadleDeletePdf} />
                              }


                            </div>
                          </div>



                        </div>

                      </div>


                    </div>
                    <div>

                    </div>
                    <div className='d-flex justify-content-center align-items-center  mt-5 '>
                      <div className='col-lg-1' onClick={isDelete ? () => handleDeleteModal() : null}>
                        <UnderLine text="Deactive  Service" />
                      </div>
                      <div className='col-lg-4'>
                        <Button type='submit' text='Next' disabled={formik.isSubmitting || !isEdit} />
                      </div>


                    </div>



                  </Form>
                )
              }}
            </Formik>

          </div>



        </div>) : (
          ''

        )
      }
    </div>
  )
}

export default EditService
