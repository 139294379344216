import React, { useEffect, useState } from 'react';
import crop from "../../../../Assets/images/service/crop-circle.png";
import star from "../../../../Assets/images/Icons/start_fill.svg"
import { GETREVIEWAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';
import Loader from '../../Loader';

function Review({ data, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({})
    const fetchData = async () => {
        try {
            setLoading(true);

            const result = await GETREVIEWAPi(data?.projectId);
            if (result.res.status === true) {
                setLoading(false);
                setAllData(result.res.data)

            } else {
                setLoading(false);
                closeModal();
                toast.error(result.res.message)
            }
        } catch (err) {
            toast.error(err);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [data?.projectId]);

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                <div className='review_head'>
                    <div>
                        <h5>Review</h5>
                    </div>


                </div>
                <div className='row'>
                    <div className='review_content'>
                        <div className='col-lg-2'>
                            <div className='review_prof'>
                                <img src={allData?.serviceImage} />

                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <div className='review_text'>
                                <p>
                                    {allData?.title}
                                </p>
                                <p>
                                    “{allData?.reviewText}”
                                </p>
                                <p> {allData?.userName}</p>
                            </div>

                        </div>


                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='rating'>
                            <div className='col-lg-3'>
                                <div className='rat_text'>
                                    <p>
                                        Service Rating
                                    </p>

                                </div>
                            </div>
                            <div className='col-lg-6'>
                           
                                <div className='rat_star'>
                                    <ul>
                                        {Math.trunc(allData?.taskRating) > 0 && Array(Math.trunc(allData?.taskRating)).fill().map((_, index) => (
                                            <li key={index}>
                                                <img src={star} alt={`Star ${index + 1}`} />
                                            </li>
                                        ))}
                                    </ul>


                                </div>
                            </div>


                        </div>
                    </div>

                </div>


                <div className='employee_rate_tile'>
                    <div className='row'>
                        {
                            allData?.employeeReviewDtoList?.map((res) => {
                                return (
                                    <div className='col-lg-4  mb-2'>
                                        <div className='employee_rate_tile_text'>
                                            <p>
                                                {res?.employeeName}
                                            </p>
                                            <span>
                                                {res?.roleName}
                                            </span>

                                        </div>
                                        <div className='rat_star_emp'>
                                            <ul>
                                                {
                                                    Math.trunc(res?.employeeRating) && Array(Math.trunc(res?.employeeRating)).fill().map((_, index) => (
                                                        <li key={index}>
                                                            <img src={star} />
                                                        </li>
                                                    ))
                                                }

                                            </ul>

                                        </div>
                                    </div>
                                )
                            })
                        }



                    </div>

                </div>



            </div>
        </div>
    )
}

export default Review