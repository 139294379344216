import React from 'react'

function Square({children}) {
    return (
        <div className="square">
        <div>{children}</div>
      </div>
    )
}

export default Square