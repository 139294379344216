import React from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate"
import { Formik, Form } from 'formik';
import moment from "moment";
import * as Yup from 'yup'

function DeleteLink(props) {
    const { closeModal, data, handle } = props
    const handlecancel = () => {
        closeModal();
    }

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                >
                    {formik => {
                        return (
                            <Form className="form_default">
                                <div className='mb-4'>
                                    <FormikControl
                                        control='heading'
                                        title='Are you sure you want to remove this?'

                                    />

                                </div>
                                <div className='delete_modal'>
                                    <div className='row w-100'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <div className='delete_modal_content'>
                                                <p>
                                                    Title:
                                                </p>
                                                <span>
                                                    {
                                                        data?.linkName ? data?.linkName : data?.documentName
                                                    }
                                                    {
                                                        (!data?.linkName && !data?.documentName && data?.serviceName) && (
                                                            // JSX to render when conditions are met
                                                            <div>{data.serviceName}</div>
                                                        )
                                                    }

                                                </span>
                                            </div>
                                            <div className='delete_modal_content'>
                                                <p>
                                                    Added on:
                                                </p>
                                                <span>
                                                    {moment(data?.createdAt).format('DD/MM/YYYY')}
                                                </span>
                                            </div>


                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>

                                            {data?.linkUrl && (
                                                <div className='delete_modal_content cus_width'>
                                                    <p>Url:</p>
                                                    <span>{data.linkUrl}</span>
                                                </div>
                                            )}




                                        </div>



                                    </div>

                                </div>
                                {/* <div className='row mt-2'>
                                    <FormikControl
                                        control='Checkbox'
                                        name='Delete this link in every services.'

                                    />
                                </div> */}

                                <div className='d-flex justify-content-evenly align-items-center my-3'>
                                    {
                                        (data?.linkId || data?.documentId) && <div className='yes_button' onClick={() => handle(data?.linkId ? data.linkId : data?.documentId)}>
                                            Yes
                                        </div>
                                    }
                                    {
                                        data?.serviceId && <div className='yes_button' onClick={() => handle(data?.serviceId)}>
                                            Yes
                                        </div>

                                    }

                                    <div onClick={handlecancel}>
                                        <Button text="No" type="button" />

                                    </div>

                                </div>

                            </Form>
                        )
                    }}
                </Formik>


            </div>
        </div>
    )
}

export default DeleteLink