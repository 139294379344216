import React, { useState, useMemo, useEffect } from 'react';
import Button from '../../Buttons/Navigate';
import DefaultTable from "../../Tables/DefaultTable";
import FormControl from '../../Controls/FormControl';
import SubTitle from '../../Heading/SubTitle';
import { Form, Formik } from 'formik';
import { RaciGet, ServiceUpdate } from '../../../../Utils/services';
import { toast } from 'react-toastify';
import Loader from '../../Loader';

function Raci({ closeModal, Reload, id, getData }) {
    const [loading, setLoading] = useState(false);
    const [raciMappingList, setRaciMappingList] = useState([]); // Initialize with an empty array

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await RaciGet();
                if (result?.res?.status === true) {


                    if (getData && getData.length > 0) {
                        const FilterData = result?.res?.data?.filter((selectedData) => {
                            const matchingData = getData?.find((data) => data.masterRaciId === selectedData.masterRaciId);
                            return !matchingData || selectedData.stakeHolder !== matchingData.stakeHolder;
                        });
                        setRaciMappingList(FilterData)
                    }
                    else {
                        setRaciMappingList(result?.res?.data)
                    }


                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [getData]);

    const [searchVal, setSearchVal] = useState('');

    const handleCheckboxChange = (masterRaciId, column) => {
        setRaciMappingList((prevList) =>
            prevList.map((item) =>
                item.masterRaciId === masterRaciId
                    ? { ...item, [column]: !item[column] }
                    : item
            )
        );
    };

    const saveSelectedRows = async () => {
        let submitData = []
        const selectedRowData = raciMappingList.filter((item) => item.select);
        const updatedData = selectedRowData
            .filter((item) => item.select)
            .map(({ select, ...rest }) => rest);

        if (getData && getData?.length > 0) {
            submitData = updatedData?.filter((selectedData) => {
                const matchingData = getData?.find((data) => data.masterRaciId === selectedData.masterRaciId);
                return !matchingData || selectedData.stakeHolder !== matchingData.stakeHolder;
            });

        }
        else {
            submitData = updatedData;
        }



        const data = new FormData();
        data.append("taskCreateRequest", JSON.stringify({ 'raciMappingList': submitData }))
        if (submitData && submitData.length > 0) {
            setLoading(true);
            try {
                const result = await ServiceUpdate(id?.step1?.serviceId ? id?.step1?.serviceId : id, data);


                if (result?.res.status == true) {

                    setLoading(false);
                    toast.success(result?.res.message || "Service created successfully");
                    Reload();
                    closeModal();
                }
                else {
                    setLoading(false);
                    toast.error(result?.res.message)
                }
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        }
        else {
            toast.error("Data is Required ");
        }




    };
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'select',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.masterRaciId, 'select')}
                        checked={row.original.select}
                    />
                ),
            },
            // {
            //     Header: 'Sno',
            //     accessor: 'masterRaciId',
            // },
            {
                Header: 'Value',
                accessor: 'stakeHolder',
            },
            {
                Header: 'Responsible(R)',
                accessor: 'raciR',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.masterRaciId, 'raciR')}
                        checked={row.original.raciR}
                    />
                ),
            },
            {
                Header: 'Accountable(A)',
                accessor: 'raciA',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.masterRaciId, 'raciA')}
                        checked={row.original.raciA}
                    />
                ),
            },
            {
                Header: 'Consulted(C)',
                accessor: 'raciC',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.masterRaciId, 'raciC')}
                        checked={row.original.raciC}
                    />
                ),
            },
            {
                Header: 'Informed(I)',
                accessor: 'raciI',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.masterRaciId, 'raciI')}
                        checked={row.original.raciI}
                    />
                ),
            },
        ],
        [handleCheckboxChange]
    );

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik>
                    {formik => (
                        <Form className='text-center' onSubmit={e => { e.preventDefault(); }}>
                            <div className='mb-4'>
                                <FormControl
                                    control='heading'
                                    title='Add/Remove RACI VALUES'
                                />
                            </div>
                            <div className='col-lg-8 mb-3'>
                                <FormControl
                                    control='Search'
                                    placeholder='Search For Stakeholder'
                                    setSearchVal={setSearchVal}
                                    searchVal={searchVal}
                                />
                            </div>
                            <Loader loading={loading} />
                            <div className="display_table mb-3" style={{ height: '300px', overflowY: 'scroll' }}>
                                {
                                    raciMappingList && <DefaultTable
                                        columns={columns}
                                        data={raciMappingList}
                                        checkboxColumns={['raciR', 'raciA', 'raciC', 'raciI', 'select']}
                                        onCellValueChange={handleCheckboxChange}
                                        searchVal={searchVal}
                                        isEditable={true}
                                    />
                                }

                            </div>
                            <div onClick={saveSelectedRows} >
                                <Button type='button' text='Add' disabled={loading} />
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default Raci;
