import React, { useContext, useState, useEffect } from 'react';
import Title from '../../Components/Common/Heading/Title';
import ADD from '../../../src/Components/Common/Buttons/ADD';
import link from "../../../src/Assets/images/Icons/add_link.svg";
import documents from "../../../src/Assets/images/Icons/add_documnet.svg";
import FormControl from '../../../src/Components/Common/Controls/FormControl';
import { Formik, Form } from 'formik';
import { useSearch } from "../../Components/Common/Hooks/TableSearch/useSearch"
import Loader from '../../Components/Common/Loader';
import { Link } from 'react-router-dom';
import { ModalContext } from '../../Utils/Context';
import UploadLink from '../../Components/Common/Modals/UploadLink';
import UploadDocument from '../../Components/Common/Modals/UploadDocument';
import { ServiceALLGet } from '../../Utils/services';
import { useAbility } from '../../Utils/AbilityContext';
import AuthContext from '../../Utils/auth-context';


function Upload() {
    const [loading, setloading] = useState(false);
    const [allData, setallData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const modalContext = useContext(ModalContext);
    const { isAdmin, permission } = useContext(AuthContext);
    const { closeModal, handleModalData } = modalContext;
    const ability = useAbility();

    // Using the useSearch hook to filter data
    useSearch(searchQuery, allData, setFilteredData, ["serviceName", "description", 'linkCount', 'documentCount']);

    const fetchData = async () => {
        setloading(true);
        try {
            const result = await ServiceALLGet();
            if (result?.res?.status === true) {
                const filtered = result?.res?.data?.filter(item => item.serviceId !== '38f934ca-4b11-11ee-be56-0242ac120002');
                setallData(filtered);
                setFilteredData(filtered);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const data = [
        {
            icons: link,
            name: "Links",
            links: "row-materials",
        },
        {
            icons: documents,
            name: "Documents",
            links: "row-materials",
        },
    ];

    // Corrected the condition: searchQuery.length < 0 should be searchQuery.length === 0
    useEffect(() => {
        if (searchQuery.length === 0) {
            setFilteredData(allData);
        }
    }, [searchQuery, allData]);

    const handleModal = (val) => {
        const tabsValue = val;
        const Upload = <UploadLink closeModal={closeModal} Reload={fetchData} />;
        const Document = <UploadDocument closeModal={closeModal} Reload={fetchData} />;

        switch (tabsValue) {
            case "Documents":
                handleModalData(Document, "md");
                break;
            case "Links":
                handleModalData(Upload, "md");
                break;
            default:
                break;
        }
    };
    const isCreate = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
    const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'delete');
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Title title="Links and Documents" />
                <div className='row'>
                    <div className='d-flex justify-content-between align-items-center links_upload'>
                        <div className='col-lg-6 col-md-8'>
                            <Formik>
                                <Form onSubmit={e => { e.preventDefault(); }}>
                                    <FormControl
                                        control='Search'
                                        placeholder='Search For Services'
                                        setSearchVal={setSearchQuery}
                                        searchVal={searchQuery}
                                    />
                                </Form>
                            </Formik>
                        </div>
                        <div className='col-lg-3 col-md-3 btn_wrap'>
                            <div className='d-flex justify-content-between align-items-center add_btns'>

                                {
                                    data.map((val, i) => {
                                        const matchingAbility = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'create')

                                        return (
                                            <div key={i} onClick={matchingAbility ? () => handleModal(val.name) : null}>
                                                <ADD text={val.name} source={val.icons} disabled={matchingAbility} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Loader loading={loading} />
            <div className='row mt-5 gy-4 gx-4'>
                {filteredData.map((val) => (
                    <div key={val.serviceId} className='col-lg-4 col-md-6'>
                        <Link to={`/uploadinner/${val.serviceId}`}>
                            <div className='links_tile'>

                                <div className='d-flex justify-content-start align-items-center'>
                                    <p className='link_head'>{val.serviceName}</p>
                                </div>
                                <div className='d-flex justify-content-start align-items-center mt-2'>
                                    <p className='link_text'>
                                        <span>{val.linkCount}</span>
                                        <span className='mx-1'>Links</span>
                                    </p>
                                    <span className='mx-1'>
                                        |
                                    </span>

                                    <p className='link_text'>
                                        <span>{val.documentCount}</span>
                                        <span className='mx-1'>Documents</span>
                                    </p>
                                </div>

                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Upload;
