import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from "../Errros"

function Checkbox(props) {
    const { name, isChecked, value, label, ...rest } = props;

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div className='d-flex align-items-center justify-content-between'>
                            {/* <input
                                type="checkbox"
                                id={name}
                                checked={isChecked}
                                {...field}
                                {...rest}
                            />
                            <label htmlFor={name} className='mx-2'>
                                {label}
                            </label> */}
                            <label for="accented-light">

                                {label}

                            </label>
                            <input
                                className='mx-2'
                                type="checkbox"
                                id={name}
                                checked={isChecked}
                                {...field}  
                                {...rest}
                            />

                        </div>
                        <ErrorMessage component={TextError} name={name} />
                    </div>
                )}
            </Field>
        </div>
    )
}

export default Checkbox;
