import React, { useState, useEffect } from 'react';
import { CustomTabs, CustomTabPanel } from '../../../Components/Common/Tabs';
import Raci from '../RaciMaster';
import Title from '../../../Components/Common/Heading/Title';
import Roles from '../Roles';
import Notifications from '../Notifications';
import AddCompany from '../AddCompany';
import ViewEmployee from '../Employee/ViewEmployee';
import Customers from '../../Customers';
import Scans from '../../Scans';
import TermsAndCondition from '../TermsAndCondition';

function Raci_Role() {
  // Get the active tab from local storage or default to the first tab
  const initialTab = parseInt(localStorage.getItem('activeTab')) || 1;

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(initialTab);

  // Update local storage when the active tab changes
  useEffect(() => {
    localStorage.setItem('activeTabservice', parseInt(activeTab));
  }, [activeTab]);

  return (
    <div>
      <div className="container-fluid pb-5">
        <div className="row mb-5">
          <Title title="Master" />
        </div>
        <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
          <CustomTabPanel tabLabel="Raci" key="1">
            <Raci />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="Roles" key="2">
            <Roles />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="Notification" key="3">
            <Notifications />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="Employees" key="4">
            <ViewEmployee />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="Companies" key="5">
            <Customers />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="Scans" key="6">
            <Scans />
          </CustomTabPanel>
          <CustomTabPanel tabLabel="T&C" key="7">
            <TermsAndCondition />
          </CustomTabPanel>
        </CustomTabs>
      </div>
    </div>
  );
}

export default Raci_Role;
