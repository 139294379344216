import React, { useContext, useEffect, useState } from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate";
import { toast } from 'react-toastify';
import Loader from "../../../Common/Loader"
import { POSTSCANSAPi, RaciCreate, UPDATESTARTDATEAPI } from "../../../../Utils/services";
import { ModalContext } from '../../../../Utils/Context';
import { Formik, Form } from 'formik';

import * as Yup from 'yup'
import AuthContext from '../../../../Utils/auth-context';
import SubTitle from '../../Heading/SubTitle';

function AddStartDate({ closeModal, reload, projectdata}) { 
    const [options, setOptions] = useState([]);
    const [data, setdata] = useState()
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const allData = {
        date: ""
        
    };
    const authContext = useContext(AuthContext);
    const Users = JSON.parse(authContext?.user)
    const handle = async (values) => {
        if (values.formData) {

            const date = new Date(values.formData.date);
            const formattedDate = `${date.toISOString().split('T')[0]}T00:00:00`;
           
            const formData = new FormData();
            
           
            formData.append("projectId", projectdata?.projectId);
            formData.append("startDate", formattedDate);
    
            setLoading(true);
            try {
                // Send FormData instead of JSON object
                const result = await UPDATESTARTDATEAPI(formData);
                
                if (result?.res?.status === true) {
                    closeModal();
                    setLoading(false);
                    toast.success(result?.res?.message);
                    reload({ type: 1, page: currentPage, size: 10 });
                }
            } catch (error) {
                closeModal();
                toast.error(error.message);
                setLoading(false);
            }
        }
    };
    

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            date: Yup.date().required("Date is required") // Use Yup.date() for date validation
        }),
    });
    const runnableScanTypes = [
        { value: "NMAP", label: "NMAP", id: "NMAP" },
        { value: "OPENVAS", label: "OPENVAS", id: "OPENVAS" },
        { value: "SSLYZE", label: "SSLYZE", id: "SSLYZE" },
        { value: "NMAP_UDP", label: "NMAP_UDP", id: "NMAP_UDP" },
        { value: "OWASP_ZAP", label: "OWASP_ZAP", id: "OWASP_ZAP" },
        { value: "OWASP_ZAP_ACTIVE", label: "OWASP_ZAP_ACTIVE", id: "OWASP_ZAP_ACTIVE" }

    ];

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: allData,
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-5'>
                                <FormikControl
                                    control='heading'
                                    title='Add Project Start Date'
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='d-flex  align-items-center justify-content-between flex-wrap'>
                                    {/* <div className='col-lg-12 mb-2'>

                                        <FormikControl
                                            name='formData.url'
                                            placeholder='Add Scan'
                                            value={formik?.values?.formData?.url}
                                            control='Input'
                                            type="url"


                                        />
                                    </div> */}
                                                 <div>
                                                 <SubTitle title={`Service Name: ${projectdata.serviceName}`} />

                                                </div>

                                    <div className='col-lg-12 mb-5 mt-3'>
                                        <FormikControl
                                            control='Input'
                                            placeholder='Add Date'
                                            name='formData.date'
                                            type="date"
                                            value={formik.values.formData?.date}
                                        />
                                    </div>

                                </div>
                            </div>

                            <Button type='submit' text='Save' disabled={formik.isSubmitting} />
                            <Loader loading={loading} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AddStartDate;
