import React, { Suspense, useEffect, useState } from "react";
import SIDEBAR_UL from "./sidebarList";
import downArrow from "../../Assets/images/Logos/downArrow.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../Utils/auth-context";
import { useAbility } from "../../Utils/AbilityContext";
import Loader from '../../Components/Common/FallBack';


const Sidenav = (props) => {
    const toggle = props.toggle;
    const handletoggle = props.handletoggle;
    const [activeLink, setActiveLink] = useState({ 0: true });
    const [listShow, setListShow] = useState({ 0: true });
    const [buttonid, setButtonId] = useState(0);
    const [all, setAll] = useState([]);
    const { isAdmin, permission } = useContext(AuthContext);
    const ability = useAbility();
    const navigate = useNavigate();

    const listHandler = (id, sublist, listRef) => {
        setActiveLink({ [id]: true });

        if (sublist !== undefined && !listShow[listRef]) {
            setListShow({ [listRef]: true });
        } else {
            setListShow({ [listRef]: false });
        }
    };

    if (isAdmin === "false" || isAdmin === false) {
        return (
            <div>
                <Suspense fallback={<Loader />}>
                    <ul className="side-menu">
                        {SIDEBAR_UL && SIDEBAR_UL?.map((list, id) => {
                            let listRef = list.ref;
                            let sublist = list.sublist;
                            let labelText = list.label;
                            let path = list.path;
                            let src = list.src;
                            let src1 = list.src1;

                            const matchingAbility = ability?.A?.find((a) => a?.subject === list?.module_id);


                            return (
                                <React.Fragment key={list.id}>
                                    {matchingAbility && (
                                        <li
                                            key={list.id}
                                            id={id}
                                            onClick={() => {
                                                { sublist === undefined ? handletoggle() : listHandler(id, sublist, listRef) }
                                            }}
                                        >
                                            <Link
                                                to={`${path}`}
                                                onClick={() => {
                                                    listHandler(id, sublist, listRef);
                                                    setButtonId(parseInt(id));
                                                }}
                                                className={activeLink[id] && "active"}
                                            >
                                                <div className="navIcon d-flex">
                                                    {" "}

                                                    <div className="sidenavimg">
                                                        <img src={id === buttonid ? src : src1} alt="" />
                                                    </div>{" "}
                                                    <p className={id === buttonid ? 'side_menutext mx-2' : 'mx-2'}> {labelText}</p>
                                                </div>

                                                <div>{
                                                    matchingAbility && sublist && (
                                                        <img
                                                            src={downArrow}
                                                            className={`downArrow ${listShow[listRef] && "upArrow"
                                                                }`}
                                                            alt="logo"
                                                        />
                                                    )
                                                }

                                                </div>

                                            </Link>
                                        </li>
                                    )}

                                    {sublist && matchingAbility && (
                                        <li className="p-0">
                                            <ul className={listShow[listRef] && "submenu"}>
                                                {list.sublist.map((list, id) => (
                                                    <li key={list.id}>
                                                        <NavLink
                                                            to={`${list.path}`}
                                                            activeclassname="active"
                                                            className="subnavlist"
                                                        >
                                                            <div className="subnavIcon d-flex">
                                                                {" "}
                                                                <div className="me-3">
                                                                    <img src={window.location.pathname === list.path ? list.src : list.src1} alt="" />
                                                                </div>

                                                                <p className={window.location.pathname === list.path ? 'side_menutext ' : ''}
                                                                    onClick={() => {
                                                                        handletoggle();
                                                                    }}
                                                                >
                                                                    {list.label}
                                                                </p>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </Suspense>
            </div>
        );
    }
    else {
        return (
            <div>
                <Suspense fallback={<Loader />}>
                    <ul className="side-menu">
                        {SIDEBAR_UL && SIDEBAR_UL?.map((list, id) => {
                            let listRef = list.ref;
                            let sublist = list.sublist;
                            let labelText = list.label;
                            let path = list.path;
                            let src = list.src;
                            let src1 = list.src1;



                            return (
                                <React.Fragment key={list.id}>
                                    {true && (
                                        <li
                                            key={list.id}
                                            id={id}
                                            onClick={() => {
                                                { sublist === undefined ? handletoggle() : listHandler(id, sublist, listRef) }
                                            }}
                                        >
                                            <Link
                                                to={`${path}`}
                                                onClick={() => {
                                                    listHandler(id, sublist, listRef);
                                                    setButtonId(parseInt(id));
                                                }}
                                                className={activeLink[id] && "active"}
                                            >
                                                <div className="navIcon d-flex">
                                                    {" "}

                                                    <div className="sidenavimg">
                                                        <img src={id === buttonid ? src : src1} alt="" />
                                                    </div>{" "}
                                                    <p className={id === buttonid ? 'side_menutext mx-2' : 'mx-2'}> {labelText}</p>
                                                </div>

                                                <div>
                                                    {sublist && (
                                                        <img
                                                            src={downArrow}
                                                            className={`downArrow ${listShow[listRef] && "upArrow"
                                                                }`}
                                                            alt="logo"
                                                        />
                                                    )}
                                                </div>

                                            </Link>
                                        </li>
                                    )}

                                    {sublist && (
                                        <li className="p-0">
                                            <ul className={listShow[listRef] && "submenu"}>
                                                {list.sublist.map((list, id) => (
                                                    <li key={list.id}>
                                                        <NavLink
                                                            to={`${list.path}`}
                                                            activeclassname="active"
                                                            className="subnavlist"
                                                        >
                                                            <div className="subnavIcon d-flex">
                                                                {" "}
                                                                <div className="me-3">
                                                                    <img src={window.location.pathname === list.path ? list.src : list.src1} alt="" />
                                                                </div>

                                                                <p className={window.location.pathname === list.path ? 'side_menutext ' : ''}
                                                                    onClick={() => {
                                                                        handletoggle();
                                                                    }}
                                                                >
                                                                    {list.label}
                                                                </p>
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </Suspense>
            </div>
        );
    }

};

export default Sidenav;