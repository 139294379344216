import React from "react";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Delete from "../../../../../Assets/images/Icons/delete.png";
import FormControl from "../../FormControl";

export default function DynamicTable(props) {
    const { columns, isDyanmic, className, name, rowdata, setField, onChangefunc, fieldname } = props;

    const linksGroup = rowdata



    const schema = Yup.object().shape({
        // Add validation schema if needed
    });

    const handleSubmit = (values) => {
        // Define your submission logic here

    };
    const handleRemove = (indexToRemove) => {

        if (indexToRemove >= 0 && indexToRemove < rowdata.length) {
            const newMilestones = rowdata.filter((_, index) => index !== indexToRemove);

            setField(newMilestones);
        }
    };

    return (


        <form> {/* Wrap your form content in a <form> */}
            <table className={className ? `${className}` : "custom_table"}>
                <thead>
                    <tr>
                        {columns &&
                            columns.map((val, index) => (
                                <th key={index}>{val}</th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name={name}>
                        {({ push, remove }) => (
                            <>
                                {linksGroup.map((res, index) => {

                                    return (<>
                                        <tr key={index}>


                                            <td className="">{index + 1}</td>
                                            {
                                                linksGroup.length > 1 && !isDyanmic ? (
                                                    Object.keys(res[name])?.filter(key => key !== "projectMilestoneId").map((value) => {
                                                        return (
                                                            <>
                                                                <td className="">
                                                                    <Field
                                                                        type="text"
                                                                        name={value}
                                                                        value={res[name][value]}
                                                                        onChange={(e) => onChangefunc(e, index)}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`${fieldname}`}
                                                                        component="div"
                                                                        className="error"
                                                                    />
                                                                </td>

                                                            </>
                                                        )
                                                    })


                                                ) : (
                                                    <td className="">
                                                        <Field
                                                            type="text"
                                                            name={`${name}.${index}.${fieldname}`}
                                                            value={res[fieldname]}
                                                            onChange={(e) => onChangefunc(e, index)}
                                                        />
                                                        <ErrorMessage
                                                            name={`${name}.${index}.${fieldname}`}
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </td>

                                                )
                                            }

                                            {index > 0 && isDyanmic && (

                                                <div
                                                    className="tbl_btn"
                                                    onClick={() => handleRemove(index)}>

                                                    <img src={Delete} alt="" />
                                                </div>

                                            )}
                                        </tr>

                                    </>);
                                })}
                                {isDyanmic && (
                                    <div className="d-flex justify-content-end align-items-center m-2">
                                        <div
                                            className="tbl_btn ml-5"
                                            onClick={() => {
                                                setField([...rowdata, { [fieldname]: "" }]);
                                                // push({ mileStoneName: "" }); // Push an empty milestone
                                            }}
                                        >
                                            <p>Add</p>
                                        </div>
                                    </div>
                                )}

                            </>
                        )}

                    </FieldArray>

                </tbody>
            </table>


        </form>

    );
}
