import React from 'react';
import Circle from '../../../../Assets/images/Icons/MyServiceImage.png';
import Star from "../../Other/Star"


function ReviewCards({ data }) {

    return (
        <div className='row m-2'>
            <div className='card_tile'>
                <div className='row'>
                    <div className='card_rate'>
                        <Star readonly={true} count={data?.taskRating} />
                    </div>

                </div>
                <div className='row'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='review_img'>
                            <img src={Circle} alt='' />

                        </div>
                        <div className='d-flex  justify-content-between align-items-start flex-column px-3 col-lg-9'>
                            <div>
                                <p className='card_head'>{data?.title}</p>
                            </div>
                            <div>
                                <p className='card_text'>“{data?.reviewText}”</p>
                            </div>
                            <div>
                                <p className='card_sign'>Lorem ipsum dolor s</p>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default ReviewCards