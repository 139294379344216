import React from 'react';
import FormikControl from "../../Controls/FormControl";
import crop from "../../../../Assets/images/service/crop-circle.png"
import Button from "../../Buttons/Navigate"
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

function UndoCompany({ closeModal, data, handle, reload, setCustomer }) {
    const handlecancel = () => {
        closeModal()
        setCustomer([])
        reload()

    }
    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                >
                    {formik => {
                        return (
                            <Form className="form_default">
                                <div className='mb-4'>
                                    <FormikControl
                                        control='heading'
                                        title='Are you sure you want to revoke this?'

                                    />

                                </div>
                                <div className='delete_modal'>
                                    <div className='row w-100'>
                                        <div className='d-flex justify-content-around align-items-center mb-2'>
                                            <div className='d-flex justify-content-between align-items-start flex-column '>

                                                <div className='delete_modal_content mb-2'>
                                                    <p>
                                                        Comapny Name:
                                                    </p>
                                                    <span>
                                                        {data?.companyName}
                                                    </span>
                                                </div>
                                                {/* <div className='delete_modal_content  mb-2'>
                                                    <p>
                                                        Name:
                                                    </p>
                                                    <span>
                                                        {data?.employeeName}
                                                    </span>
                                                </div>
                                                <div className='delete_modal_content'>
                                                    <p>
                                                        Role:
                                                    </p>
                                                    <span>
                                                        {data?.roleName}
                                                    </span>
                                                </div> */}



                                            </div>





                                        </div>




                                    </div>

                                </div>

                                <div className='d-flex justify-content-evenly align-items-center my-3'>
                                    <div className='yes_button' onClick={() => handle(data)}>
                                        Yes
                                    </div>
                                    <div onClick={handlecancel}>
                                        <Button text="No" type="button" />

                                    </div>

                                </div>

                            </Form>
                        )
                    }}
                </Formik>


            </div>
        </div>
    )
}

export default UndoCompany