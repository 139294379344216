import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import TextError from '../Errros';
import { toast } from 'react-toastify';

function PhoneNumber(props) {
    const { name, type, placeholder, value, disabled,...rest } = props;
    const [error, setError] = useState('')

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div>
                            <PhoneInput
                                {...field}
                                countryCodeEditable={false}
                                defaultCountry="IN"
                                disabled={disabled}
                                value={field.value} // Use field.value to populate the input
                                onChange={(value) => {
                                    const stringValue = String(value); // Convert value to a string
                                    form.setFieldValue(name, stringValue);
                                    const isValid = isValidPhoneNumber(stringValue);
                                    if (!isValid) {
                                        setError('Invalid phone number');
                                    } else {
                                        // Clear the error if the phone number is valid
                                        setError('');
                                    }
                                }}
                            />
                            <div className='error'>{error && error}</div>
                            {/* <ErrorMessage component={TextError} name={name} /> */}

                        </div>
                    </div>
                )}
            </Field>

        </div>
    );
}

export default PhoneNumber;
