import React from 'react'

function ButtonLoader({ loading }) {
    return (
        <div>
            {
                loading && <div className=''>
                    <div className='loadingSpinnerContainer_btn'>
                        <div className='loadingSpinner_btn'></div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ButtonLoader