import React, { useState, useEffect } from 'react';
import View from "../../../Common/Buttons/View";
import Edit from '../../../../Assets/images/Icons/edit.svg';
import Delete from '../../../../Assets/images/Icons/delete.svg'
import FormControl from '../../Controls/FormControl';
import { DELTEMILESTONEAPi, MileStoneDelete, ServiceUpdate } from '../../../../Utils/services';
import Button from '../../Buttons/Navigate';
import { toast } from 'react-toastify';

function EditMilestone({ id, closeModal, Reload, data }) {


    const [milestones, setMilestones] = useState([]);
    const [milestoneInput, setMilestoneInput] = useState('');
    const [subMilestoneInput, setSubMilestoneInput] = useState('');
    const [subWeightage, setSubWeightage] = useState('');
    const [editingMilestoneIndex, setEditingMilestoneIndex] = useState(-1);
    const [editingSubMilestone, setEditingSubMilestone] = useState({ milestoneIndex: -1, subMilestoneIndex: -1 });
    const [milestoneError, setMilestoneError] = useState('');
    const [subMilestoneError, setSubMilestoneError] = useState('');
    const [milestoneAdded, setMilestoneAdded] = useState(false);
    const [loading, setLoading] = useState(false);


    const fetchData = async () => {
        setLoading(true)
        try {

            if (data && data != null && data != undefined) {

                setMilestones(data)
                setLoading(false)
            }
        }
        catch (e) {
            toast.error(e)
            setLoading(false)
        }


    }


    useEffect(() => {
        fetchData()
    }, [data])



    const handle = async () => {

        const totalSubWeightage = milestones[0]?.subMilestones?.reduce(
            (total, sub) => total + parseInt(sub.subWeightage || 0), // Convert to float
            0
        );

        if (true) {
            const data = new FormData();
            data.append("taskCreateRequest", JSON.stringify({ 'mileStones': milestones }))
            setLoading(true);

            if (milestones?.length > 0) {
                try {
                    const result = await ServiceUpdate(id?.step1?.serviceId ? id?.step1?.serviceId : id, data);
                    if (result?.res.status == true) {
                        Reload()
                        closeModal()
                        setLoading(false);
                        toast.success(result?.res.message || "Service created successfully");
                    }
                    else {
                        setLoading(false);
                        toast.error(result?.res.message)
                    }
                } catch (error) {
                    toast.error(error.message);
                    setLoading(false);
                }
            }
            else {
                toast.error("Minimum 1 Milestone is Required")
            }
        }
        else {
            toast.error('Submiletone is Required with Total Weightage of it is 100')
        }



    }



    const handleMilestoneChange = (event) => {
        setMilestoneInput(event.target.value);
    };

    const handleSubMilestoneChange = (event) => {
        setSubMilestoneInput(event.target.value);
    };
    const handleSubMilestoneweightageChange = (event) => {
        setSubWeightage(event.target.value);
    }
    const handleSaveMilestone = (milestoneIndex) => {
        const updatedMilestones = [...milestones];
        const milestoneToSave = updatedMilestones[milestoneIndex];
        const trimmedMilestoneInput = milestoneInput.trim();

        if (trimmedMilestoneInput !== '') {
            milestoneToSave.mileStoneName = trimmedMilestoneInput;
            setMilestones(updatedMilestones);

            setEditingMilestoneIndex(-1);
            setMilestoneInput('');
            setMilestoneError('');
        } else {
            setMilestoneError('Milestone name is required.');
        }
    };

    const handleSaveSubMilestone = (milestoneIndex, subMilestoneIndex) => {

        const updatedMilestones = [...milestones];
        const subMilestoneToSave = updatedMilestones[milestoneIndex].subMilestones[subMilestoneIndex];
        const trimmedSubMilestoneInput = subMilestoneInput.trim();
        const trimmedSubMilestoneWeightage = subWeightage;

        if (/\./.test(trimmedSubMilestoneWeightage)) {
            toast.error('Weightage must be an integer');
            return; // Exit the function or handle the error accordingly
        }

        const subWeightageInteger = parseInt(trimmedSubMilestoneWeightage, 10);

        if (isNaN(subWeightageInteger)) {
            toast.error('Weightage must be a valid number');
            return; // Exit the function or handle the error accordingly
        }

        if (trimmedSubMilestoneInput && trimmedSubMilestoneWeightage) {
            subMilestoneToSave.subMilestoneName = trimmedSubMilestoneInput;
            subMilestoneToSave.subWeightage = subWeightageInteger;
            setMilestones(updatedMilestones);
            setEditingSubMilestone({ milestoneIndex: -1, subMilestoneIndex: -1 });
            setSubWeightage('');
            setSubMilestoneInput('');
            setSubMilestoneError('');
        } else {
            setSubMilestoneError('Both fields are required.');
        }
    };


    const addMilestone = () => {
        const trimmedMilestoneInput = milestoneInput.trim();
        if (trimmedMilestoneInput !== '') {
            const newMilestone = {
                mileStoneName: trimmedMilestoneInput,
                subMilestones: [],
            };
            const updatedMilestones = [...milestones, newMilestone];
            setMilestones(updatedMilestones);
            setMilestoneAdded(true);
            setMilestoneInput('');
            setMilestoneError('');
        } else {
            setMilestoneError('Milestone name is required.');
        }
    };

    const addSubMilestone = (milestoneIndex) => {
        const trimmedSubMilestoneInput = subMilestoneInput.trim();
        const trimmedSubMilestoneWeightage = subWeightage.trim();

        if (/\./.test(trimmedSubMilestoneWeightage)) {
            toast.error('Weightage must be an integer');
            return; // Exit the function or handle the error accordingly
        }

        const subWeightageInteger = parseInt(trimmedSubMilestoneWeightage, 10);

        if (isNaN(subWeightageInteger)) {
            toast.error('Weightage must be a valid number');
            return; // Exit the function or handle the error accordingly
        }

        if (trimmedSubMilestoneInput !== '') {
            const updatedMilestones = [...milestones];
            const newSubMilestone = {
                subMilestoneNo: updatedMilestones[milestoneIndex].subMilestones.length + 1,
                subMilestoneName: trimmedSubMilestoneInput,
                subWeightage: subWeightageInteger,
            };
            updatedMilestones[milestoneIndex].subMilestones.push(newSubMilestone);
            setMilestones(updatedMilestones);
            setSubWeightage('');
            setSubMilestoneInput('');
            setSubMilestoneError('');
        } else {
            setSubMilestoneError('This Field is required.');
        }
    };


    const handleEditMilestone = (milestoneIndex) => {
        setEditingMilestoneIndex(milestoneIndex);
        setMilestoneInput(milestones[milestoneIndex].mileStoneName);
        setMilestoneError('');
    };

    const handleDeleteMilestone = async (milestoneIndex, id) => {

        const updatedMilestones = [...milestones];
        updatedMilestones.splice(milestoneIndex, 1);
        setMilestoneAdded(false)
        setMilestones(updatedMilestones);
        try {
            const result = await MileStoneDelete(id?.step1?.serviceId, id);
            if (result.res.status == true) {
                toast.success(result.res.message);

            }
            else {
                toast.error(result.res.message)
            }

        }
        catch (err) {
            toast.error(err)
        }
    };

    const handleEditSubMilestone = (milestoneIndex, subMilestoneIndex) => {

        setEditingSubMilestone({ milestoneIndex, subMilestoneIndex });
        setSubMilestoneInput(milestones[milestoneIndex].subMilestones[subMilestoneIndex].subMilestoneName);
        setSubWeightage(milestones[milestoneIndex].subMilestones[subMilestoneIndex].subWeightage)
        setSubMilestoneError('');
    };

    const handleDeleteSubMilestone = async (milestoneIndex, subMilestoneIndex) => {
        const updatedMilestones = [...milestones];

        if (updatedMilestones[milestoneIndex]?.subMilestones?.length > 1) {
            try {
                const Auto_data =
                {
                    "taskId": id,
                    "milestoneId": updatedMilestones[milestoneIndex]?.mileStoneId,
                    "subMilestoneId": updatedMilestones[milestoneIndex]?.subMilestones[subMilestoneIndex]?.subMilestoneId,
                }
                const result = await DELTEMILESTONEAPi(Auto_data);
                if (result?.res?.status == true) {
                    if (
                        updatedMilestones[milestoneIndex]?.subMilestones[subMilestoneIndex]?.hasConversation == null
                    ) {
                        // Condition is met, remove the subMilestone    
                        const updatedSubMilestones = [...updatedMilestones[milestoneIndex].subMilestones];
                        updatedSubMilestones.splice(subMilestoneIndex, 1);

                        // Update the main milestones array with the modified subMilestones array
                        updatedMilestones[milestoneIndex].subMilestones = updatedSubMilestones;

                        setMilestones(updatedMilestones);
                    }
                    else if (updatedMilestones[milestoneIndex]?.subMilestones[subMilestoneIndex]?.hasConversation == false) {
                        const updatedSubMilestones = [...updatedMilestones[milestoneIndex].subMilestones];
                        updatedSubMilestones.splice(subMilestoneIndex, 1);

                        // Update the main milestones array with the modified subMilestones array
                        updatedMilestones[milestoneIndex].subMilestones = updatedSubMilestones;

                        setMilestones(updatedMilestones);
                    }
                    else {
                        // Condition is not met, show an error
                        toast.error("It is Not Permissible");
                    }
                    toast.success(result?.res?.message);
                }

            }
            catch (e) {

                if (e?.subMilestoneId == "SubMileStoneId cannot be null") {
                    const updatedSubMilestones = [...updatedMilestones[milestoneIndex].subMilestones];
                    updatedSubMilestones.splice(subMilestoneIndex, 1);

                    // Update the main milestones array with the modified subMilestones array
                    updatedMilestones[milestoneIndex].subMilestones = updatedSubMilestones;

                    setMilestones(updatedMilestones);
                }
                else {
                    toast.error(e)
                }

            }
        }
        else {
            toast.error("Minimum 1 Milestone Needed")
        }





    };


    return (
        <div className='row'>
            <div className='com_modal'>
                <div className='form_default'>
                    {/* Milestone input section */}
                    <div className='mb-3'>
                        <FormControl
                            control='heading'
                            title='MileStone & Sub-MileStone'
                        />
                    </div>
                    <div className='add_miltone_wrap'>
                        <div className='col-lg-6'>
                            <div className='position-relative'>
                                <input
                                    type='text'
                                    name='milestone'
                                    placeholder='Milestone'
                                    disabled={editingMilestoneIndex !== -1 || milestoneAdded}
                                    onChange={handleMilestoneChange}
                                    value={milestoneInput}
                                />


                                <div className='inside_btn '>
                                    {editingMilestoneIndex !== -1 ? (
                                        <div
                                            onClick={() => handleSaveMilestone(editingMilestoneIndex)}

                                        >
                                            <div className={'save_edit_btn cus_btn_wid'}>
                                                <div className='icon_text'>
                                                    <p>
                                                        Save
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div

                                            onClick={addMilestone}
                                        >
                                            <div className={'save_edit_btn cus_btn_wid'}>
                                                <div className='icon_text'>
                                                    <p>
                                                        Add
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>



                            </div>
                            {milestoneError && <div className='error'>{milestoneError}</div>}
                        </div>
                        <div className='col-lg-6'>
                            <div className='position-relative'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <input
                                        type='text'
                                        name='submilestone'
                                        placeholder='SubMilestone '
                                        disabled={milestones.length === 0 || editingSubMilestone.milestoneIndex !== -1}
                                        onChange={handleSubMilestoneChange}
                                        value={subMilestoneInput}
                                        className='mx-2'
                                    />
                                    <input
                                        type='text'
                                        name='subWeightage'
                                        placeholder='Weightage in %'
                                        className='weight_output'
                                        disabled={milestones.length === 0 || editingSubMilestone.milestoneIndex !== -1}
                                        onChange={handleSubMilestoneweightageChange}
                                        value={subWeightage}
                                    />
                                </div>
                                <div className='inside_btn' onClick={() => addSubMilestone(0)} // Always add to the first milestone if it exists
                                    disabled={milestones.length === 0 || editingSubMilestone.milestoneIndex !== -1}>
                                    <div className={'save_edit_btn cus_btn_wid'}>




                                        <div className='icon_text'>

                                            <p>
                                                Add
                                            </p>


                                        </div>
                                    </div>

                                </div>

                            </div>
                            {subMilestoneError && <div className='error'>{subMilestoneError}</div>}
                        </div>
                    </div>

                    {/* Sub Milestone input section */}


                    {/* Milestone and Sub Milestone table */}
                    <div>

                    </div>
                    {
                        milestones && milestones.length > 0
                        && <div className="custom_submile_table my-3 gray_backg mt-3">

                            {milestones.map((milestone, milestoneIndex) => (
                                <div key={milestoneIndex} className=' milstone_table_wrap'>
                                    <div>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div>
                                                <p className='mile_name_head'>
                                                    MileStone Name:
                                                </p>
                                            </div>
                                            {editingMilestoneIndex === milestoneIndex ? (
                                                // Editing Milestone
                                                <div>
                                                    <input
                                                        type='text'
                                                        value={milestoneInput}
                                                        onChange={handleMilestoneChange}
                                                    />

                                                    {milestoneError && <div className='error mr-1'>{milestoneError}</div>}
                                                </div>
                                            ) : (
                                                // Display Milestone
                                                <div><p className='mile_name_text'>
                                                    {milestone.mileStoneName}
                                                </p></div>
                                            )}
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        {editingMilestoneIndex === milestoneIndex ? (
                                            // If editing, show Save button
                                            <div
                                                className='mx-1'
                                                onClick={() => handleSaveMilestone(milestoneIndex)}
                                            >
                                                <div className={'save_edit_btn cus_btn_wid'}>




                                                    <div className='icon_text'>

                                                        <p>
                                                            Save
                                                        </p>


                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            // If not editing, show Edit div
                                            <div
                                                className='mx-1'
                                                onClick={() => handleEditMilestone(milestoneIndex)}
                                            >
                                                <div className={'save_edit_btn cus_btn_wid'}>




                                                    <div className='icon_text'>

                                                        <p>

                                                            Edit
                                                        </p>


                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* <div

                                            onClick={() => handleDeleteMilestone(milestoneIndex)}
                                        >
                                            <div className={'save_edit_btn cus_btn_wid'}>




                                                <div className='icon_text'>

                                                    <p>
                                                        Delete
                                                    </p>


                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            ))}

                        </div>

                    }



                    {/* Sub Milestone list */}
                    <div className='d-flex justify-content-center'>
                        <div>
                        </div>
                        <div className='custom_submile_table  mt-3'>
                            <table class="min-w-full bg-white border border-gray-300 rounded shadow-md">
                                <thead>
                                    <tr>
                                        <th class="fw-normal">SubMileStone Name</th>
                                        <th class="fw-normal">SubMileStone Weightage</th>
                                        <th class="fw-normal">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {milestones[0]?.subMilestones?.length > 0 && milestones.map((milestone, milestoneIndex) => (
                                        <tr key={milestoneIndex}>
                                            <td class="py-1 px-1">
                                                {milestone.subMilestones.map((subMilestone, subMilestoneIndex) => (
                                                    <div key={subMilestoneIndex} class="sub_milstone_table_wrap">
                                                        {editingSubMilestone.milestoneIndex === milestoneIndex &&
                                                            editingSubMilestone.subMilestoneIndex === subMilestoneIndex ? (
                                                            // Editing Sub Milestone
                                                            <div class="mx-2">
                                                                <input
                                                                    type="text"
                                                                    value={subMilestoneInput}
                                                                    onChange={handleSubMilestoneChange}
                                                                    class="border p-1"
                                                                />
                                                                {subMilestoneError && <div class="error mr-1">{subMilestoneError}</div>}
                                                            </div>
                                                        ) : (
                                                            // Display Sub Milestone
                                                            <div><p class="submile_name">{subMilestone.subMilestoneName}</p></div>
                                                        )}
                                                    </div>
                                                ))}
                                            </td>
                                            <td class="py-1 px-1">
                                                {milestone.subMilestones.map((subMilestone, subMilestoneIndex) => (
                                                    <div key={subMilestoneIndex} class="sub_milstone_table_wrap">
                                                        {editingSubMilestone.milestoneIndex === milestoneIndex &&
                                                            editingSubMilestone.subMilestoneIndex === subMilestoneIndex ? (
                                                            // Editing Sub Milestone
                                                            <div class="mx-2">
                                                                <input
                                                                    type="text"
                                                                    value={subWeightage}
                                                                    onChange={handleSubMilestoneweightageChange}
                                                                    class="border p-1"
                                                                />
                                                                {subMilestoneError && <div class="error mr-1">{subMilestoneError}</div>}
                                                            </div>
                                                        ) : (
                                                            // Display Sub Milestone
                                                            <div><p class="sub_weightage">{subMilestone.subWeightage}</p></div>
                                                        )}
                                                    </div>
                                                ))}
                                            </td>
                                            <td class="py-2 px-4 border-b">
                                                {milestone.subMilestones.map((subMilestone, subMilestoneIndex) => (
                                                    <div key={subMilestoneIndex} class="sub_milstone_table_wrap">
                                                        {editingSubMilestone.subMilestoneIndex === subMilestoneIndex ? (
                                                            // Editing mode
                                                            <div class="table_icon mx-2 cursor-pointer" onClick={() => handleSaveSubMilestone(milestoneIndex, subMilestoneIndex)}>
                                                                &#10004; {/* Checkmark symbol (✓) */}
                                                            </div>
                                                        ) : (
                                                            // Display mode
                                                            <div class="table_icon mx-2 cursor-pointer" onClick={() => handleEditSubMilestone(milestoneIndex, subMilestoneIndex)}>
                                                                <img src={Edit} alt="Edit Icon" />
                                                            </div>
                                                        )}
                                           
                                                        {
                                                            !subMilestone.hasConversation &&   <div class="table_icon cursor-pointer" onClick={() => handleDeleteSubMilestone(milestoneIndex, subMilestoneIndex)}>
                                                                <img src={Delete} alt="Delete Icon" />
                                                            </div> 
                                                        }
                                                       
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div onClick={handle} className='mt-5'>
                        <Button type='button' text='Save ' />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default EditMilestone;
