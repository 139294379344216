import React from "react";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { BiSolidMinusCircle } from 'react-icons/bi';
import Delete from "../../../../../Assets/images/Icons/delete.png"
import FormControl from "../../FormControl";


export default function DynamicTable2(props) {
    const { columns, rowdata, isDyanmic, className } = props;

    const linksGroup = rowdata;
    const result = linksGroup.map((obj) => ({
        name: obj.name,
        value: obj.value,
        type: obj.type,
    }));

    const schema = Yup.object().shape({
        links: Yup.array().of(
            Yup.object().shape({
                file_name: Yup.string().required("Link Name is required"),
                url_website: Yup.string().required("Link URL is required"),
            })
        ),
    });

    const handleSubmit = (values) => {
        // Define your submission logic here

    };

    return (
        <Formik
            initialValues={{
                links: [linksGroup],
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ values }) => (
                <div className="custom_form">


                    <FieldArray name="links">
                        {({ push, remove }) => (
                            <>
                                {values.links.map((data, index) => {

                                    {
                                        return (
                                            data.map((res, i) => {

                                                return (
                                                    <div key={index} className="custom_form_content">
                                                        {res.map((val, i) => {

                                                            return (
                                                                <div className="field_width">
                                                                    <FormControl
                                                                        control={`${val.type}`}
                                                                        value={`${val.value}`}
                                                                        name={`links.${index}.${val.name}`}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                        {isDyanmic && (

                                                            <span
                                                                className="tbl_btn"
                                                                style={{ width: index > 0 ? '30px' : "30px" }}
                                                                onClick={() => remove(index)}
                                                            >
                                                                <img src={`${index > 0 && Delete}`} alt="" />
                                                            </span>

                                                        )}

                                                    </div>
                                                )
                                            }
                                            )
                                        )

                                    }

                                })}


                                {
                                    isDyanmic && <div className="d-flex justify-content-end align-items-center m-2">
                                        <div
                                            className="tbl_btn ml-5"
                                            onClick={() => {
                                                push(linksGroup);
                                            }}>
                                            <h6>
                                                Add
                                            </h6>
                                        </div>
                                    </div>

                                }


                            </>
                        )}
                    </FieldArray>


                </div>
            )}
        </Formik>
    );
}
