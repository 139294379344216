import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import FormControl from '../../Controls/FormControl';
import { PURCHASETASKAPi, ServiceALLGet, UPDATECUSTOMERAPi } from '../../../../Utils/services';
import Button from "../../Buttons/Navigate";
import * as Yup from 'yup';
import Loader from '../../Loader';
import { toast } from 'react-toastify';

function AssignProjects(props) {
    const { closeModal, id, data, reload } = props;
    const [allData, setAllData] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await ServiceALLGet();
            if (result) {
                const datas = result?.res?.data?.map((item) => ({
                    id: item?.serviceId,
                    label: item?.serviceName,
                    value: item?.serviceName,
                }));
                setOptions(datas);
                setAllData(result.res.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };

    const handleSubmit = async (values) => {
        if (values.formData.taskIds) {
            const data = {
                "taskIds": [values.formData.taskIds],
                "companyId": id
            };
            try {
                setLoading(true);
                const result = await PURCHASETASKAPi(data);
                if (result?.res?.status === true) {
                    setLoading(false);
                    toast.success(result?.res?.message);
                    reload()
                    closeModal()
                }
                else {
                    setLoading(false);
                    closeModal()
                    toast.error(result?.res?.message)
                }

            }
            catch (err) {
                setLoading(false);
                closeModal()
                toast.error(err || err.message)
            }

        }
        else {
            toast.error('Please choose at least one service')
        }

    };
    return (
        <div>
            <div className='row'>
                <div className='com_modal'>
                    <Loader loading={loading}/>
                    <Formik
                        initialValues={{
                            formData: {  // Replace editdata with data or appropriate source
                                taskIds: "",
                            },
                        }}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form className="form_default">
                                <div className='mb-4 text-center'>
                                    <FormControl control='heading' title='Assign Project' />
                                </div>

                                <div className='mb-5'>
                                    <FormControl
                                        control='SelectBox'
                                        placeholder='Choose a Service'
                                        name='formData.taskIds'
                                        options={options}

                                    />
                                </div>
                                <Button type='submit' text='Upload' disabled={formik.isSubmitting} />
                            </Form>

                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AssignProjects;
