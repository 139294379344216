import React, { useEffect, useState } from 'react';
import { PROJECTONEAPi, MileStatus, PROJECTCOMMENTAPi } from '../../../../Utils/services';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function UpdateMilestone(props) {
    const { closeModal, value, value2, Parent } = props;
    const [loading, setLoading] = useState(false);
    const [milestoneInput, setMilestoneInput] = useState({ index: "", comment: "", id: "" })

    const handleSubmit = async () => {
        const data = {
            "projectMilestoneId": value2,
            "projectSubMilestoneId": value?.projectSubmilestoneId,
            "projectId": Parent,
            "commentText": milestoneInput.comment
        };
        try {
            const result = await PROJECTCOMMENTAPi(data);
            if (result.res.status === true) {
                toast.success(result?.res.message);
                closeModal()

            }
        } catch (error) {
            console.error("Error submitting data:", error.message);
        }
    }

    return (
        <div className='update_mile p-2'>
            <div className='row mt-3'>
                <div className='col-lg-12'>
                    {
                        <div className='mb-3'>
                            <h6>Add  Comment :</h6>
                        </div>
                    }


                </div>

                <div className='col-lg-12 col-md-12'>


                    <div className=''>
                        <div className='d-flex align-items-center justify-content-between comment_wrap'>
                            <div className='col-lg-9 col-md-6'>
                                <div className='d-flex justify-content-between flex-column'>
                                    <div className='mt-1'>
                                        <textarea
                                            rows="5"
                                            className='w-100'
                                            onChange={(e) => setMilestoneInput({ ...milestoneInput, comment: e.target.value })}
                                            value={milestoneInput.comment}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-4'>
                                <div>
                                    <div>
                                        <button className='default_btn w-100' onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                    <div>
                                        <button className='default_btn w-100' onClick={() => closeModal()}>
                                            Cancel  
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default UpdateMilestone;
