import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ModalContext } from '../../../../Utils/Context';
import { ServiceALLGet, DocumentAdd } from '../../../../Utils/services';
import Loader from '../../Loader';
import FormikControl from "../../Controls/FormControl";
import Button from "../../Buttons/Navigate";

function UploadDocument({ editdata, closeModal, Reload }) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {

            try {
                setLoading(true);
                const result = await ServiceALLGet();
           
                if (result?.res?.status === true) {
                    setLoading(false);
                    const datas = result?.res?.data?.map((item) => ({
                        id: item?.serviceId,
                        label: item?.serviceName,
                        value: item?.serviceName,
                    }));
                    setOptions(datas);
                }
            } catch (error) {
                // Fallback to test data
                setLoading(false);

            }

        };
        fetchData();
    }, []);

    const handle = async (values) => {

        const filteredOptions = options.filter((res) => res?.id === values.formData?.taskId);
        const data = new FormData();
        data.append("documentfiles", values.formData.documentfiles[0]);
        data.append("taskId", filteredOptions[0]?.id);
        data.append("isCommon", filteredOptions[0]?.id === '38f934ca-4b11-11ee-be56-0242ac120002' ? true : false);
        if (values.formData.documentfiles && values.formData.documentfiles[0]) {
            try {
                setLoading(true);
                const result = await DocumentAdd(data);
                if (result?.res.status) {
                    setLoading(false);
                    closeModal();

                    Reload && Reload();
                    toast.success(result?.res?.message || "Document Upload successful");
                }
            } catch (error) {
                setLoading(false);
                toast.error(error.message || "An error occurred");
                closeModal();

            }
        }
        else {
            toast.error('File is Required')
        }

    }

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            taskId: Yup.string().required("Service is required"),
            documentfiles: Yup.mixed().required("Document is required"),
        }),
    });

    return (
        <div className='row'>
            <Formik
                initialValues={{
                    formData: editdata?.employeeName ? editdata : {
                        documentfiles: [],
                        taskId: '',
                    },
                }}
                onSubmit={handle}
                validationSchema={schema}
            >
                {formik => (
                    <Form className="form_default">
                        <div className='mb-4'>
                            <FormikControl
                                control='heading'
                                title='Upload Document'
                            />
                            <Loader loading={loading} />
                        </div>
                        <div className='mb-4'>
                            <FormikControl
                                control='SelectBox'
                                placeholder='Choose a Service'
                                name='formData.taskId'
                                options={options}
                                prevalue={formik.values.formData.taskId}
                            />
                        </div>
                        <div className='mb-4'>
                            <FormikControl
                                control="UploadFile" // Use DropZone component directly
                                name='formData.documentfiles'
                                maxFileSize={5}
                                allowedExtensions={['xlsx', 'docx', 'pdf', 'pptx', 'csv', 'txt']}
                                setField={formik.setFieldValue} // Use setFieldValue
                            />
                        </div>
                        <Button type='submit' text='Upload' disabled={formik.isSubmitting} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default UploadDocument;
