import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendarEvent } from "react-icons/bi";

function DatePickerComponent(props) {
    const { startDate, setStartDate, endDate, setEndDate, minDate } = props


    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 date_input">
                {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                    customInput={<CustomInput icon={<BiCalendarEvent />} />}
                /> */}
                <DatePicker
                    selectsStart
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    format="dd-MM-yyyy"
                    minDate={minDate}
                    customInput={<CustomInput icon={<BiCalendarEvent />} />}

                />
            </div>
            <div className="col-lg-6 col-md-6 date_input">
                {/* <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select end date"
                    minDate={startDate}
                    customInput={<CustomInput icon={<BiCalendarEvent />} />}
                /> */}
                <DatePicker
                    selectsEnd
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    endDate={endDate}
                    startDate={startDate}
                    minDate={startDate}
                    format="dd-MM-yyyy"
                    customInput={<CustomInput icon={<BiCalendarEvent />} />}
                />
            </div>
        </div>
    );
}

const CustomInput = ({ icon, placeholder, ...restProps }) => (
    <div style={{ position: "relative" }}>
        <input {...restProps} className="form-control" placeholder={placeholder} />
        <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
            {icon}
        </div>
    </div>
);

export default DatePickerComponent;
