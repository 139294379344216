import React from 'react'

function View({ text, type, disabled, source }) {
    return (
        <div>
           <div className={`${source ? 'view_btn' : 'save_edit_btn'}`}>
                {
                    source ? <div className='view_btn_img'>
                        <img src={source} />

                    </div> : ""
                }


                <div className='icon_text'>

                    <p>
                        {text}
                    </p>


                </div>
            </div>
        </div>
    )
}

export default View