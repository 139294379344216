import React, { useContext, useEffect, useState } from 'react';
import FormikControl from "../../Controls/FormControl"
import Button from "../../Buttons/Navigate";
import { toast } from 'react-toastify';
import Loader from "../../../Common/Loader"
import { POSTSCANSAPi, RaciCreate } from "../../../../Utils/services";
import { ModalContext } from '../../../../Utils/Context';
import { Formik, Form } from 'formik';

import * as Yup from 'yup'
import AuthContext from '../../../../Utils/auth-context';

function AddScan({ closeModal, reload }) {
    const [options, setOptions] = useState([]);
    const [data, setdata] = useState()
    const [loading, setLoading] = useState(false);
    const allData = {
        email: "",
        url: "",
    };
    const authContext = useContext(AuthContext);
    const Users = JSON.parse(authContext?.user)
    const handle = async (values) => {
        if (values.formData) {
            const data = {
                "url": values.formData.url,
                "email": values.formData.email

            };

            setLoading(true);
            try {
                const result = await POSTSCANSAPi(data);
                
                if (result?.res?.status == true) {
                    closeModal()
                    setLoading(false);
                    toast.success(result?.res?.message)
                    reload()

                }

            } catch (error) {
                closeModal()
                toast.error(error.message);
                setLoading(false);
            }

        }
       

    }

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            url: Yup.string().url("Invalid URL format")  // Add URL validation
                .required("Url is required"),
            email: Yup.string().email("Invalid email format").required("Email is required"),
        }),
    });
    const runnableScanTypes = [
        { value: "NMAP", label: "NMAP", id: "NMAP" },
        { value: "OPENVAS", label: "OPENVAS", id: "OPENVAS" },
        { value: "SSLYZE", label: "SSLYZE", id: "SSLYZE" },
        { value: "NMAP_UDP", label: "NMAP_UDP", id: "NMAP_UDP" },
        { value: "OWASP_ZAP", label: "OWASP_ZAP", id: "OWASP_ZAP" },
        { value: "OWASP_ZAP_ACTIVE", label: "OWASP_ZAP_ACTIVE", id: "OWASP_ZAP_ACTIVE" }

    ];

    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: allData,
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {formik => (
                        <Form className="form_default">
                            <div className='mb-5'>
                                <FormikControl
                                    control='heading'
                                    title='Add Scan'
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='d-flex  align-items-center justify-content-between flex-wrap'>
                                    <div className='col-lg-12 mb-2'>

                                        <FormikControl
                                            name='formData.url'
                                            placeholder='Add Scan'
                                            value={formik?.values?.formData?.url}
                                            control='Input'
                                            type="url"


                                        />
                                    </div>

                                    <div className='col-lg-12 mb-5'>
                                        <FormikControl
                                            control='Input'
                                            placeholder='Add Email'
                                            name='formData.email'
                                            type="email"
                                            value={formik.values.formData?.email}
                                        />
                                    </div>

                                </div>
                            </div>

                            <Button type='submit' text='Scan' disabled={formik.isSubmitting} />
                            <Loader loading={loading} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AddScan;
