import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../Components/Common/Heading/Title';
import SubTitle from '../../Components/Common/Heading/SubTitle';
import Navigate from '../../Components/Common/Buttons/Navigate';
import Employee from '../../Components/DummyJsons/employee.json';
import { Form, Formik } from 'formik';
import DefaultTable from "../../../src/Components/Common/Tables/DefaultTable"
// import PhoneInput from 'react-phone-number-input'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

// import { getCountryCallingCode } from 'react-phone-number-input';
// import en from 'react-phone-number-input/locale/en.json';
import Delete from "../../../src/Assets/images/Icons/delete.png"
import FormControl from "../../Components/Common/Controls/FormControl"
import Default from "../../Components/Common/Buttons/Default"
import { ModalContext } from '../../Utils/Context';
import UploadLink from '../../Components/Common/Modals/UploadLinkManage';
import Chat from '../../Components/Common/Modals/Chat';
import FileSaver, { saveAs } from 'file-saver';
import FileNotification from '../../Components/Common/Notifications/FileNotification';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Components/Common/Loader';
import { DocsDelete, EmployeeGetAll, LinkDeleteAPi, PROJECTONEAPi, PROJECTUPDATEAPi } from '../../Utils/services';
import { styled } from '@mui/material/styles'; 
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import View from '../../../src/Components/Common/Buttons/View';
import EmployeeAccess from './EmployeeAccess';
import AddComment from "../../Components/Common/Modals/AddComment"
import { toast } from 'react-toastify';
import AuthContext from '../../Utils/auth-context';
import { useAbility } from '../../Utils/AbilityContext';
import Select from 'react-select';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `2px solid #292728`,
    '&:before': {
        display: 'none',
    },
    borderRadius: '8px',
    margin: 10,
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary  {...props} />

))(({ theme }) => ({
    border: `5px solid #292728`,
    borderRadius: '10px',
    borderColor: 'rgba(255, 255, 255, .05)',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginRight: theme.spacing(5),
    },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),

}));

function ManageProjects() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false);
    const [alldata, setalldata] = useState({});
    const [linkdata, setLinkdata] = useState();
    const [docdata, setdocdata] = useState();
    const [milstone, setMilestone] = useState([])
    const [FieldValue, setFieldValue] = useState([]);
    const [options, setOptions] = useState([]);
    const [matData, setmatData] = useState([]);
    const [chatData, setChatData] = useState();
    const [subMilestoneInput, setSubMilestoneInput] = useState('');
    const [editingMilestoneIndex, setEditingMilestoneIndex] = useState(-1);
    const [editingSubMilestone, setEditingSubMilestone] = useState({
        milestoneIndex: -1,
        subMilestoneIndex: -1,
    });
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [chatOpen, setChatOpen] = useState(false)
    const [milestoneError, setMilestoneError] = useState('');
    const [subMilestoneError, setSubMilestoneError] = useState('');
    const { id, completed } = useParams();
    const [expanded, setExpanded] = useState(new Array(milstone.length).fill(false));
    const toggleAccordion = (milestoneIndex) => {
        const newExpanded = [...expanded];
        newExpanded[milestoneIndex] = !newExpanded[milestoneIndex];
        setExpanded(newExpanded);
    };
    const { isAdmin, permission } = useContext(AuthContext);
    const ability = useAbility();
    const navigate = useNavigate();
    const isCompletedExist = Boolean(completed);
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await PROJECTONEAPi(id);
            setalldata(result?.res?.data);
            setdocdata(result?.res?.data?.documentList);
            setLinkdata(result?.res?.data?.linkList)
            setMilestone(result?.res?.data?.projectMilestoneList)
            setmatData(result?.res?.data?.projectRACIMatrixList)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
        try {
            setLoading(true)
            const result = await EmployeeGetAll();

            if (result && result?.res?.status === true) { 
                console.log(result?.res?.data,"result?.res?.data")
                const datas = result?.res?.data?.map((item) => ({ 
                    id: item?.employeeId,
                    label: item?.employeeName,
                    value: item?.employeeName,
                    number:item?.employeeNumber,
                    email:item?.employeeEmail,
                    numberCode: item?.employeeNumberCode
                    
                } ));
                
                setOptions(datas)
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



const getCountryCodeFromCallingCode = (callingCode) => {  
    const callingCodeToCountryCode = {
        '91': 'IN',
        '1': 'US',
        
    };

    if (callingCode) {
        return callingCodeToCountryCode[callingCode.toString()] || 'IN'; 
    }
    return 'IN'; 
};



    const FeedsColumns = useMemo(
        () => [
            {
                Header: 'Folder',
                accessor: data => (
                    <span className='text-sm'>{data?.isProject === true ? 'Project Link ' : 'Service Link '}</span>
                ),
            },
            {
                Header: 'Title',
                accessor: data => (
                    <span className='text-sm'>{data.linkName}</span>
                ),
            },
            {
                Header: 'URL',
                accessor: data => (
                    <span className='cursor-pointer'>
                        <u>{data.linkUrl}</u>
                    </span>
                ),
            },
            {
                Header: 'Actions',
                accessor: data => {
                    return (!isCompletedExist && isDelete) && data?.isProject === true ? (
                        <span
                            className='d-flex justify-content-center cursor-pointer'
                            onClick={() => hadleDelete(data)}
                        >
                            <div className='delete_icon'>
                                <img src={Delete} alt='Delete' />
                            </div>
                        </span>
                    ) : null; // Null or an alternative component if needed
                },
            },
        ],
        []
    );


    const handleComment = (value, value2) => {

        if (value) {

            const Modal = <AddComment value={value} value2={value2} Parent={id} closeModal={closeModal} />;
            handleModalData(Modal, "lg");
        }
    }

    const handleDocsDeleteApi = async (val) => {


        if (val
            && isDelete) {
            try {
                const result = await DocsDelete(val?.documentId);
                if (result.res.status === true) {
                    toast.success(result.res.message);
                    fetchData();
                    closeModal();
                }

            }
            catch (error) {
                toast.error(error?.message || error)
            }



        }


    }




    const handleSubMilestoneChange = (event, milestoneIndex, subMilestoneIndex, value, key) => {


        const updatedData = [...milstone];
        const propertyName = value;

        switch (propertyName) {
            case 'employeeSelection':

                // Extract the selected option values and store them in your data structure
                const updatedDataCopy = [...updatedData];

                // Extract the selected employee IDs from selectedOptions
                const selectedEmployeeIds = event.map((option) => option.id);

                // Update the employeeList property in the copied data

                updatedDataCopy[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex].employeeList = selectedEmployeeIds;

                // Update your state or data with the updatedDataCopy
                // For example, if you're using React and 'setData' to update your state:

                setMilestone(updatedDataCopy);
                break;
            case 'duration':
                updatedData[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex].totalWorkingHours = event.target.value;
                break;
            // Add more cases for other properties if needed
            default:
                break;
        }

        setMilestone(updatedData);
    };
    const handleInputChange = (property, e, index) => { 
        const updatedData = [...milstone];
        switch (property) {

            case 'weightage':
                updatedData[index].weightage = +e.target.value;
                break;
            case 'duration':
                updatedData[index].duration = +e.target.value;
                break;
            default:
                break;
        }
        setMilestone(updatedData);
    };

    const handleEditMilestone = (milestoneIndex) => {
        const updatedData = [...milstone];
        setEditingMilestoneIndex(milestoneIndex);


    }

    const handleSaveMilestone = (milestoneIndex) => {

        setEditingMilestoneIndex(-1);

    };



    const handleEditSubMilestone = (milestoneIndex, subMilestoneIndex) => {

        const updatedalldata = [...milstone];
        const subMilestoneToEdit = updatedalldata[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex];
        subMilestoneToEdit.isEditing = true;
        // Set the input value to the current sub-milestone's subMilestoneName
        subMilestoneToEdit.inputValue = subMilestoneToEdit.projectSubmilestoneName;
        setEditingSubMilestone({ milestoneIndex, subMilestoneIndex });
        setSubMilestoneInput(
            updatedalldata[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex].inputValue
        );
        setMilestone(updatedalldata);
        setEditingSubMilestone(-1);
    };

    const handleSaveSubMilestone = (milestoneIndex, subMilestoneIndex) => {  
        const updatedalldata = [...milstone];
        const subMilestoneToSave =
            updatedalldata[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex];
        // subMilestoneToSave.isEditing = false;
        setMilestone(updatedalldata);
        setEditingSubMilestone({ milestoneIndex: -1, subMilestoneIndex: -1 });
        setSubMilestoneError('');

    };

    const handleDeleteSubMilestone = async (milestoneIndex, subMilestoneIndex) => {

        setEditingSubMilestone({
            milestoneIndex: -1,
            subMilestoneIndex: -1,
        });
        const updatedalldata = [...milstone];
        const subMilestoneToEdit = updatedalldata[milestoneIndex].projectSubMilestoneDtoList[subMilestoneIndex];
        subMilestoneToEdit.isEditing = false;

    };




    const handleNameChange = (index, value) => {
        if (matData[index]) {
            const newMatData = [...matData];
            newMatData[index].contactName = value;
            setmatData(newMatData);
        }

        // setmatData(newMatData);
    };
    const handleEmailChange = (index, value) => {
        if (matData[index]) {
            const newMatData = [...matData];
            newMatData[index].raciEmail = value?.toLowerCase();
            setmatData(newMatData);
        }
    }
    const handleContactChange = (index, value) => {
        if (matData[index]) {
            const newMatData = [...matData];
            newMatData[index].phoneNumber = value;
            setmatData(newMatData);
        }
    };
    const handleDownload = (data1, data2) => {

        FileSaver.saveAs(data2, data1);
    }
    const hadleDelete = async (values) => {

        if (values) {
            const result = await LinkDeleteAPi(values?.linkId);
            if (result.res.status === true) {
                toast.success(result.res.message);
                fetchData();
                closeModal();
            }
        }
    }





    const handle = (values) => {


        const updatedData = milstone?.map((item) => {
            if (!item || !item.projectSubMilestoneDtoList) {
                // Handle missing data gracefully
                return item;
            }
            // Clone the original item, removing the "status" and "projectSubmilestoneName"
            const { status, projectMilestoneName, projectSubMilestoneDtoList, ...updatedItem } = item;

            const updatedSubMilestones = projectSubMilestoneDtoList.map((subMilestone) => {
                // Replace "duration" with "dailyWorkingHours" in subMilestone
                const { duration, totalWorkingHours, status, projectSubmilestoneName, isEditing, inputValue, weightage, employeeList, ...rest } = subMilestone;
                return { ...rest, dailyWorkingHours: +totalWorkingHours, employeeIds: employeeList };
            });

            // Update the "subMileStoneToUpdateList" with the processed "updatedSubMilestones"
            updatedItem.subMileStoneToUpdateList = updatedSubMilestones;
            return updatedItem;
        });
        const updatedmatData = matData?.map((item) => {
            if (!item || !item.stakeholder) {
                // Handle missing data gracefully
                return item;
            }
            // Clone the original item, removing the "status" and "projectSubmilestoneName"
            const { stakeholder, raciA, raciC, raciI, raciR, ...updatedItem } = item;

            return updatedItem;
        });



        const data =
        {
            "projectId": id,
            "mileStoneUpdateDtoList": updatedData,
            "projectRACIMatrixList": updatedmatData,
        }
        const allSubmitData = new FormData();
        if (values.formData.image) {
            allSubmitData.append("documents", values.formData.image[0]);
        }
        else {
            allSubmitData.append("documents", '');
        }
        allSubmitData.append("projectUpdateRequest", JSON.stringify(data));

        ApiHnadle(allSubmitData)
    }
    const ApiHnadle = async (data) => {
        try {
            setLoading(true)
            const result = await PROJECTUPDATEAPi(data);

            if (result.res) {
                toast.success(result.res.message);
                setLoading(false)
                fetchData()
            }
            else {
                toast.error(result.message);
                setLoading(false)
            }

        }
        catch (err) {
            toast.error(err)
        }
    }


    const hanldeAdd = () => {

        handleModalData(<UploadLink closeModal={closeModal} Reload={fetchData} ApiHnadle={ApiHnadle} projectId={id} />, "md")
    }
    let hide = {
        display: 'none'
    }
    let show = {
        display: 'block'
    }

    const updateStataus = (data) => {
        setChatOpen(true)
        setChatData(data)
    }

    const isEdit = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 1 && a?.action == 'edit');
    const isCreate = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 1 && a?.action == 'create');
    const isDelete = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 1 && a?.action == 'delete');
    const istableEdit = ability?.A?.some((a) => a?.subject === 1 && a?.action == 'edit')
    // const handleChangeEmployee = (index, selectedOption) => {
    //     if (matData[index]) {
    //         const newMatData = [...matData];
    //         newMatData[index].contactName = selectedOption ? selectedOption.value : '';
    //         setmatData(newMatData);
    //     }
    // };

    const handleChangeEmployee = (index, selectedOption) => {
        if (matData[index]) {
            const newMatData = [...matData];
            newMatData[index].contactName = selectedOption ? selectedOption.value : '';
            newMatData[index].raciEmail = selectedOption ? selectedOption.email : '';
            newMatData[index].phoneNumber = selectedOption ? selectedOption.number : '';
            newMatData[index].countryCode = selectedOption ? selectedOption.numberCode : ''; // Correctly set the phone number code
            setmatData(newMatData);
        }
    };

    console.log(matData,"matData")
    console.log(options,"options")
    return (
        <div className="container-fluid pb-5">
            <div className="row">
                <div className='manage_project'>
                    <div className='col-lg-7'>  
                        <Title title="Manage Project" />
                    </div>
                    <div className='col-lg-2'>
                        <div className='d-flex justify-content-between align-items-center'>

                            <div>
                                {

                                    !isCompletedExist && <Link to={`/companyprofile/${alldata?.companyId}`}>
                                        <Navigate text="View Profile" />
                                    </Link>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alldata && alldata.taskName && (
                <Formik
                    initialValues={{
                        formData: {
                            industry: alldata.industry || "",
                            companySize: alldata.companySize || "",
                            location: alldata.location || "",

                        },
                    }}
                    onSubmit={handle}
                >

                    {formik => (
                        <Form>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className='manage_service_name'>
                                        <div className='col-lg-5 col-md-5'>
                                            <SubTitle title={alldata?.companyName} />
                                        </div>
                                        <div className='col-lg-5 col-md-5 manage_taskName'>
                                            <SubTitle title={alldata?.taskName} />
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <Loader loading={loading} />
                            <div className='row'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div className='col-lg-7'>
                                        <SubTitle title="About Company" />
                                        <p className='clik_text'>
                                            Click on 'View Profile' for more information
                                        </p>
                                        <div className='mt-3'>

                                            <div className='form_default'>
                                                <div className='row'>
                                                    <div className='d-flex justify-content-between align-items-center col-lg-9'>
                                                        <div className='col-lg-5 manage_input'>

                                                            <FormControl
                                                                type="text" placeholder="Enter Industry" control="Input" name='formData.industry'
                                                                value={formik.values.formData?.industry}
                                                                disabled
                                                            />

                                                        </div>
                                                        <div className='col-lg-5 manage_input'>
                                                            <FormControl
                                                                name='formData.companySize'
                                                                value={formik.values.formData?.companySize} type="text" placeholder="Company Size" control="Input"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-10 mt-3'>
                                                        <FormControl
                                                            name='formData.location'
                                                            disabled
                                                            value={formik.values.formData?.location} type="text" placeholder="Location" control="Input"
                                                        />
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-5'>
                                    <SubTitle title="Upload Files" />
                                    <div className='mt-2  d-flex justify-content-between align-items-start upload_wrap'>
                                        <div className='col-lg-5 col-md-3'>


                                            <div className='form_default'>
                                                <FormControl
                                                    control='UploadFile'
                                                    placeholder=''
                                                    maxFileSize={1}
                                                    allowedExtensions={['xlsx', 'docx', 'pdf', 'pptx', 'csv', 'txt']}
                                                    name='formData.image'
                                                    setField={setFieldValue}
                                                />
                                            </div>



                                        </div>

                                        <div className='col-lg-6 col-md-7 file_tile'>
                                            <FileNotification icons={2} data={docdata} handleDownload={handleDownload} hadleDelete={handleDocsDeleteApi} />
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <Loader loading={loading} />
                            <div className='row'>
                                <div className='col-lg-12 mt-5 text_left'>
                                    <SubTitle title="Employee Access" />
                                    <p className='clik_text'>
                                        Select Employee to grant Access
                                    </p>
                                    <div className="mt-3 custom_table w-100 my-3 project_mile proj_mile_wrap">
                                        {milstone?.map((milestone, milestoneIndex) => (
                                            <Accordion key={milestoneIndex} expanded={expanded[milestoneIndex]}>
                                                <AccordionSummary
                                                    aria-controls={`panel${milestoneIndex}-content`}
                                                    id={`panel${milestoneIndex}-header`}

                                                    expandIcon={
                                                        <ArrowForwardIosSharpIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => toggleAccordion(milestoneIndex)} />
                                                    }
                                                    sx={{ cursor: 'unset !important' }}
                                                >
                                                    {editingMilestoneIndex === milestoneIndex ? (
                                                        <div className='d-flex justify-content-between align-items-center proj_mile_wrap w-100'>
                                                            <div className='col-lg-3'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <span>
                                                                        {milestoneIndex + 1}
                                                                    </span>
                                                                    <p>
                                                                        {milestone.projectMilestoneName}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <input
                                                                    type='text'
                                                                    value={milestone.duration}
                                                                    // defaultValue={milestone.duration}
                                                                    placeholder='Enter Duration'
                                                                    onChange={(e) => handleInputChange("duration", e, milestoneIndex)}
                                                                />
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <input
                                                                    type='text'
                                                                    // value={milestone.weightage}
                                                                    disabled
                                                                    value={100}
                                                                    onChange={(e) => handleInputChange("weightage", e, milestoneIndex)}
                                                                />
                                                            </div>



                                                            <div className=' d-flex justify-content-between align-items-center'>
                                                                <div onClick={() => handleSaveMilestone(milestoneIndex)} className='mx-2'>
                                                                    {
                                                                        (!isCompletedExist && isEdit) && <View text="
                                                                Save" />
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                        
                                                    ) : (<div className='d-flex justify-content-between align-items-center proj_mile_wrap w-100'>
                                                        {/* <div className="col-lg-9"> */}
                                                            <div className='d-flex justify-content-between align-items-center proj_mile_wrap w-100'>
                                                                <div className='display-block-width-400'>
                                                                    <div className='d-flex  align-items-center'>
                                                                        <span className=''>
                                                                            {milestoneIndex + 1}
                                                                        </span>
                                                                        <p className='left'>
                                                                            {milestone.projectMilestoneName}
                                                                        </p>
                                                                    </div>


                                                                </div>
                                                                {/* <div className='display-block-width-200'>

                                                                    <div className='d-flex justify-content-center align-items-center disabled'>
                                                                        <p className='mx-1'>
                                                                            Duration :
                                                                        </p>
                                                                        <p>
                                                                            {milestone?.duration} <span>Weeks</span>
                                                                        </p>
                                                                    </div>

                                                                </div> */}
                                                                <div className='display-block-width-200'>



                                                                    <div className='d-flex justify-content-center align-items-center disabled'>
                                                                        <p className='mx-1'>
                                                                            Weightage :
                                                                        </p>
                                                                        <p>
                                                                        {milestone?.weightage}<span>%</span>
                                                                        </p>
                                                                    </div>



                                                                </div>
                                                                <div className='display-block-widthedit-200'>
                                                                <div className='d-flex justify-content-center align-items-center color_text'>

                                                                    <p>
                                                                        {milestone?.projectSubMilestoneDtoList?.length}
                                                                    </p>
                                                                    <span>
                                                                        Task
                                                                    </span>
                                                                </div>
                                                                </div>
                                                                <div className="display-block-widthedit-200">   
                                                                    <div className=' d-flex justify-content-between align-items-center'>  
                                                                        <div className='mx-2' onClick={() => handleEditMilestone(milestoneIndex)}>
                                                            
                                                                             {/* {
                                                                               (!isCompletedExist && isEdit) && <View text="Edit" />
                                                                              } */}
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                            </div>
                                                        {/* </div> */}




                                                      
                                                    </div>)}




                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <EmployeeAccess milestone={milestone} // Pass the milestone object itself
                                                            data={alldata} // Pass the subMilestones of the milestone
                                                            Employee={options}
                                                            milestoneIndex={milestoneIndex}
                                                            handleSaveSubMilestone={handleSaveSubMilestone}
                                                            handleSubMilestoneChange={handleSubMilestoneChange}
                                                            handleEditSubMilestone={handleEditSubMilestone}
                                                            handleDeleteSubMilestone={handleDeleteSubMilestone}
                                                            updateStataus={updateStataus}
                                                            isEditDisabled={(!isCompletedExist && isEdit)}
                                                            iscreateDisabled={(!isCompletedExist && isCreate)}
                                                            handleComment={handleComment} />
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}

                                    </div>



                                </div>
                            </div>

                            <div className='row mt-5'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=''>
                                        <SubTitle title="Link List" />
                                        <p className='clik_text'>
                                            Add links which are useful to this project
                                        </p>
                                    </div>
                                    <div onClick={() => hanldeAdd()}>
                                        {
                                            (!isCompletedExist && isCreate) && <Default text="Add" type="button" />
                                        }

                                    </div>

                                </div>

                                <div className=" project_table text_left  my-3">
                                    {linkdata && <DefaultTable data={linkdata} columns={FeedsColumns} />}

                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-lg-12 mt-5 text_left'>
                                    <SubTitle title="RACI Matrix-Ransomeware BIA Assessment" />
                                    <p>

                                    </p>
                                    <div className=" mt-3 custom_table text-center w-100">
                                        <table className='w-100'>
                                            <thead>
                                                <tr>
                                                    <th>Stakeholder</th>
                                                    <th>Responsible (R)</th>
                                                    <th>Accountable (A)</th>
                                                    <th>Consulted (C)</th>
                                                    <th>Informed  (I)</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {matData.map((row, index) => (
                                                    <tr key={index}>

                                                        <td> <div>
                                                            {row.stakeholder}
                                                        </div></td>
                                                        <td><div className='w-100'>
                                                            {row?.raciA === true ? (<span>&#10004;</span>) : ''}
                                                        </div></td>
                                                        <td><div className='w-100'>
                                                            {row?.raciR === true ? (<span>&#10004;</span>) : ''}
                                                        </div></td>
                                                        <td><div className='w-100'>
                                                            {row?.raciC === true ? (<span>&#10004;</span>) : ''}
                                                        </div></td>
                                                        <td><div className='w-100'>
                                                            {row?.raciI === true ? (<span>&#10004;</span>) : ''}
                                                        </div></td>
                                                        <td className='cus_input_wid2'  style={{width: 250}}>
                                                            <div className='cus_input_wid2'>
                                                                {/* <input
                                                                    type="text"
                                                                    name="name"
                                                                    disabled={!(!isCompletedExist || isEdit)}
                                                                    className='cus_input'
                                                                    value={row.contactName}
                                                                    onChange={(e) => handleNameChange(index, e.target.value)}
                                                                /> */}
                                                                <Select
                    key={index}
                    value={options.find(option => option.value === row.contactName) || null}
                    options={options}
                    isClearable={true}
                    isMulti={false}
                   
                    // className='cus_input'
                    onChange={(selectedOption) => handleChangeEmployee(index, selectedOption)}
                />
                                                           
                                                            </div>

                                                        </td>
                                                        <td className='cus_input_wid'>
                                                            <div className='cus_input_wid'>
                                                                <input
                                                                    type="Email"
                                                                    name="email"
                                                                    disabled={!(isCompletedExist || isEdit)}
                                                                    className='cus_input'
                                                                   // value={row.raciEmail}
                                                                   value={row.raciEmail}
                                                                    // onChange={(e) => handleEmailChange(index, e.target.value)}
                                                                />
                                                            </div>

                                                        </td>
                                                        <td className='  cus_input_wid'>
                                                            <div className='cus_input_wid'>
                                                                <PhoneInput
                                                                    // defaultCountry={row.phoneNumberCode ? getCountryCallingCode(row.phoneNumberCode.toString()) : 'IN'}
                                                                    // defaultCountry={options.find(option => {
                                                                    //     console.log(option);
                                                                    //     if(option.numberCode === getCountryCodeFromCallingCode(row.phoneNumberCode)){
                                                                    //         return option
                                                                    //     }
                                                                   
                                                                    enableAreaCodes={['us', 'ca']}
                                                                    enableAreaCodeStretch
                                                                    
                                                                    disableDropdown={false} 
                                                                    //     }) }
                                                                    value={row.phoneNumber}
                                                                    // disabled={!(isCompletedExist || isEdit)}
                                                                    className='cus_input_wid'
                                                                    countryCodeEditable={true}
                                                                    onChange={(value) => handleContactChange(index, value)}
                                                                    dropdownStyle={{
                                                                        maxHeight: "250px",
                                                                        backgroundColor: "#fff",
                                                                        border: "none",
                                                                        width: "240px",
                                                                        overflow: "none",
                                                                        overflowY: "auto",
                                                                      }}
                                                                    // onChange={(value) => {
                                                                        
                                                                    //     const newMatData = [...matData];
                                                                    //     newMatData[index].phoneNumber = value;
                                                                    //     setmatData(newMatData);
                                                                    // }}


                                                                />
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    {
                                        (!isCompletedExist && isEdit) && <div className='d-flex justify-content-center mt-2'>
                                            <Navigate type='submit' text='Save' onClick={hadleDelete} />
                                        </div>
                                    }



                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            <div id='chatcon'>
                <div className='chat-box' style={chatOpen ? show : hide}>
                    <Chat closeModal={closeModal} id={chatData?.subMilestoneId} ProjectId={chatData?.projectSubmilestoneId} setChatOpen={setChatOpen} />

                </div>
            </div>

        </div>
    )
}

export default ManageProjects;