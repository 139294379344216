import React from 'react'

function UnderLine({text}) {
    return (
        <div><p className='under-line-text'>
            {text}
        
        </p></div>
    )
}

export default UnderLine