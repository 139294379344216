// MultiSelect.js
import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from 'react-select';
import TextError from "../Errros";

function MultiSelect(props) {
    const { name, placeholder, options, disabled } = props;

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div className="text_left">
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'black' : 'black',
                                        borderWidth: '1px',
                                        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                                    }),
                                }}
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions
                                        ? selectedOptions.map((item) => item.value)
                                        : [];
                                    form.setFieldValue(name, selectedValues);
                                }}
      
                                placeholder={placeholder}
                                options={options}
                                value={options.filter(option => field.value.includes(option.value))}
                                isMulti={true}
                                isDisabled={disabled}
                                isClearable={true}
                            />

                            <ErrorMessage component={TextError} name={name} />
                        </div>
                    </div>
                )}
            </Field>
        </div>
    );
}

export default MultiSelect;
