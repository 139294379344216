// MultiStepForm.tsx
import React, { useState } from 'react';
import EditService from './EditService';
import AddService from './AddService';
import Step2 from './ViewMileStone';
import { useLocation } from 'react-router-dom';
import Step3 from './Raci';
import { Provider } from "../../../Utils/MultiStepContext";
const MultiStepForm = () => {
  const location = useLocation();
  const state = location.state;
  const [step, setStep] = useState(1);
  const [details, setDetails] = useState({ data: state?.id });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return details.step1 || details.data ? (
          <EditService nextStep={nextStep} details={details} setDetails={setDetails} />
        ) : (
          <AddService nextStep={nextStep} details={details} setDetails={setDetails} />
        );
      case 2:
        return <Step2 nextStep={nextStep} prevStep={prevStep} setDetails={setDetails} />;
      case 3:
        return <Step3 prevStep={prevStep} />;
      default:
        return null;
    }
  };

  return (
    <Provider value={{ details, setDetails, step, setStep }}>
      <div>
        {renderStep(details, setDetails, step, setStep)}

      </div>
    </Provider>
  );
};

export default MultiStepForm;
