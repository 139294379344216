import React from 'react';
import Navigate from "../../Buttons/Navigate"
import { Link } from 'react-router-dom';

function Index(props) {
    const { data, disabled } = props
    return (
        <div className='row'>
            {
                data && data?.map((res) => {
                    return (
                        <>
                            <div className='new_proj_tile'>
                                <div className='new_proj_wrap col-lg-9'>
                                    <div className=' col-lg-3'>
                                        <p className='bold_text fs-5'>{res?.clientName ? res?.clientName : 'ClientNAME'}</p>
                                    </div>
                                    <div className='col-lg-3'><p>{res?.companyName}</p></div>
                                    <div className='col-lg-6'><p>{res?.serviceName}</p></div>
                                </div>

                                {/* <div><p className='bold_text fs-6'>View RACI Matrix</p></div> */}
                                <div className='px-3 '>
                                    {
                                        !disabled ? (<Navigate text="Manage Projects" type="button" />) : (<Link to={`/manageprojects/${res?.projectId}`}>
                                            <Navigate text="Manage Projects" type="button" />
                                        </Link>)
                                    }


                                </div>





                            </div>
                        </>
                    )
                })
            }


        </div>

    )
}

export default Index
