class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message) {
    if(message){
      const message = this.actionProvider.createChatBotMessage("i don't understand what u said ? please click on the options given above",{
        delay:500 
      });
      this.actionProvider.setState((prevState) => {
        const msg = [...prevState.messages, message];
        return {
          ...prevState,
          messages: msg,
        };
      });
    }
  }
}

export default MessageParser;