import React, { useContext, useEffect, useState } from 'react'
import Title from '../../Components/Common/Heading/Title';
import Notification from "../../Components/Common/Notifications/Notification";
import NewProject from "../../Components/Common/Notifications/NewProject";
import Employee from "../../Assets/images/Icons/add_employee.svg";
import document from "../../Assets/images/Icons/add_documnet.svg";
import link from "../../Assets/images/Icons/add_link.svg";
import service from "../../Assets/images/Icons/add_service.svg";
import ADD from '../../Components/Common/Buttons/ADD';
import SubTitle from "../../Components/Common/Heading/SubTitle"
import { ModalContext } from '../../Utils/Context';
import { useNavigate } from "react-router-dom";
import UploadLink from "../../Components/Common/Modals/UploadLink";
import UploadDocument from "../../Components/Common/Modals/UploadDocument"
import EmployeeModal from "../../Components/Common/Modals/Employee"
import { GetProjects, NOTIFICATIONAPI } from '../../Utils/services';
import Loader from '../../Components/Common/Loader';
import { toast } from 'react-toastify';
import Can from "../../Utils/Can"
import AuthContext from '../../Utils/auth-context';
import { useAbility } from '../../Utils/AbilityContext';


function Home() {
    const [loading, setLoading] = useState(false);
    const [Projects, SetProjects] = useState([]);
    const [notication, Setnotication] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [metaData, setmetaData] = useState({});
    const [userData, SetUserData] = useState({})
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const { isAdmin, permission, name } = useContext(AuthContext);
    const authCtx = useContext(AuthContext);
    const ability = useAbility();
    let navigate = useNavigate();
    const data = [{
        icons: Employee,
        name: "Employees",

    },
    {
        icons: document,
        name: "Documents",

    },
    {
        icons: link,
        name: "Links",

    },
    {
        icons: service,
        name: "Services",

    },



    ];

    const fetchData = async (type) => {

        try {
            setLoading(true);
            const result = await GetProjects(type?.type, type?.page, type?.size)
            if (result.res.status === true) {
                setLoading(false);
                setmetaData(result?.res?.metadata)
                SetProjects(result?.res?.data);

            } else {
                setLoading(false);
            }
        } catch (err) {
            toast.error(err);
        }
    };
    const fetchNotiData = async () => {
   
        if (userData && userData?.primaryRole) {
            if (!userData) {
                return; // Exit early if result is undefined
            }

            let data;

            if (userData?.primaryRole === "ADMIN") {
                data = {
                    "userId": null
                };
            } else {
                data = {
                    "userId": userData?.userId
                };
            }

            try {
                setLoading(true);
                const result2 = await NOTIFICATIONAPI(data);

                if (result2.res.status === true) {
                    Setnotication(result2?.res?.data);
                }
            } catch (err) {
                toast.error(err);
            } finally {
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        fetchNotiData();
    }, [userData?.primaryRole, userData?.userId]); // Update the dependency array based on your needs

    useEffect(() => {
        fetchData({ type: 1, page: currentPage, size: 5 })
    }, [currentPage]);
    useEffect(() => {
        setLoading(true)
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx.user)) {
            SetUserData(JSON.parse(authCtx.user));
            setLoading(false)
        } else {
            SetUserData(authCtx.user);
            setLoading(false)
        }
    }, [authCtx.user]);
    const handleModal = (val) => {
        const tabsValue = val;
        const Upload = <UploadLink closeModal={closeModal} />
        const Documnet = <UploadDocument closeModal={closeModal} />
        const Employee = <EmployeeModal closeModal={closeModal} />


        switch (tabsValue) {
            case "Documents":
                handleModalData(Documnet, "md");
                break;
            case "Employees":

                handleModalData(Employee, "md");
                break;
            case "Links":
                handleModalData(Upload, "md");
                break;
            case "Services":
                navigate('/service');
                break;


        }

    }

    const isService = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 2 && a?.action == 'create');
    const isEmployee = (isAdmin == "true" || isAdmin == true) ? true : false;
    const isUpload = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 3 && a?.action == 'create');
    const ismanage = (isAdmin == "true" || isAdmin == true) ? true : ability?.A?.some((a) => a?.subject === 1 && a?.action == 'edit');

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <Title title={`Hello, ${userData && userData.name ? userData.name : 'Admin'}`} />
                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-8 noti_wrap' >
                        <div className='row'>
                            <SubTitle title="Notifications" />
                            <Notification data={notication} />
                        </div>


                    </div>

                    <div className='col-lg-4 col-md-12'>
                        <div className='row home_banner'>
                            <SubTitle title="Quick Add" />
                            <div className='home_btn_wrap' >

                                {
                                    isService && (
                                        <div onClick={() => handleModal("Services")}>
                                            <ADD text="Add Service" source={service} />
                                        </div>
                                    )}

                                {isEmployee && (
                                    <div onClick={() => handleModal("Employees")}>
                                        <ADD text="Add Employee" source={Employee} />
                                    </div>
                                )}

                                {isUpload && (
                                    <div onClick={() => handleModal("Documents")}>
                                        <ADD text="Add Document" source={document} />
                                    </div>
                                )}

                                {isUpload && (
                                    <div onClick={() => handleModal("Links")}>
                                        <ADD text="Add Link" source={link} />
                                    </div>
                                )}
                            </div>
                        </div>





                    </div>

                </div>
            </div>
            <div className='row mt-5'>
                {
                    Projects && (
                        <>
                            <SubTitle title="New Projects" />
                            <NewProject data={Projects} disabled={ismanage} />
                        </>
                    )
                }

            </div>

        </div>
    )
}

export default Home
