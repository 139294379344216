import React from 'react';
import Rating from 'react-rating';
import { FaRegStar, FaStar } from 'react-icons/fa'; // Import the specific icons you want to use

function Star(props) {
    const { readonly, count } = props;

    return (
        <div className='cursor-pointer'>
            <Rating
                initialRating={count}
                stop={5}
                readonly={readonly}
                emptySymbol={<FaRegStar className="icon" />}
                fullSymbol={<FaStar className="icon" />}

            />
        </div>
    );
}

export default Star;
