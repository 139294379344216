import React, { useState, useEffect, useContext } from 'react';
import Title from '../../../Components/Common/Heading/Title';
import SubTitle from '../../../Components/Common/Heading/SubTitle';
import * as Yup from 'yup';
import FormControl from '../../../Components/Common/Controls/FormControl';
import Navigate from '../../../Components/Common/Buttons/Navigate';
import AddValue from '../../../Components/Common/Modals/AddValue';
import { ModalContext } from '../../../Utils/Context';
import { RaciGet, RaciUpdate } from '../../../Utils/services';
import { useSearch } from "../../../Components/Common/Hooks/TableSearch/useSearch"
import View from '../../../Components/Common/Buttons/View';
import Loader from '../../../Components/Common/Loader';
import { toast } from 'react-toastify';

function RaciMaster() {
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const [loading, setLoading] = useState(false);
    const [allData, setallData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [isQuantityDisabled, setIsQuantityDisabled] = useState(false);
    const [errors, setErrors] = useState([]);
    useSearch(searchVal, allData, setFilteredData, ['stakeHolder']);
    const { closeModal, handleModalData } = modalContext;

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await RaciGet();
            if (result && result.res.status === true) {
                setallData(result.res.data);
                setFilteredData(result.res.data)
                setLoading(false);
            }
            // Handle the fetched data as needed
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            // Handle the error if needed
        }
    };

    const updateQuantity = (index, quantity) => {
        const newFuelDetails = [...allData];
        newFuelDetails[index].stakeHolder = quantity;
        setallData(newFuelDetails);
    };
    const stakeHolderSchema = Yup.string().required('StakeHolder is required')
    const SaveData = async (val, index) => {
        const data = allData.filter((res) => res.masterRaciId === val);
        const UpdatedJson = {
            "stakeHolder": data[0]?.stakeHolder,
        }

        try {
            setLoading(true);
            await stakeHolderSchema.validate(data[0]?.stakeHolder); // Validate the stakeHolder
            const result = await RaciUpdate(data[0].masterRaciId, UpdatedJson);
            if (result && result.res.status === true) {
                toast.success(result.res.message);
                fetchData();
                setEditIndex(-1);
                setErrors([])
                setLoading(false);
            } else {
                toast.error(result.res.message);
            }
        } catch (error) {
            setLoading(false)

            // Set the error for the specific row
            const updatedErrors = [...errors];
            updatedErrors[index] = { stakeHolder: error.message };
            setErrors(updatedErrors);

            console.error('Validation failed or error fetching data:', error);
        }
    }


    useEffect(() => {
        fetchData();
    }, []);




    useEffect(() => {
        if (searchVal.length === 0) {
            setFilteredData(allData);
        }
    }, [searchVal, allData]);

    const handle = () => {
        const Document = <AddValue closeModal={closeModal} Reload={fetchData} />;
        handleModalData(Document, 'md');
    };

    return (
        <div>
         
               
                
                <div className='row mt-3 mb-5'>
                    <div className='d-flex justify-content-between  align-items-center mt-1'>
                        <div className='col-lg-5 col-md-7'>
                            <FormControl
                                control='Search'
                                placeholder='Search For Value'
                                setSearchVal={setSearchVal}
                                searchVal={searchVal}
                            />
                        </div>
                        <div className='col-lg-3 col-md-3' onClick={handle}>
                            <Navigate text='Add Value' />
                        </div>
                    </div>
                </div>
                <Loader loading={loading} />
                <div className='row'>
                    <div className="project_table mt-3 form_default">
                        <div>

                            <table>
                            <thead style={{  position: 'sticky', top: 0, zIndex: 1 }}>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Value</th>

                                        <th></th>
                                  
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.map((fuel, index) => {
                                        const isRowEdited = index === editIndex;
                                        const hasError = errors[index];
                                        const isQuantityDisabled = !isRowEdited;
                                        return (
                                            <tr key={index} className='user-select-none text_align_webkit'>

                                                <td>
                                                    <div className='col-lg-4'>
                                                        {index + 1}
                                                    </div></td>
                                                <td>
                                                    <div className='col-lg-4'>
                                                        {
                                                            isRowEdited ? (<input
                                                                type='text'
                                                                value={fuel.stakeHolder}
                                                                onChange={(event) => updateQuantity(index, event.target.value)}

                                                            />) : (<p>
                                                                {fuel.stakeHolder}
                                                            </p>)
                                                        }
                                                        {hasError && hasError.stakeHolder && (
                                                            <span className="error">{hasError.stakeHolder}</span>
                                                        )}
                                                    </div>

                                                </td>
                                      
                                                <td >
                                                    <div className='col-lg-4'>
                                                        {!isRowEdited && (
                                                            <div onClick={() => setEditIndex(index)}>
                                                                <View text="Edit" />

                                                            </div>
                                                        )}
                                                        {isRowEdited && (
                                                            <>
                                                                <div onClick={() => SaveData(fuel?.masterRaciId, index)}>
                                                                    <View text="Save"/>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            
        </div>
    );
}

export default RaciMaster;
