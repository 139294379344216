import React from 'react'

function Heading({ title }) {
    return (
        <div className='row'>
        <div className='form_heading'><h6>{title
        }
        </h6></div>
        </div>
    )
}

export default Heading