import React, { useContext, useEffect } from 'react'
import FormControl from '../../../Components/Common/Controls/FormControl';
import Navigate from '../../../Components/Common/Buttons/Navigate';
import Loader from '../../../Components/Common/Loader';
import { useState } from 'react';
import { NOTIFICATIONAPI } from '../../../Utils/services';
import { ModalContext } from '../../../Utils/Context';
import View from '../../../Components/Common/Buttons/View';
import Role from '../../../Components/Common/Modals/Role';
import { useSearch } from '../../../Components/Common/Hooks/TableSearch/useSearch';
import AuthContext from '../../../Utils/auth-context';
import { toast } from 'react-toastify';

function Notifications() {
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const modalContext = useContext(ModalContext);
    const [allData, setallData] = useState([]);
    const [userData, SetUserData] = useState({});
    const { closeModal, handleModalData } = modalContext;
    const authCtx = useContext(AuthContext);

    const fetchNotiData = async () => {

        if (userData && userData?.primaryRole) {
            if (!userData) {
                return; // Exit early if result is undefined
            }

            let data;

            if (userData?.primaryRole === "ADMIN") {
                data = {
                    "userId": null
                };
            } else {
                data = {
                    "userId": userData?.userId
                };
            }

            try {
                setLoading(true);
                const result2 = await NOTIFICATIONAPI(data);

                if (result2.res.status === true) {
                    setallData(result2?.res?.data);
                }
            } catch (err) {
                toast.error(err);
            } finally {
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        fetchNotiData();
    }, [userData?.primaryRole, userData?.userId]);
    useEffect(() => {
        setLoading(true)
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx.user)) {
            SetUserData(JSON.parse(authCtx.user));
            setLoading(false)
        } else {
            SetUserData(authCtx.user);
            setLoading(false)
        }
    }, [authCtx.user]);

    function calculateTimeDifference(providedTimestamp) {
        // Current timestamp
        const currentTime = new Date();
        const givenTime = new Date(providedTimestamp)

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - givenTime;

        // Convert the time difference to days, hours, minutes, and seconds
        const millisecondsInSecond = 1000;
        const millisecondsInMinute = millisecondsInSecond * 60;
        const millisecondsInHour = millisecondsInMinute * 60;
        const millisecondsInDay = millisecondsInHour * 24;

        if (timeDifference >= millisecondsInDay) {
            const days = Math.floor(timeDifference / millisecondsInDay);
            return `${days} days`;
        } else if (timeDifference >= millisecondsInHour) {
            const hours = Math.floor(timeDifference / millisecondsInHour);
            return `${hours} hours`;
        } else if (timeDifference >= millisecondsInMinute) {
            const minutes = Math.floor(timeDifference / millisecondsInMinute);
            return `${minutes} minutes`;
        } else {
            const seconds = Math.floor(timeDifference / millisecondsInSecond);
            return `${seconds} seconds`;
        }
    }

    return (
        <div>



            <Loader loading={loading} />
            <div className='row mt-3 mb-5'>
                <div className="project_table mt-3 form_default">
                    <div class="notification-ui_dd-content">
                        {allData && allData?.map((res, index) => {
                            const difference = calculateTimeDifference(res?.createdAt);
                            return (
                                <div class="notification-list" key={index}>
                                    <div class="notification-list_content">

                                        <div class="notification-list_detail row">
                                            <div className='col-lg-10'>
                                                <p class="text-start not-black">{res?.message}</p>
                                            </div>
                                            <div className='col-lg-2'>
                                                <p class="text-muted text-end"><small>{difference} ago</small></p>
                                            </div>



                                        </div>
                                    </div>

                                </div>
                            )
                        })

                        }




                    </div>

                </div>

            </div>

        </div>
    )
}

export default Notifications