import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from "../../Errros/index"

function index(props) {
    const { name, defaultValue, placeholder, type, value, handle, row, ...rest } = props;
    return (
        <div>


            <div className='row'>
                <div >
                    <input name={name} type={type} placeholder={placeholder} onChange={(e) => handle(row.index, e.target.value)} />
                    <ErrorMessage component={TextError} name={name} />
                </div>



            </div>


        </div>
    )
}

export default index