import React, { useState, useEffect, useMemo, useContext } from 'react';
import Title from '../../Components/Common/Heading/Title'
import SubTitle from '../../Components/Common/Heading/SubTitle'
import { Formik, Form } from 'formik'
import Navigate from '../../Components/Common/Buttons/Navigate'
import FormControl from '../../Components/Common/Controls/FormControl'
import DefaultTable from '../../Components/Common/Tables/DefaultTable'
import gray from "../../../src/Assets/images/Icons/grey.svg";
import Cross from "../../../src/Assets/images/Icons/cross.png";
import Review from '../../Components/Common/Modals/Review';
import { GetProjects, SEARCHPROJECTSOAPi } from '../../Utils/services';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { ModalContext } from '../../Utils/Context';
import Loader from '../../Components/Common/Loader';
import Pagination from '../../Components/Common/Pagination';
import useDebounce from "../../Components/Common/Hooks/useDebounce";


function CompletedProjects() {
    const [searchVal, setSearchVal] = useState('');
    const [complete, Setcomplete] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const modalContext = useContext(ModalContext);
    const [metaData, setmetaData] = useState({});
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate();
    const [debouncedSearch, setDebouncedSearch] = useDebounce('', 300)
    const fetchData = async (type) => {
        if (type?.type && type?.page && type?.size) {
            try {
                setLoading(true);
                const result = await GetProjects(type?.type, type?.page, type?.size)
                if (result.res.status === true) {
                    setLoading(false);
                    setmetaData(result?.res?.metadata)

                    Setcomplete(result?.res?.data)

                } else {
                    setLoading(false);
                }
            } catch (err) {
                toast.error(err);
            }
        }

    };
    useEffect(() => {
        fetchData();
    }, []);
    const handle = (data, name) => {
        switch (name) {
            case "review":
                const ReviewModal = <Review data={data} closeModal={closeModal} />
                handleModalData(ReviewModal, "md");
                break;
            case "Complete":
                navigate(`/manageprojects/${data?.projectId}/completed`);
                break;



        }

    }
    useEffect(() => {
        fetchData({ type: 3, page: currentPage, size: 10 })
    }, [currentPage]);

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Emp.ID",
                accessor: (data, index) => {
                    return (
                        <>
                            <span className='text-sm'>{(currentPage - 1) * 10 + index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Company Name",
                accessor: "companyName"
            },
            {
                Header: "Service",
                accessor: "serviceName"

            },
            {
                Header: () => (
                    <div className="flex">
                        <div className="">Status</div>
                        <div className='sub_text'>(Completed Milestone)</div>
                    </div>
                ),
                accessor: "status", // Assign a unique string accessor
                Cell: (data) => ( // Use Cell for rendering the cell content
                    <span className='text-sm'>{data.row.original.completedStatus}/{data.row.original.totalStatus}</span>
                )
            },
            {
                Header: "Access",
                accessor: data => {
                    return (
                        <div className='acess_icon'>

                            <ul>
                                {data?.employeeImageUrls?.slice(0, 3).map((res, index) => (
                                    <li key={index}>
                                        {res ? (
                                            <img src={res} alt={`p${index + 1}`} />
                                        ) : (
                                            <img src={gray} alt="" />
                                        )}
                                    </li>
                                ))}
                            </ul>

                        </div>
                    );
                }
            },
            {
                Header: 'Actions',
                accessor: (data) => {
                    return (
                        <>
                            <div className='d-flex justify-content-start align-items-center'>
                                {
                                    data?.reviewId && <div onClick={() => handle(data, "review")}>
                                        <Navigate text="View Review" />
                                    </div>
                                }

                                <div onClick={() => handle(data, "Complete")}>
                                    <Navigate text="View Details" />
                                </div>






                            </div>
                        </>
                    );
                },
            }

        ], [fetchData]);
    const searchData = async (type) => {

        try {
            setLoading(true);
            const result = await SEARCHPROJECTSOAPi(type?.type, type?.page, type?.size, type?.searchKeyword)

            if (result.res.status === true) {
                setmetaData(result?.res?.metadata)
                Setcomplete(result?.res?.data)

                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            toast.error(err);
        }
    };
    useEffect(() => {
        fetchData({ type: 3, page: currentPage, size: 10 });
    }, [currentPage, searchVal]);

    useEffect(() => {
        if (!searchVal) {
            // If searchVal is null or empty, fetch all data
            fetchData({ type: 3, page: currentPage, size: 10 });

            return;
        }

        setDebouncedSearch(searchVal);
    }, [currentPage, searchVal]);

    useEffect(() => {
        if (!debouncedSearch || debouncedSearch.length < 0) return;

        setCurrentPage(1);
        searchData({ type: 3, page: 1, size: 300, searchKeyword: debouncedSearch });
    }, [debouncedSearch]);

    return (
        <div>   <div className="container-fluid pb-5">
            <div className='row'>
                <div className='mb-2'>
                    <Title title="Completed Projects" />
                </div>

                <div className='searchbox'>

                    <div className='col-lg-4 col-md-12 col-12'>
                        <Formik
                        >
                            {formik => {
                                return (
                                    <Form onSubmit={e => { e.preventDefault(); }}>


                                        <FormControl
                                            control='Search'
                                            placeholder='Search For Projects'
                                            setSearchVal={setSearchVal}
                                            searchVal={searchVal}

                                        />


                                    </Form>
                                )
                            }}
                        </Formik>

                    </div>


                </div>
                <Loader loading={loading} />
                {
                    complete?.length > 0 && <div className=" project_table  mb-5">
                        <div>
                            <DefaultTable data={complete} columns={FeedsColumns} />
                        </div>
                        <div className='pagnation_wrap my-2'>
                            <Pagination
                                totalRows={metaData.totalElements}
                                pageChangeHandler={setCurrentPage}
                                rowsPerPage={metaData?.pageSize}
                            />
                        </div>





                    </div>
                }


            </div>



        </div></div>
    )
}

export default CompletedProjects